<template>
  <div class="snackbar-container">
    <v-slide-y-transition group>
      <v-alert
          v-for="(snackbar, index) in $snackbar.snackbars" :key="index"
          :color="snackbar[1]?.type"
      >
        <div class="d-flex align-center justify-space-between ga-6">
          {{ snackbar[1]?.message }}
          <p @click="closeSnackbar(snackbar[0])">
            <Icon icon="close-icon"/>
          </p>
        </div>
      </v-alert>
    </v-slide-y-transition>
  </div>
</template>

<script setup>
import Icon from "@/components/Icons/Icon.vue";
import {inject} from 'vue';

const snackbarStore = inject('snackbar');
const closeSnackbar = (id) => {
  snackbarStore.removeSnackbar(id);
};
</script>

<style scoped>
.snackbar-container {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  margin: auto;
  width: fit-content;
  display: grid;
  grid-gap: 0.5em;
  z-index: 99;
}
</style>
