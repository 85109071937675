<template>
  <EventsAndActivityPanelCard
      icon="cart-icon"
      title="Recent Activity"
      @click="router.push({ name: 'order-view', params: { id: recentActivity.id } })"
  >
    <template #customContent>
      <div class="font-size-12-400 ml-3 pb-2">
        <p> Order ID:
          <span class="font-size-16-600">#{{ recentActivity.id }}</span>
        </p>
        <p class="font-14 font-grey-dark mb-1"> {{ convertDateCalendar(recentActivity.meta.created_at) }}</p>
        <p class="subtitle">
          {{ getTotalOrderItems }}
          <span class="secondary-button-color mr-1">|</span>
          <span class="font-grey-dark">Status</span> New
        </p>

        <p v-if="recentActivity.meta.total" class="font-size-12-600 mt-1">
          {{ recentActivity.meta.total }}

          <span :class="getPaymentStatus(recentActivity.meta).color" class="font-size-10-600">
          ({{ getPaymentStatus(recentActivity.meta).status }})
        </span>
        </p>
      </div>
    </template>
  </EventsAndActivityPanelCard>
</template>

<script lang="ts" setup>
import {getPaymentStatus} from "@/views/OrdersComponents/Helpers";
import {convertDateCalendar} from "@/components/Helpers/DateHelper";
import {computed, defineProps} from 'vue';
import EventsAndActivityPanelCard
  from "@/components/Layout/LayoutV2/EventsAndActivityPanel/EventsAndActivityPanelCard.vue";
import {useRouter} from "vue-router";

const router = useRouter();

const props = defineProps({
  recentActivity: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const getTotalOrderItems = computed(() => {
  if (props.recentActivity.meta.items > 1) {
    return `${props.recentActivity.meta.items} Items` ?? ''
  }

  return `${props.recentActivity.meta.items} Item` ?? ''
})
</script>