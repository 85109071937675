import {HttpService} from "./http";

class PackagesService extends HttpService {
    public constructor() {
        super("/client-app/shop/packages");
    }

    public index(filters: string | null = null): Promise<any> {
        return this.http
            .get(`${this.route}/`, {
                params: filters
            })
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public show(id: number): Promise<any> {
        return this.http
            .get(`${this.route}/${id}`)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }
}

export const packagesService: PackagesService = new PackagesService();
