<template>
  <svg width="36" height="22" viewBox="0 0 36 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2938_297)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4727 19.6347H22.8401V3.14844H13.4727V19.6347Z"
            fill="#FF5F00" />
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M14.0674 11.3911C14.0674 8.04645 15.6664 5.06758 18.1564 3.14797C16.3352 1.74387 14.0377 0.90625 11.5403 0.90625C5.62804 0.90625 0.835449 5.60056 0.835449 11.3911C0.835449 17.1817 5.62804 21.876 11.5403 21.876C14.0377 21.876 16.3352 21.0384 18.1564 19.6343C15.6664 17.7144 14.0674 14.7358 14.0674 11.3911Z"
            fill="#EB001B" />
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M35.1429 17.8882V17.4814H35.0344L34.9098 17.7613L34.7849 17.4814H34.6763V17.8882H34.7528V17.5813L34.8699 17.846H34.9493L35.0665 17.5807V17.8882H35.1429ZM34.4557 17.8882V17.5507H34.5949V17.482H34.241V17.5507H34.3799V17.8882H34.4557ZM35.4772 11.3911C35.4772 17.1817 30.6843 21.876 24.7724 21.876C22.275 21.876 19.9771 21.0384 18.1562 19.6343C20.6462 17.7147 22.2452 14.7358 22.2452 11.3911C22.2452 8.04674 20.6462 5.06787 18.1562 3.14797C19.9771 1.74387 22.275 0.90625 24.7724 0.90625C30.6843 0.90625 35.4772 5.60056 35.4772 11.3911Z"
            fill="#F79E1B" />
    </g>
    <defs>
      <clipPath id="clip0_2938_297">
        <rect width="35" height="21" fill="white" transform="translate(0.835449 0.90625)" />
      </clipPath>
    </defs>
  </svg>
</template>