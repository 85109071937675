<template>
  <Component :is="getActivityTypeComponent"/>
</template>

<script lang="ts" setup>
import {defineProps, computed, h} from 'vue';
import OrderActivity from "@/components/Layout/LayoutV2/EventsAndActivityPanel/Cards/OrderActivity.vue";

const props = defineProps({
  recentActivity: {
    type: Object,
    default: () => ({})
  }
})

// Dynamically determine the component to render based on the activity type
const getActivityTypeComponent = computed(() => {
  switch (props.recentActivity.subject_type) {
    case 'App\\Models\\v3\\Order\\Order':
      return h(OrderActivity, {recentActivity: props.recentActivity});
    default:
      return null;
  }
})
</script>