<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="14" height="14" fill="#F5F5F5"/>
    <g clip-path="url(#clip0_0_1)">
      <rect width="390" height="844" transform="translate(-152 -806)" fill="#F5F5F5"/>
      <g filter="url(#filter0_d_0_1)">
        <rect x="-152" y="-28" width="390" height="108" fill="white"/>
      </g>
      <rect x="-124.395" y="-10" width="338.79" height="35" rx="17.5" fill="currentColor"/>
      <rect x="-124.395" y="-10" width="338.79" height="35" rx="17.5" fill="url(#paint0_linear_0_1)"/>
      <circle cx="7" cy="7" r="7" fill="white"/>
      <path d="M10.104 7.46H7.836V9.788H6.252V7.46H3.984V5.996H6.252V3.668H7.836V5.996H10.104V7.46Z" fill="#C671C2"/>
    </g>
  </svg>
</template>