<template>
    <v-container>
        <v-row v-for="service in state.services" :key="service.id" class="mb-3">
            <v-col class="mt-2">
                 <p class="p-text-md">{{ service.name }} ({{ service.booking_options.duration }} mins)</p>
            </v-col>
            <v-col cols="2" class="mt-2">
                <p class="p-text-md">{{ service.price_display }}</p>
            </v-col >
            <v-col cols="3" class="text-center">
                <v-btn class="text-white" :color="settingsStore.settings.buttonColor" 
                @click="router.push({ name: 'create-appointment', params: { id: service.id } })">View</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts" setup>
import { reactive } from 'vue';
import { servicesService } from '@/api/services/service';
import { useSettingsStore } from '@/api/store/settings';

const state = reactive({
    services: [],
});

const settingsStore = useSettingsStore();

const fetchServices = async () => {
    servicesService.index().then((response) => {
        state.services = response.data;
    }).catch((error) => {
        console.log(error);
    }).finally(() => {
        console.log('done');
    });
};

fetchServices();
</script>