<template>
  <svg
    width="16"
    height="19"
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.1509 18.0287C2.14911 18.2695 2.24118 18.5011 2.40694 18.6729C2.5727 18.8446 2.79861 18.9425 3.03512 18.9449H13.2667C13.5032 18.9425 13.7291 18.8446 13.8949 18.6729C14.0606 18.5011 14.1527 18.2695 14.1509 18.0287V5.8125H2.1509V18.0287ZM3.3509 7.03412H12.9509V17.7233H3.3509V7.03412Z"
      fill="#EC5151"
    />
    <path d="M6.05107 8.25781H4.85107V15.8929H6.05107V8.25781Z" fill="#EC5151" />
    <path d="M8.75127 8.25781H7.55127V15.8929H8.75127V8.25781Z" fill="#EC5151" />
    <path d="M11.451 8.25781H10.251V15.8929H11.451V8.25781Z" fill="#EC5151" />
    <path
      d="M10.8512 3.36973V1.53731C10.8512 1.02354 10.4954 0.621094 10.0412 0.621094H6.26117C5.80697 0.621094 5.45117 1.02354 5.45117 1.53731V3.36973H0.951172V4.59135H15.3512V3.36973H10.8512ZM6.65117 1.84271H9.65117V3.36973H6.65117V1.84271Z"
      fill="#EC5151"
    />
  </svg>
</template>
