<template>
    <div>
        <v-card color="#A5E4EE" theme="dark">
            <div class="d-flex flex-no-wrap">
                <v-avatar class="ma-3" size="75" rounded="0">
                    <v-img :src="logo_img" class="rounded-circle"></v-img>
                </v-avatar>

                <div class="w-100 mr-2">
                    <v-text-field bg-color="white" density="compact" class="search-input mt-7" label="Search"
                        prepend-inner-icon="fa-search"></v-text-field>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script lang="ts" setup>
const logo_img = 'img/logo.png';
</script>

<style scoped>
.search-input.v-input .v-input__slot {
  border-radius: 50px;
}
</style>