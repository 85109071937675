const medicalAlertTypes = [
    {
        label: 'Allergy',
        name: 'Allergy'
    },
    {
        label: 'Dietary Requirement',
        name: 'Dietary Requirement'
    },
    {
        label: 'Intolerance',
        name: 'Intolerance'
    },
    {
        label: 'Medical Condition',
        name: 'Medical Condition'
    },
    {
        label: 'Medication',
        name: 'Medication'
    },
    {
        label: 'Other',
        name: 'Other'
    },
    {
        label: 'Preference',
        name: 'Preference'
    },
    {
        label: 'Significant Event',
        name: 'Significant Event'
    },
    {
        label: 'Significant Symptom',
        name: 'Significant Symptom'
    },
    {
        label: 'Supplement',
        name: 'Supplement'
    }
]

const statusTypes = [
    {
        label: 'Ongoing',
        name: 'Ongoing'
    },
    {
        label: 'Ended',
        name: 'Ended'
    },
]

const filterValues = [
    {
        name: 'type',
        label: 'Type'
    },
    {
        name: 'name',
        label: 'Name'
    },
    {
        name: 'description',
        label: 'Description'
    },
    {
        name: 'status',
        label: 'Status'
    },
    {
        name: 'age_display',
        label: 'Age'
    },
    {
        name: 'duration_display',
        label: 'Duration'
    }
]

const sortValues = [
    {
        name: 'asc',
        label: 'Asc'
    },
    {
        name: 'desc',
        label: 'Desc'
    }
]

export {medicalAlertTypes, statusTypes, filterValues, sortValues}