<template>
  <div>
    <v-row v-if="state.items.length > 0" no-gutters>
      <div v-for="item in state.items" :key="item.id" class="w-100">
        <v-col @click="viewNotification(item.id)">
          <v-row :class="{'sw-notification-not-read': !item.read}" class="pa-3 ma-1 sw-notification-read align-center">
            <div class="d-flex flex-wrap ga-6">
              <div class="pt-3">
                <v-icon class="fr-custom-primary">
                  fa-wechat
                </v-icon>
              </div>
              <div>
                <p class="p-text-lg">{{ item.subject }}</p>
                <p class="p-text-sm mt-2">{{ truncateText(item.content, 32) }}</p>
                <p class="p-text-xs mt-2" color="light-grey-1">From: {{ item.from }}</p>
              </div>
            </div>
          </v-row>
        </v-col>
      </div>
    </v-row>

    <v-row>
      <v-col>
        <div v-if="state.pagination.next_page_url" class="text-center">
          <v-btn
              :ripple="false"
              class="load-more text-capitalize font-size-12-600"
              variant="plain"
              @click="seeMore"
          >
            Load More
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="state.items.length == 0" class="mt-4 mx-4 mb-3 my-2 py-1" no-gutters>
      <v-col class="my-1 px-10 text-center" cols="12">
        <v-alert v-if="!settingsStore.loading" type="info" variant="tonal">No Notifications</v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts" setup>
import {useRouter} from "vue-router"
import {useSettingsStore} from "@/api/store/settings"
import {reactive, shallowRef} from "vue";
import {notificationsService} from '@/api/services/notification';
import {truncateText} from '@/tools/text';

const settingsStore = useSettingsStore();

const router = useRouter();

const state = reactive({
  items: [] as any[],
  pagination: {
    meta: {},
    links: [],
    next_page_url: null,
  },
});

const page = shallowRef(1)

const getNotifications = () => {
  settingsStore.loading = true;
  notificationsService.index({page: page.value}).then((response) => {
    state.items.push(...response.data)
    state.pagination.meta = response.meta;
    state.pagination.links = response.links;
    state.pagination.next_page_url = response.next_page_url;
    settingsStore.loading = false;
  });
}

const markRead = (id: number) => {
  notificationsService.read(id).then((response) => {
    state.items = state.items.map((item) => {
      if (item.id === id) {
        item.read = true;
      }
      return item;
    });
  });
}

const viewNotification = (id: number) => {
  markRead(id);
  router.push({name: 'view-notification', params: {id: id}});
}

const seeMore = () => {
  if (state.pagination.next_page_url) {
    page.value++;
    getNotifications();
  }
}

getNotifications();
</script>