<template>
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15.4695" cy="15.4695" r="15.4695" fill="currentColor" />
    <path
      d="M24.0774 14.7822H9.50218L12.3948 11.3079C12.5301 11.1451 12.5952 10.9353 12.5757 10.7246C12.5563 10.5139 12.454 10.3195 12.2912 10.1843C12.1285 10.049 11.9187 9.98394 11.708 10.0034C11.4973 10.0228 11.3029 10.1251 11.1676 10.2879L7.18328 15.0691C7.15647 15.1071 7.1325 15.1471 7.11156 15.1886C7.11156 15.2285 7.11156 15.2524 7.05578 15.2922C7.01966 15.3836 7.00075 15.4809 7 15.5791C7.00075 15.6774 7.01966 15.7746 7.05578 15.866C7.05578 15.9058 7.05578 15.9297 7.11156 15.9696C7.1325 16.0111 7.15647 16.0511 7.18328 16.0891L11.1676 20.8703C11.2426 20.9603 11.3364 21.0326 11.4424 21.0822C11.5485 21.1318 11.6642 21.1574 11.7812 21.1572C11.9674 21.1576 12.1479 21.0927 12.2912 20.9739C12.3719 20.907 12.4386 20.8249 12.4875 20.7322C12.5364 20.6395 12.5665 20.538 12.5761 20.4336C12.5858 20.3293 12.5747 20.224 12.5436 20.1239C12.5125 20.0238 12.4619 19.9309 12.3948 19.8504L9.50218 16.376L24.0774 16.376C24.2888 16.376 24.4915 16.292 24.6409 16.1426C24.7904 15.9931 24.8743 15.7905 24.8743 15.5791C24.8743 15.3678 24.7904 15.1651 24.6409 15.0156C24.4915 14.8662 24.2888 14.7822 24.0774 14.7822Z"
      fill="white"
    />
  </svg>
</template>
