import {useSnackbarStore} from '@/api/store/useSnackbarStore';
import GlobalSnackbar from "@/components/GlobalSnackbar.vue";

export const snackBarPlugin = {
    install: (app: any): void => {
        const snackbar = useSnackbarStore();

        // Provide the snackbar composable globally
        app.config.globalProperties.$snackbar = snackbar;

        // Register the SnackbarComponent globally
        app.component('GlobalSnackbar', GlobalSnackbar);

        // Expose specific snackbar functions globally for easy access
        app.config.globalProperties.successSnackbar = snackbar.successSnackbar;
        app.config.globalProperties.errorSnackbar = snackbar.errorSnackbar;
        app.config.globalProperties.warningSnackbar = snackbar.warningSnackbar;
        app.config.globalProperties.infoSnackbar = snackbar.infoSnackbar;

        app.provide('snackbar', snackbar);
    }
}
