<template>
  <v-container class="px-0 pb-8" style="min-height:100%">
    <QuickMenu/>
    <EventsAndActivityPanel/>
  </v-container>
</template>

<script lang="ts" setup>
import {defineAsyncComponent} from 'vue';
import EventsAndActivityPanel from '@/components/Layout/LayoutV2/EventsAndActivityPanel/EventsAndActivityPanel.vue';
import {useSettingsStore} from '@/api/store/settings';

const settingsStore = useSettingsStore();

const QuickMenu = defineAsyncComponent(() => import('@/components/Layout/LayoutV' + settingsStore.settings.layout + '/QuickMenu.vue'));
</script>