<template>
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="5.95276" cy="6" r="5" fill="currentColor" />
    <path
      d="M6.4386 2.60156L6.29358 7.04443H5.23889L5.08948 2.60156H6.4386ZM5.05432 8.41992C5.05432 8.23242 5.11877 8.07715 5.24768 7.9541C5.37952 7.82812 5.55237 7.76514 5.76624 7.76514C5.98303 7.76514 6.15588 7.82812 6.28479 7.9541C6.4137 8.07715 6.47815 8.23242 6.47815 8.41992C6.47815 8.60156 6.4137 8.75537 6.28479 8.88135C6.15588 9.00732 5.98303 9.07031 5.76624 9.07031C5.55237 9.07031 5.37952 9.00732 5.24768 8.88135C5.11877 8.75537 5.05432 8.60156 5.05432 8.41992Z"
      fill="white"
    />
  </svg>
</template>
