<template>
  <div v-if="getShipping === null" class="d-flex align-center ga-3"
       @click="emit('addShipping', item.id)">
    <div class="d-flex align-center ga-4">
      <Icon icon="plus-icon"/>
      <p v-if="getSupplierName" :class="{'secondary-background-button-color': !item?.supplier }"
         class="font-size-10-600 supplier font-grey-dark"
      >
        {{ getSupplierName }}
      </p>
    </div>
  </div>

  <div v-else-if="getShipping" class="d-flex align-center justify-space-between">
    <div class="d-flex align-center ga-3">
      <Icon icon="shipping-icon"/>
      <p>{{ getShipping?.product?.name }}</p>
    </div>

    <div
        :class="{'disabled': disabled}"
        class="text-right font-size-16-600 pt-0"
        @click="emit('delete', getShipping.cart_id, getShipping.id)"
    >
      <p>
        <Icon icon="trash-icon"/>
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {defineProps, defineEmits, computed} from 'vue';
import Icon from "@/components/Icons/Icon.vue";

const emit = defineEmits(['delete', 'addShipping']);

const props = defineProps({
  item: {
    type: Object,
  },
  userStore: {
    type: Object,
  },
  disabled: {
    type: Boolean,
    default: false
  },
});

const getShipping = computed(() => {
  return props.item.items.find(i => i.product.type == 'shipping_method') ?? null;
})

const getSupplierName = computed(() => {
  if (props.item.supplier) {
    return props.item?.supplier?.name ?? null;
  }
  return props.userStore.user?.clinic.name ?? null;
})
</script>

<style lang="scss" scoped>
.supplier {
  background: #3A5485;
  color: white;
  padding: 2px 6px;
  border-radius: 30px;
  text-align: center;
  width: fit-content;
}

.disabled {
  pointer-events: none;
}
</style>