<template>
  <CardComponent>
    <div
        :class="{'primary-color': !isStatusEnded, 'font-grey': isStatusEnded}"
        class="text-center font-size-10-400 "
    >
      <Icon :icon="setIcon"/>
      <p>{{ alert.type }}</p>
    </div>

    <div :class="setColorIfStatusEnded" class="ml-2">
      <div>
        <div class="font-size-14-600 ellipsis">{{ alert.name }}</div>
        <p class="font-size-10-400 ellipsis">{{ alert.description }}</p>
      </div>

      <p class="subtitle mt-2">
        <slot></slot>
      </p>
    </div>

    <div :class="setColorIfStatusEnded" class="text-center">
      <div class="d-flex justify-center mb-4">
        <CreatedBy
            :color="isStatusEnded ? 'grey_light' : 'primaryColor'"
            :gap="setCreateByIcon === 'practitioner-icon'? '0': '4px'"
            :icon="setCreateByIcon"
        />
      </div>

      <v-btn
          :class="{'white-btn': alert.created_by !== 'client' || isStatusEnded, 'primary-button': !isStatusEnded}"
          :disabled="disableBtn"
          class="b-round-20 outline-btn"
          elevation="0"
          height="32px"
          x-small
          @click="handleMedicalAlertAction"
      >
        {{ setButtonText }}
      </v-btn>
    </div>
  </CardComponent>
</template>

<script lang="ts" setup>
import {computed, defineProps} from 'vue';
import CreatedBy from "@/components/custom/CreatedBy.vue";
import Icon from "@/components/Icons/Icon.vue";
import CardComponent from "@/components/custom/CardComponent.vue";
import {useRouter} from "vue-router";

const router = useRouter();

const props = defineProps({
  disableBtn: {
    type: Boolean,
    default: false,
  },
  dob: {
    type: String,
    default: '',
  },
  alert: {
    type: Object,
    default: () => {
      return {
        name: '',
        type: '',
        description: '',
        created_by: '',
      }
    },
  },
})

const isStatusEnded = computed(() => {
  return props.alert.status === 'Ended';
});

const setIcon = computed(() => {
  return props.alert?.type === 'Allergy' ? 'allergy-icon' : 'file-icon'
});

const setCreateByIcon = computed(() => {
  return props.alert.created_by === 'client' ? 'client-icon' : 'practitioner-icon'
});

const setColorIfStatusEnded = computed(() => {
  return isStatusEnded.value ? 'font-grey' : 'font-black'
});

const setButtonText = computed(() => {
  return props.alert.created_by === 'client' && props.alert.status === 'Ongoing' ? 'Edit' : 'View'
});

const handleMedicalAlertAction = () => {
  if (props.alert.created_by === 'client' && props.alert.status === 'Ongoing') {
    return router.push({
      name: 'create-edit-medical-alerts',
      params: {action: 'edit', id: props.alert.id},
      query: {dob: props.dob}
    });
  }

  return router.push({
    name: 'view-medical-alert',
    params: {id: props.alert.id},
    query: {dob: props.dob}
  });
};
</script>

<style lang="scss" scoped>
.subtitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
}

.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
