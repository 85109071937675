<template>
  <v-container v-if="state.order" class="pa-0" style="background-color:#F0F0F0; min-height:100vh">
    <section v-for="order in state.orders" :key="order.id" class="pa-4">
      <OrderCardView
          :clinic-avatar="order.clinic_avatar"
          :has-multiple-orders="state.orders.length > 1"
          :order="state.order"
          :order-items="order.items"
          :order-type="getOrderType"
          :supplier-id="order.supplier_id"
          :supplier-order="order"
      />
    </section>

    <v-card v-if="state.order.payment_status === 'paid'" class="card-container">
      <v-card-title class="font-size-18-600 px-0">
        Payment Method
      </v-card-title>

      <div class="d-flex align-center py-4 pl-4 justify-space-between">
        <div v-if="state.hasCardPayments" class="d-flex align-center ga-4">
          <Icon icon="card-icon"/>
          Online
        </div>

        <div v-if="state.hasManualPayments" class="d-flex align-center ga-4">
          <Icon icon="cash-icon"/>
          Cash
        </div>

        <ButtonBase
            custom-class="text-white b-round-20 outline-btn white-btn"
            disabled
            text="View Payments"
            width="150px"
        />
      </div>
    </v-card>

    <v-card v-if="getShippingAddress" class="card-container">
      <v-card-title class="font-size-18-600 px-0">
        Shipping
      </v-card-title>

      <section>
        <v-card-title class="font-size-14-600 px-0">
          Shipping Address
        </v-card-title>
        <div class="d-flex align-center ga-3">
          <Icon icon="address-icon"/>
          <div class="font-size-12-400">
            <p>
              {{ getShippingAddress.address_1 }}
              {{ getShippingAddress.city }}
              {{ getShippingAddress.post_code }},
            </p>
            <p>
              {{ getShippingAddress.country }}
            </p>
          </div>
        </div>
      </section>

      <section v-if="getShippingMethod.length > 0">
        <v-card-title class="font-size-14-600 px-0">
          Shipping Method
        </v-card-title>
        <div
            v-for="(shipping, index) in getShippingMethod"
            :key="`shipping-${index}`"
            class="d-flex align-center ga-3 pb-4"
        >
          <Icon icon="shipping-icon"/>
          <div class="font-size-12-400 d-flex justify-space-between w-100">
            <p>{{ shipping.name }}</p>
            <p class="font-size-12-600">{{ shipping.price_display }}</p>
          </div>
        </div>
      </section>
    </v-card>

    <v-card v-if="state.order.payment_status !== 'paid'" class="card-container">
      <div class="py-4">
        <div class="d-flex align-center justify-space-between">
          <p>Total Payment:</p>
          <p class="font-size-16-600">{{ orderTotalPayment }}</p>
        </div>
      </div>
    </v-card>


    <div class="text-center py-8">
      <ButtonBase
          v-if="state.order.payment_status !== 'paid'"
          class="mb-3"
          custom-class="text-white primary-button"
          text="Pay Now"
          @click="openPurchase"
      />

      <ButtonBase
          :with-bg="false"
          button-color="buttonColor"
          custom-class="font-size-12-600"
          text="Report Issue With Order"
          @click="() => dialog = true"
      />
    </div>

    <v-dialog
        v-model="dialog"
        width="auto"
    >
      <v-card max-width="400">
        <template v-slot:text>
          <p class="pt-4">
            {{ reportIssueWithOrder.nd }}
            <a href="mailto:{{ reportIssueWithOrder.email }}">{{ reportIssueWithOrder.email }}</a>
            {{ reportIssueWithOrder.clinic }}
          </p>
        </template>

        <template v-slot:actions>
          <v-btn
              class="ms-auto"
              text="Ok"
              @click="dialog = false"
          ></v-btn>
        </template>
      </v-card>
    </v-dialog>

    <v-card v-if="state.orders.length == 0" class="mt-4 mx-4 mb-3" height="50vh">
      <v-row class="my-2 py-1" no-gutters>
        <v-col class="my-1 px-10 text-center" cols="12">
          <v-alert v-if="!settingsStore.loading" type="info" variant="tonal">No Orders Found</v-alert>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script lang="ts" setup>
import {computed, reactive, shallowRef} from 'vue';
import {useRoute, useRouter} from 'vue-router';

import {ordersService} from '@/api/services/order';
import {useSettingsStore} from '@/api/store/settings';

import OrderCardView from "@/views/OrdersComponents/OrderDetails/OrderCardView.vue";
import Icon from "@/components/Icons/Icon.vue";
import ButtonBase from "@/components/custom/ButtonBase.vue";

const route = useRoute();
const router = useRouter();

const settingsStore = useSettingsStore();

const state = reactive({
  orders: null as any,
  order: null as any,
  hasCardPayments: false,
  hasManualPayments: false,
  loaded: false,
});

const dialog = shallowRef(false)

const getOrder = () => {
  settingsStore.loading = true;
  ordersService.show(+route.params.id).then((response) => {
    state.orders = response.data;
    state.order = response.order;
    state.hasCardPayments = response.has_card_payments;
    state.hasManualPayments = response.has_manual_payments;
  }).catch((error) => {
    console.log(error);
  }).finally(() => {
    settingsStore.loading = false;
    state.loaded = true;
  });
}

// Computed
const getOrderType = computed(() => {
  let hasBooking = state.order.items.some(
      e => e.type === 'clinic_package' || e.type == 'package'
  );
  let hasProduct = state.order.items.some(
      e => e.type === 'clinic_product' || e.type == 'supplement'
  );
  let hasService = state.order.items.some(
      e => e.type === 'service' || e.type === 'clinic_service'
  );

  if (hasBooking && !hasProduct) {
    return "Package";
  } else if (!hasBooking && hasProduct) {
    return "Product";
  } else if (hasService && !hasProduct && !hasBooking) {
    return "Service";
  } else {
    return "-";
  }
})

const getShippingAddress = computed(() => {
  return state.order?.shipping_address;
})

const getShippingMethod = computed(() => {
  return state.order.items.length > 0 ? state.order.items.filter(order => order.type === 'shipping_method') : [];
})

const orderTotalPayment = computed(() => {
  return state.order.total_display ?? '-';
})

const reportIssueWithOrder = computed(() => {
  let isNonND = state.order.items.every(
      e => e.supplier_id === null
  );

  let isND = state.order.items.every(
      e => e.supplier_id !== null
  );

  if (isNonND) {
    return {
      clinic: 'Please make contact with your clinic'
    }
  } else if (isND) {
    return {
      nd: 'Please send an email with your issue to',
      email: 'naturaldispensaryorders@swandoola.com'
    }
  } else {
    return {
      clinic: 'and contact your clinic',
      nd: 'Please send an email regarding your issue to',
      email: 'naturaldispensaryorders@swandoola.com'
    }
  }
})

const openPurchase = () => {
  //redirect to orders/payment/:id in this app using router
  router.push({name: 'order-payment', params: {id: state.order.id}});
};

getOrder();
</script>

<style lang="scss" scoped>
.card-container {
  box-shadow: 0.5px 0.5px 15px 0px #0000000D !important;
  border: 1px solid #F0F0F0;
  margin-top: 20px;
  padding: 20px;
}

.font-size-12-400 {
  line-height: 18px;
}
</style>