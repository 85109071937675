<template>
  <v-col cols="12">
    <v-text-field
        placeholder="Search..."
        density="compact"
        class="input-round-20 mx-2"
        width="100%"
        variant="solo"
        elevation="0"
        v-model="setSearchValue"
        hide-details
        rounded
    >
      <template v-slot:prepend-inner>
        <Icon icon="search-icon" color="grey_light"/>
      </template>
    </v-text-field>
  </v-col>
</template>

<script setup lang="ts">
import {ref, watch} from "vue";
import Icon from "@/components/Icons/Icon.vue";

// eslint-disable-next-line no-undef
const emit = defineEmits(['update:modelValue'])

// eslint-disable-next-line no-undef
const props = defineProps({
  modelValue: {
    type: [Number, String]
  },
})

const setSearchValue = ref(props.modelValue);

watch(() => props.modelValue, (newValue) => {
  setSearchValue.value = newValue;
});

watch(setSearchValue, (newValue) => {
  emit('update:modelValue', newValue);
});

</script>


<style lang="scss" scoped>
:deep(.v-field--variant-solo, .v-field--variant-solo-filled) {
  box-shadow: none;
  border: 1px solid #0000001A;
}
</style>