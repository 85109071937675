import type { LoginParams } from "@/api/interfaces/login";

import { HttpService } from "./http";

class LoginService extends HttpService {
  public constructor() {
    super("/auth");
  }

  public login(params: LoginParams): Promise<any> {
    return this.http
      .post(this.route + "/request-token", params)
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }

  public login_sms(params: LoginParams): Promise<any> {
    return this.http
      .post(this.route + "/request-sms", params)
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }

  public login_otp(params: LoginParams): Promise<any> {
    return this.http
      .post(this.route + "/accept-otp", params)
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }

  public processLoginToken(token: string): Promise<any> {
    return this.http
      .post(this.route + "/accept-token", {
        token: token,
        requester: "clients-portal",
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }
}

export const loginService: LoginService = new LoginService();
