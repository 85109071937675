<template>
  <v-sheet class="px-5 py-3" style="background-color: #F5F5F5;">
    <v-row class="px-3 mb-3">
      <v-col class="d-flex">
        <p class="p-text-lg mt-2">Personal Details</p>
      </v-col>
      <v-col class="text-right">
        <v-btn v-if="!edit" color="#F5F5F5" variant="flat" @click="emit('edit')">
          <v-icon :color="color" icon="fa fa-pencil"></v-icon>
        </v-btn>
        <v-btn v-else color="#F5F5F5" variant="flat" @click="handleSave">
          <v-icon :color="buttonColor" icon="fas fa-save"></v-icon>
        </v-btn>
        <!--Close-->
        <v-btn v-if="edit" color="#F5F5F5" variant="flat" @click="handleClose">
          <v-icon :color="color" icon="fas fa-close"></v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-sheet class="px-2 pt-3 pb-2 b-round-20">
      <form-field v-model="state.title" :editting="edit" label="Title" variant="plain"/>
      <form-field v-model="state.first_name" :editting="edit" label="First Name" variant="outlined"/>
      <form-field v-model="state.last_name" :editting="edit" label="Last Name" variant="outlined"/>
      <form-field v-model="state.gender" :editting="edit" label="Gender" variant="outlined"/>
      <form-field v-model="state.dob" :editting="edit" label="Date of Birth" type="date" variant="outlined"/>
      <form-field v-model="state.email" :editting="edit" label="Email" variant="outlined"/>
      <p class="px-3 pb-3 font-size-12-400" style="color: red">{{ emailValidationMessage }}</p>
      <v-sheet class="pt-6">
        <PhoneNumber
            v-model="state.phone"
            :disabled="!edit"
            label="Phone"
        />
      </v-sheet>
      <v-sheet class="pt-6">
        <PhoneNumber
            v-model="state.mobile"
            :disabled="!edit"
            label="Mobile"
        />
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>

<script lang="ts" setup>
import FormField from "@/components/Forms/FormField.vue";
import {defineProps, watch, reactive, defineEmits, shallowRef} from 'vue'
import moment from "moment";
import PhoneNumber from "@/components/locations-selector/PhoneNumber.vue";

const defaultData = {
  title: '-',
  first_name: '-',
  last_name: '-',
  gender: '-',
  dob: moment(),
  email: '-',
  phone: '-',
  mobile: '-',
};

const props = defineProps({
  data: {
    type: Object,
  },
  color: {
    type: String,
  },
  buttonColor: {
    type: String,
  },
  edit: {
    type: Boolean,
    default: false
  },
})

const emit = defineEmits(['save', 'edit', 'close'])

const state = reactive({
  ...props.data,
});

const emailValidationMessage = shallowRef('');

const handleSave = () => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (emailRegex.test(state.email)) {
    emit('save', state);
  } else {
    emailValidationMessage.value = 'Please enter a valid email address.';
  }
};

const handleClose = () => {
  emailValidationMessage.value = ''
  emit('close')
}

// Watch for changes in props.data to update state
watch(() => props.data, (newData) => {
  Object.keys(defaultData).forEach(key => {
    state[key] = newData[key] !== undefined && newData[key] !== null ? newData[key] : defaultData[key];
  });
}, {immediate: true});
</script>