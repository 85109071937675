<template>
  <svg
    width="28"
    height="27"
    viewBox="0 0 28 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.23938 3.84375V11.7792V11.6979V22.2604V3.84375ZM8.02376 14.6771H13.2269C13.4331 14.3701 13.6581 14.0812 13.9019 13.8104C14.1456 13.5396 14.4175 13.2868 14.7175 13.0521H8.02376V14.6771ZM8.02376 19.2812H12.4113C12.355 19.0104 12.3128 18.7396 12.2847 18.4687C12.2566 18.1979 12.2519 17.9271 12.2706 17.6562H8.02376V19.2812ZM5.23938 23.8854C4.78938 23.8854 4.39563 23.7229 4.05813 23.3979C3.72063 23.0729 3.55188 22.6937 3.55188 22.2604V3.84375C3.55188 3.41042 3.72063 3.03125 4.05813 2.70625C4.39563 2.38125 4.78938 2.21875 5.23938 2.21875H15.3925L21.5519 8.15V12.3208C21.2894 12.1944 21.0175 12.0861 20.7363 11.9958C20.455 11.9056 20.1644 11.8333 19.8644 11.7792V8.88125H14.5488V3.84375H5.23938V22.2604H14.0988C14.4738 22.6396 14.8909 22.9646 15.3503 23.2354C15.8097 23.5062 16.3113 23.7229 16.855 23.8854H5.23938ZM18.7394 20.9062C19.6206 20.9062 20.3566 20.6219 20.9472 20.0531C21.5378 19.4844 21.8331 18.7757 21.8331 17.9271C21.8331 17.0785 21.5378 16.3698 20.9472 15.801C20.3566 15.2323 19.6206 14.9479 18.7394 14.9479C17.8581 14.9479 17.1222 15.2323 16.5316 15.801C15.9409 16.3698 15.6456 17.0785 15.6456 17.9271C15.6456 18.7757 15.9409 19.4844 16.5316 20.0531C17.1222 20.6219 17.8581 20.9062 18.7394 20.9062ZM24.4769 24.5896L21.4629 21.6917C21.0722 21.9444 20.6472 22.1476 20.1878 22.301C19.7284 22.4545 19.2456 22.5312 18.7394 22.5312C17.4113 22.5312 16.2824 22.0833 15.3527 21.1874C14.423 20.2916 13.9581 19.2037 13.9581 17.9239C13.9581 16.6441 14.4233 15.5573 15.3536 14.6635C16.284 13.7698 17.4136 13.3229 18.7427 13.3229C20.0717 13.3229 21.2003 13.7705 22.1284 14.6658C23.0566 15.5611 23.5206 16.6482 23.5206 17.9271C23.5206 18.4146 23.4409 18.8795 23.2816 19.3219C23.1222 19.7642 22.9113 20.1735 22.6488 20.5497L25.6581 23.4521L24.4769 24.5896Z"
      fill="currentColor"
    />
  </svg>
</template>
