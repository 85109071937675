<template>
  <div class="number-control-container">
    <v-icon :class="{'disable': disable}" class="number-control-minus" color="grey"
            icon="fa-minus"
            @click="$emit('update:modelValue', props.modelValue ? props.modelValue - 1 : 0)"></v-icon>
    <input :class="{'disable': disable}" :value="modelValue" class="number-control" density="compact" type="number"
           variant="outlined" @input="$emit('update:modelValue', $event.target.value)">
    <v-icon :class="{'disable': disable}" class="number-control-plus" color="grey"
            icon="fa-plus" @click="$emit('update:modelValue', props.modelValue + 1)"></v-icon>
  </div>
</template>

<script lang="ts" setup>
import {defineProps, defineEmits} from 'vue';

const props = defineProps({
  modelValue: {
    type: Number,
  },
  disable: {
    type: Boolean,
    default: false
  }
});
defineEmits(['update:modelValue']);
</script>

<style scoped>
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-control-container {
  border: 1px solid #0000001A;
  border-radius: 10px;
  width: 80px;
  height: 30px;
}

.number-control {
  display: inline-block;
  border-left: 1px solid #0000001A;
  border-right: 1px solid #0000001A;
  border-top: 0;
  border-bottom: 0;
  width: 40%;
  font-size: 14px;
  line-height: 28px;
  margin-top: -1px;
  font-weight: 600;
  text-align: center;
}

.number-control-minus {
  display: inline-block;
  font-size: 12px;
  width: 30%;
  text-align: center;
  margin-top: -2px;
}

.number-control-plus {
  display: inline-block;
  font-size: 12px;
  width: 30%;
  text-align: center;
  margin-top: -2px;
}

.disable {
  pointer-events: none;
}
</style>