<template>
  <v-btn
      :class="customClass"
      :color="setColor"
      :disabled="disabled"
      :variant="variant"
      :width="width"
      class="b-round-20 text-capitalize position-relative"
      flat
      @click="emit('click')"
  >
    <slot name="button-icon">
      <Icon v-if="icon" :class="customIconClass" :color="colorIcon" :icon="icon"/>
    </slot>

    <span>{{ text }}</span>

    <span v-if="count && !singleLine" :class="{'primary-button': !activeFilter, 'white-btn': activeFilter}"
          class="count"
    >
      {{ count }}
    </span>
  </v-btn>
</template>

<script lang="ts" setup>
import Icon from "@/components/Icons/Icon.vue";
import {defineProps, defineEmits, computed} from "vue";
import buttonProps from "@/components/custom/ButtonFilterSelect/ButtonProps";
import {useSettingsStore} from "@/api/store/settings";

const emit = defineEmits(['click']);
const settingsStore = useSettingsStore();

const props = defineProps({...buttonProps})

const setColor = computed(() => {
  if (props.buttonColor === 'buttonColor') {
    return settingsStore.settings.buttonColor;
  }
  return props.buttonColor !== undefined ? props.buttonColor : settingsStore.settings.buttonColor2;
})
</script>

<style lang="scss" scoped>
.count {
  font-size: 8px;
  font-weight: 700;
  line-height: 12px;
  position: absolute;
  top: 25%;
  right: 9px;
  border-radius: 50%;
  width: 9%;
  padding: 3px;
}
</style>