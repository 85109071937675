import { HttpService } from "./http";

class SwanpayService extends HttpService {
  public constructor() {
    super("/client-app/payments");
  }

  getPaymentSession(data = {} as any) {
    return this.http
      .post(this.route + `/combined`, data)
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }
}

export const swanpayService: SwanpayService = new SwanpayService();
