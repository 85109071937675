import {HttpService} from "./http";

class CartService extends HttpService {
    public constructor() {
        super("/client-app/client/carts");
    }

    public index(filters: string | null = null): Promise<any> {

        let route = this.route + "/";

        if (filters) {
            route += "?";
            route += filters;
        }

        return this.http
            .get(route)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public getCart(supplier_id: number | null): Promise<any> {
        const supplierId = supplier_id == 0 ? null : supplier_id;
        return this.http
            .post(this.route, {supplier_id: supplierId})
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public addItem(cart: number | null, product_id: number, quantity: number, product_type?: string): Promise<any> {
        return this.http
            .post(this.route + "/" + cart + "/items", {product_id, quantity, product_type})
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public processOrder(order_id: number, shipping_address_id: number): Promise<any> {
        return this.http
            .post(this.route + "/" + order_id + "/finalize", {
                shipping_address_id: shipping_address_id,
                order_title: "Client Order"
            })
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public bulkOrder(supplier_id: number | null, product_ids: any): Promise<any> {
        return this.http
            .post(this.route + "/bulk-order", {supplier_id, product_ids})
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public removeItem(cart: number | null, item_id: number): Promise<any> {
        return this.http
            .delete(this.route + "/" + cart + "/items/" + item_id)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public changeQuantity(cart: number | null, item_id: number, quantity: number): Promise<any> {
        return this.http
            .post(this.route + "/" + cart + "/items/" + item_id + '/qty', {quantity})
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public applyDiscount(cart: number | null, coupon: string | null): Promise<any> {
        return this.http
            .post(this.route + "/" + cart + "/discounts", {
                discount_code: coupon
            })
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public removeDiscount(cart: number, formData = {}) {
        return this.http.delete(this.route + "/" + cart + "/discounts", {data: formData})
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public getShippingMethod(cartId: number, addressId: number) {
        return this.http
            .get(this.route + "/" + cartId + "/shipping-methods/" + addressId)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }
}

export const cartService: CartService = new CartService();
