<template>
  <div class="form-input">
    <v-list lines="one">
      <v-list-subheader>{{ item.label }}</v-list-subheader>
      <v-list-item v-for="(checkboxItem, index) in item.content" :key="index">
        <v-list-item-content>
          <v-checkbox :name="checkboxItem.name"
                      v-model="checkedItems[index]"
                      :disabled="disabled"
                      @change="updateModelValue">
            <template #label>
              <span>{{ checkboxItem.text }}</span>
            </template>
          </v-checkbox>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script setup>
import { defineProps, ref, watch, defineEmits } from 'vue';

const props = defineProps({
  item: Object,
  disabled: Boolean,
  modelValue: {
    type: Array,
    default: () => []
  }
});

// Initialize checkedItems array to store checkbox states
const checkedItems = ref([]);

// Watch for changes in the item.content array and initialize checkedItems accordingly
watch(() => props.item.content, (newContent) => {
  checkedItems.value = newContent.map(item => {
    // Check if the item exists in modelValue
    return props.modelValue.some(selectedItem => selectedItem.name === item.name);
  });
}, { immediate: true }); // Set immediate: true to initialize checkedItems immediately

// Emit an event when any checkbox changes its state
const emit = defineEmits(['update:modelValue']);

// Update the modelValue with the list of checked items
const updateModelValue = () => {
  const selectedItems = checkedItems.value
    .map((isChecked, index) => isChecked ? props.item.content[index] : null)
    .filter(item => item !== null);
  emit('update:modelValue', selectedItems);
};
</script>
