import { AppUpdate } from '@capawesome/capacitor-app-update';
import { AppUpdateAvailability } from '@capawesome/capacitor-app-update/dist/esm/definitions';

const getCurrentAppVersion = async () => {
  const result = await AppUpdate.getAppUpdateInfo();
  return result.currentVersion;
};

const getAvailableAppVersion = async () => {
  const result = await AppUpdate.getAppUpdateInfo();
  return result.availableVersion;
};

const openAppStore = async () => {
  await AppUpdate.openAppStore();
};

const performImmediateUpdate = async () => {
  const result = await AppUpdate.getAppUpdateInfo();
  if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
    return;
  }
  if (result.immediateUpdateAllowed) {
    await AppUpdate.performImmediateUpdate();
  }
};

const startFlexibleUpdate = async () => {
  const result = await AppUpdate.getAppUpdateInfo();
  if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
    return;
  }
  if (result.flexibleUpdateAllowed) {
    await AppUpdate.startFlexibleUpdate();
  }
};

const completeFlexibleUpdate = async () => {
  await AppUpdate.completeFlexibleUpdate();
};

export {
    getCurrentAppVersion,
    getAvailableAppVersion,
    openAppStore,
    performImmediateUpdate,
    startFlexibleUpdate,
    completeFlexibleUpdate,
};