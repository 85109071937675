<template>
    <v-sheet color="#FFF" height="50">
        <v-row justify="center" class="mt-1 py-1">
            <v-col cols="auto">
                <v-btn class="b-round-20" :class="state.modelValue.method == 'in_person' ? 'text-white' : ''"
                    :color="state.modelValue.method == 'in_person' ? state.color : ''"
                    :variant="state.modelValue.method == 'in_person' ? 'flat' : 'text'"
                    @click="state.modelValue.method = 'in_person';emit('update:modelValue', state.modelValue)">
                    <span class="p-text-sm">In Person</span>
                </v-btn>
            </v-col>

            <v-col cols="auto">
                <v-btn class="b-round-20" :class="state.modelValue.method == 'video' ? 'text-white' : ''"
                    :color="state.modelValue.method == 'video' ? state.color : ''"
                    :variant="state.modelValue.method == 'video' ? 'flat' : 'text'" 
                    @click="state.modelValue.method = 'video';emit('update:modelValue', state.modelValue)">
                    <span class="p-text-sm">Video</span>
                </v-btn>
            </v-col>

            <v-col cols="auto">
                <v-btn class="b-round-20" :class="state.modelValue.method == 'phone' ? 'text-white' : ''"
                    :color="state.modelValue.method == 'phone' ? state.color : ''"
                    :variant="state.modelValue.method == 'phone' ? 'flat' : 'text'" 
                    @click="state.modelValue.method = 'phone';emit('update:modelValue', state.modelValue)">
                    <span class="p-text-sm">Phone</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, reactive } from 'vue';
const props = defineProps(['modelValue', 'color'])
const emit = defineEmits(['update:modelValue']);

const state = reactive({
    modelValue: props.modelValue,
    color: props.color,
});
</script>

<style>
.v-picker__header {
    display: none !important;
}

.v-picker-title {
    display: none !important;
}
</style>