<template>
  <CardComponent
      class="font-size-12-400"
  >
    <v-sheet
        :class="{
          'clinic-logo-border secondary-background-button-color': order.supplier_id === 'clinic', 
          'default-logo-border': order.supplier_id !== 'clinic'
        }"
        class="logo-container"
    >
      <div :style="{backgroundImage: `url(${getClinicAvatar})`}" class="logo-container__image"></div>
    </v-sheet>

    <div class="order-description-container">
      <div>
        <div class="font-size-12-400 ellipsis">
          Order ID:
          <span class="font-size-12-600">#{{ order.order_id }}</span>
          <span class="font-size-12-400 font-grey-dark">
            {{ getTotalPartsOfTheOrder }}
          </span>
        </div>
        <div class="font-size-10-400 font-grey-dark ellipsis">
          {{ getOrderDate }}
        </div>
        <p class="ellipsis subtitle">
          {{ getTotalOrderItems }}
          <span class="secondary-button-color">|</span>
          Client Order
          <span v-if="getShippingDetails" class="secondary-button-color">|</span>
          {{ getShippingDetails }}
        </p>
      </div>

      <p class="font-size-12-600">
        {{ getTotalPaymentAmount }}

        <span :class="getPaymentStatus(order).color" class="font-size-10-600">
          ({{ getPaymentStatus(order).status }})
        </span>
      </p>
    </div>

    <div>
      <v-btn
          class="text-white b-round-20 outline-btn white-btn"
          elevation="0"
          height="32px"
          x-small
          @click="router.push({ name: 'order-view', params: { id: order.order_id } })"
      >
        View
      </v-btn>
    </div>
  </CardComponent>
</template>

<script lang="ts" setup>
import CardComponent from "@/components/custom/CardComponent.vue";
import {computed, defineProps} from "vue";
import {useRouter} from "vue-router";
import moment from 'moment';
import {getPaymentStatus} from "@/views/OrdersComponents/Helpers";

const router = useRouter();

const props = defineProps({
  order: {
    type: Object,
  },
});

const getClinicAvatar = computed(() => {
  if (props.order.supplier_id == 'clinic') {
    return props.order.clinic_avatar?.file.file_path ?? ''
  }

  return '/img/tnd_hands_logo.png'
})

const getOrderDate = computed(() => {
  return moment(props.order.created_at).format('DD MMMM YYYY') ?? ''
})

const getTotalPartsOfTheOrder = computed(() => {
  const orderPart = props.order.order_part ?? ''
  const total = props.order.total_order_parts ?? ''

  if (total > 1) {
    return ` (Part ${orderPart} of ${total})`
  }

  return ''
})

const getTotalOrderItems = computed(() => {

  if (props.order.items.length > 1) {
    return `${props.order.items.length} Items` ?? ''
  }

  return `${props.order.items.length} Item` ?? ''
})

const getTotalPaymentAmount = computed(() => {
  return `${props.order.currency_symbol}${props.order.total.toFixed(2)}` ?? ''
})

const getShippingDetails = computed(() => {
  return props.order.fulfillment_status ?? ''
})
</script>

<style lang="scss" scoped>
.logo-container {
  border-radius: 30%;
  padding: 8px;
  width: 65px;
  height: 65px;

  &__image {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.default-logo-border {
  border: 1px solid #304978;
  background: #3A5485;
}

.order-description-container {
  padding: 0 10px;

  .subtitle {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
  }

  .ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>