<template>
  <div class="form-input">
    <div class="text-caption">
      {{ item.label }}
    </div>
    <v-slider :name="item.name"
      v-model="sliderValue"
      :min="item.min ?? 0" :max="item.max ?? 10" :step="item.step ?? 1"
      thumb-label>
    </v-slider>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits } from 'vue';

const props = defineProps({
  item: Object,
  modelValue: [String, Number]
});

const emit = defineEmits(['update:modelValue']);

const sliderValue = ref(props.modelValue);

watch(() => props.modelValue, (newValue) => {
  sliderValue.value = newValue;
});

watch(sliderValue, (newValue) => {
  emit('update:modelValue', newValue);
});
</script>