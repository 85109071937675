<template>
  <v-slider
      v-model="answer"
      :ticks="tickLabels"
      :max="10"
      :min="min"
      step="1"
      tick-size="0"
      :color="color"
      track-color="grey"
      :thumb-size="14"
      :track-size="trackSize"
      thumb-color="white"
      :show-ticks="showTicks"
      :direction="direction"
      :disabled="disable"
      :readonly="readonly"
      hide-details
  />
</template>

<script setup lang="ts">
import {ref, watch} from 'vue';
import {defineProps, defineEmits} from 'vue';

const emit = defineEmits(['update:modelValue']);

defineProps({
  question: {
    type: Object,
  },
  disable: {
    type: Boolean,
    default: false
  },
  direction: {
    type: String,
    default: 'horizontal'
  },
  trackSize: {
    type: String,
  },
  showTicks: {
    type: [String, Boolean],
    default: 'always'
  },
  readonly: {
    type: Boolean,
    default: false
  },
  color: {
    type: String,
    default: '#1EBCD5'
  },
  min: {
    type: Number,
    default: 1
  }
});

const answer = ref(0)

const tickLabels = ref({
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10'
});

watch(answer, (newValue) => {
  emit('update:modelValue', newValue);
});
</script>

<style scoped lang="scss">
:deep(.v-slider-thumb__surface) {
  border: 1px solid v-bind(color);
}

:deep(.v-slider-track__tick-label) {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #b5b8bc;
}

:deep(.v-slider.v-input--horizontal) {
  margin: 0;
}
</style>