<template>
  <section>
    <ServicePackageDetails
        v-if="isClinicServiceOrPackage"
        :is-clinic-service-or-package="isClinicServiceOrPackage"
        :is-shipping="isShipping"
        :item="item"
        :order="order"
        :payment-status="paymentStatus"
        :supplier-id="supplierId"
        :supplier-order="supplierOrder"
    />
    <ProductDetails
        v-else
        :is-shipping="isShipping"
        :item="item"
        :payment-status="paymentStatus"
        :supplier-id="supplierId"
    />
  </section>

  <p v-if="order.instalments.length > 0 && isPackage" class="border-bottom"></p>

  <InstalmentsDetails v-if="order.instalments.length > 0 && isPackage" :order="order" :supplierOrder="supplierOrder"/>

  <p v-if="!isShipping" class="border-bottom"></p>
</template>

<script lang="ts" setup>
import {defineProps, computed} from 'vue';
import ProductDetails from "@/views/OrdersComponents/OrderDetails/ProductDetails/ProductDetails.vue";
import ServicePackageDetails
  from "@/views/OrdersComponents/OrderDetails/ServicePackageDetails/ServicePackageDetails.vue";
import InstalmentsDetails from "@/views/OrdersComponents/OrderDetails/ServicePackageDetails/InstalmentsDetails.vue";

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  paymentStatus: {
    type: Object,
    default: () => ({color: '', status: ''})
  },
  supplierOrder: {
    type: Object,
    default: () => ({})
  },
  order: {
    type: Object,
    default: () => ({})
  },
  supplierId: {
    type: String,
  }
});

const isClinicServiceOrPackage = computed(() => {
  return props.item.type === 'clinic_service' || props.item.type === 'service' || isPackage.value;
});

const isShipping = computed(() => {
  return props.item.type === 'shipping_method';
});

const isPackage = computed(() => {
  return props.item.type === 'clinic_package' || props.item.type === 'package'
});
</script>

<style lang="scss" scoped>
.border-bottom {
  border: 0.8px solid #F0F0F0
}
</style>