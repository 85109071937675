<template>
  <input
      :id="id"
      type="date"
      :name="name"
      v-model="setDate"
      :disabled="disabled"
      :min="min"
      :max="max"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
  />
</template>

<script setup lang="ts">
import {ref, defineEmits, defineProps, watch} from 'vue';

const emit = defineEmits(['update:modelValue', 'blur', 'focus']);

const props = defineProps({
  id: {
    type: String,
    default: '',
  },

  name: {
    type: String,
    default: '',
  },

  value: {
    type: String,
    default: '',
  },

  modelValue: {
    type: String,
    default: '',
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  min: {
    type: [String, Number],
    default: '',
  },

  max: {
    type: [String, Number],
    default: '',
  },
})

const setDate = ref(props.modelValue);

watch(() => props.modelValue, (value) => {
  setDate.value = value;
});

watch(setDate, (newValue) => {
  emit('update:modelValue', newValue);
});
</script>