import {HttpService} from "./http";

class ServicesService extends HttpService {
    public constructor() {
        super("/client-app/shop/services");
    }
    
    public index(filters: string | null = null): Promise<any> {
        return this.http
            .get(`${this.route}/`, {
                params: filters
            })
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public getService(id: number): Promise<any> {
        return this.http
            .get(this.route + "/" + id)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public getServiceAvailability(id: number, requestData: any): Promise<any> {
        return this.http
            .post(this.route + "/" + id + "/availability", requestData)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }
}

export const servicesService: ServicesService = new ServicesService();
