<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.35272 17.6082L5.70627 17.9618L6.05983 17.6082L11 12.668L15.9402 17.6082L16.2938 17.9618L16.6473 17.6082L17.6098 16.6457L17.9634 16.2922L17.6098 15.9386L12.6696 10.9984L17.6098 6.05824L17.9634 5.70469L17.6098 5.35113L16.6473 4.38863L16.2938 4.03508L15.9402 4.38863L11 9.32883L6.05983 4.38863L5.70627 4.03508L5.35272 4.38863L4.39022 5.35113L4.03667 5.70469L4.39022 6.05824L9.33042 10.9984L4.39022 15.9386L4.03667 16.2922L4.39022 16.6457L5.35272 17.6082Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
</template>
