<template>
    <v-container fluid>
      <v-row align="center">
        <v-col cols="2">
          <v-btn @click="scrollLeft" icon="fa fa-arrow-left"></v-btn>
        </v-col>
        <v-col cols="8">
          <div class="time-grid">
            <v-row>
              <v-col
                v-for="(time, index) in gridTimeBlocks"
                :key="index"
                cols="4"
                class="time-cell"
              >
                <v-btn
                  text
                  outlined
                  :style="time === modelValue ? selectedStyle : null"
                  @click="selectTime(time)"
                >
                  {{ time }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="2">
          <v-btn @click="scrollRight" icon="fa fa-arrow-right"></v-btn>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script setup>
  import { ref, computed, defineProps, defineEmits } from 'vue';
  import { VContainer, VRow, VCol, VBtn } from 'vuetify/components';
  
  const props = defineProps({
    modelValue: String, // The currently selected time passed from parent component via v-model
    highlightColor: {
      type: String,
      default: 'rgba(0, 255, 0, 0.5)', // Default to a semi-transparent green
    },
    availableTimeslots: {
      type: Array,
      required: true,
      default: () => [],
    },
  });
  
  const emit = defineEmits(['update:modelValue']);
  
  const currentBlockIndex = ref(0);
  
  // Compute the grid of time blocks to display in a 3x3 grid
  const gridTimeBlocks = computed(() => {
    return props.availableTimeslots.slice(currentBlockIndex.value, currentBlockIndex.value + 9);
  });
  
  // Method to handle time selection
  const selectTime = (time) => {
    emit('update:modelValue', time); // Emit the selected time to update v-model
  };
  
  // Computed style for the selected button
  const selectedStyle = computed(() => ({
    backgroundColor: props.highlightColor,
    borderColor: props.highlightColor,
    color: 'white', // Ensure the text is visible against the highlight color
  }));
  
  // Scroll handling
  const scrollRight = () => {
    if (currentBlockIndex.value + 9 < props.availableTimeslots.length) {
      currentBlockIndex.value += 9;
    }
  };
  
  const scrollLeft = () => {
    if (currentBlockIndex.value - 9 >= 0) {
      currentBlockIndex.value -= 9;
    }
  };
  </script>
  
  <style scoped>
  .time-cell {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>
  