import {computed} from 'vue';
import moment from 'moment';

const defaultObject = {
    date_from: null,
    date_to: null,
    age_from: null,
    age_to: null,
    method: null,
    status: null
};

export default function ageAndDuration(dateOfBirth = '', alert = defaultObject) {

    const formatYearsAndMonths = (years: any, months: any, days: any) => {
        let value = '';

        if (years > 0) {
            value = `${years} yr${years > 1 ? 's' : ''}`;
        }

        if (months > 0) {
            value = `${months} mo`;
        }

        if (days > 0) {
            value = `${days} day${days > 1 ? 's' : ''}`;
        }

        return value
    };

    const getTheDifferenceBetweenTwoDates = (start: any, end: any) => {
        const years = end.diff(start, 'years');
        const months = end.diff(start, 'months');
        const days = end.diff(start, 'days');

        if (years) {
            return formatYearsAndMonths(years, null, null);
        } else if (months) {
            return formatYearsAndMonths(null, months, null);
        } else if (days) {
            return formatYearsAndMonths(null, null, days);
        } else if (start.isSame(end, 'day')) {
            return '1 day';
        } else {
            return '-';
        }
    }

    const getAgeBasedOnDateFrom = computed(() => {
        const dateFrom = moment(alert.date_from);
        const dob = moment(dateOfBirth);

        return getTheDifferenceBetweenTwoDates(dob, dateFrom);
    });

    const getDurationBasedOnDateFrom = computed(() => {
        const start = moment(alert.date_from);
        const end = alert.date_to ? moment(alert.date_to) : moment();

        return getTheDifferenceBetweenTwoDates(start, end);
    });

    const age = computed(() => {
        if (alert.method === 'date') {
            return getAgeBasedOnDateFrom.value;
        } else {

            return formatYearsAndMonths(alert.age_from, null, null);
        }
    });

    const duration = computed(() => {
        if (alert.method === 'date') {
            return getDurationBasedOnDateFrom.value;
        } else {
            const dob = moment(dateOfBirth);
            dob.add(alert.age_from, 'years');

            if (alert.age_to && alert.age_from && alert.status === 'Ended') {

                const diffToFindTheStart = (alert.age_to - alert.age_from);

                return formatYearsAndMonths(diffToFindTheStart, null, null);
            }

            return getTheDifferenceBetweenTwoDates(dob, moment());
        }
    });

    return {
        age,
        duration
    };
}
