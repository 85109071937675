import moment from "moment/moment";

const filterValues = [
    {
        name: 'Filter',
        label: 'All'
    },
    {
        name: 'created_at',
        label: 'Date'
    },
    {
        name: 'id',
        label: 'Id'
    },
    {
        name: 'not_paid',
        label: 'Not Paid'
    },
    {
        name: 'paid',
        label: 'Paid'
    },
]

const sortValues = [
    {
        name: 'asc',
        label: 'Asc'
    },
    {
        name: 'desc',
        label: 'Desc'
    }
]

const getPaymentStatus = (order: any) => {
    const paymentStatus = order.payment_status;
    const orderCreationDate = moment(order.created_at);

    // Calculation of overdue time limit
    const overdueTimeLimit = moment(orderCreationDate).add(24, 'hours');

    const isOverdue = paymentStatus === 'not_paid' && moment().isAfter(overdueTimeLimit);

    switch (order.payment_status) {
        case 'paid':
            return {
                color: 'font-success',
                status: 'Paid'
            }
        case 'partial':
            return {
                color: 'font-warning',
                status: 'Part Paid'
            }
        case 'processing':
            return {
                color: 'font-info',
                status: 'Processing'
            }
        case 'chargeback':
        case 'failed':
            return {
                color: 'font-success',
                status: 'Failed'
            }
        case 'not_paid':
            if (isOverdue) {
                return {
                    color: 'font-danger',
                    status: 'Overdue'
                };
            } else {
                return {
                    color: 'font-danger',
                    status: 'Not Paid'
                };
            }
        default:
            return {
                color: '',
                status: ''
            }
    }
}

export {filterValues, sortValues, getPaymentStatus}