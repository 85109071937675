<template>
  <div :style="getContainerStyle" class="position-relative w-100 mx-5">
    <div v-for="(notification, index) in notifications.slice(0, 3)" :key="notification.id">
      <Component :is="getComponent(notification)" :style="getStyle(index)" class="position-absolute"/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, defineEmits, defineProps} from 'vue';
import OrderNotificationCard from '@/components/Layout/LayoutV2/Notifications/OrderNotificationCard.vue';
import {h} from 'vue';

const emit = defineEmits(['onClose'])

const props = defineProps({
  notifications: {
    type: Array,
    default: () => []
  }
})

const getComponent = (notification) => {
  return h(
      OrderNotificationCard,
      {
        notification,
        onClose: (notification) => emit('onClose', notification)
      }
  )
}

/**
 * Get the container style based on the number of notifications
 */
const getContainerStyle = computed(() => {
  let height = 0;

  switch (props.notifications.slice(0, 3).length) {
    case 1:
      height = 110;
      break;
    case 2:
      height = 130;
      break;
    case 3:
      height = 150;
      break;
  }

  return {
    minHeight: `${height}px`
  }
})

/**
 * Get the style for the notification card
 * @param index
 */
const getStyle = (index: number) => {
  const zIndex = props.notifications.length - index;
  const top = index > 0 ? index === 1 ? `${index * 30}px` : `${index * 23}px` : '0px';
  const width = index > 0 ? index === 1 ? `98%` : '94%' : '100%';
  return {
    zIndex: zIndex,
    top: top,
    width: width,
    right: 0,
    left: 0,
    margin: 'auto',
  }
}
</script>