<template>
    <iframe width="100%" height="600px" :src="state.payment_url"></iframe>
</template>

<script lang="ts" setup>
import { reactive } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const props = reactive({
    payment_token: route.params.token,
});

const state = reactive({
    payment_url: process.env.VUE_APP_SWP_URL + '/payment/' + props.payment_token
});
</script>