<template>
  <svg fill="none" height="35" viewBox="0 0 37 35" width="37" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M32.1213 10.5151C31.8595 10.0916 31.4847 9.73863 31.0334 9.49069C30.582 9.24275 30.0696 9.10829 29.546 9.10041H10.1555L9.27683 5.90318C9.18805 5.59455 8.99 5.32307 8.71492 5.13297C8.43984 4.94288 8.10394 4.84536 7.76194 4.8563H4.73217C4.3304 4.8563 3.94508 5.00535 3.66098 5.27066C3.37689 5.53596 3.21729 5.8958 3.21729 6.271C3.21729 6.64621 3.37689 7.00604 3.66098 7.27135C3.94508 7.53666 4.3304 7.68571 4.73217 7.68571H6.61063L10.7917 22.2006C10.8805 22.5092 11.0785 22.7807 11.3536 22.9708C11.6287 23.1609 11.9646 23.2584 12.3066 23.2475H25.9406C26.2203 23.2467 26.4944 23.1736 26.7323 23.0362C26.9703 22.8989 27.1629 22.7027 27.2888 22.4694L32.2576 13.1889C32.473 12.7673 32.5733 12.3026 32.5495 11.836C32.5257 11.3695 32.3786 10.9157 32.1213 10.5151Z"
        fill="white"/>
    <path
        d="M11.5492 30.3203C12.8042 30.3203 13.8215 29.3702 13.8215 28.1982C13.8215 27.0262 12.8042 26.0762 11.5492 26.0762C10.2942 26.0762 9.27686 27.0262 9.27686 28.1982C9.27686 29.3702 10.2942 30.3203 11.5492 30.3203Z"
        fill="white"/>
    <path
        d="M26.6979 30.3203C27.9528 30.3203 28.9702 29.3702 28.9702 28.1982C28.9702 27.0262 27.9528 26.0762 26.6979 26.0762C25.4429 26.0762 24.4255 27.0262 24.4255 28.1982C24.4255 29.3702 25.4429 30.3203 26.6979 30.3203Z"
        fill="white"/>
  </svg>
</template>
