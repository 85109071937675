<template>
  <v-container
      v-if="data.length > 0" class="px-0 position-relative"
      style="background-color:#F0F0F0; min-height:100vh"
  >
    <section v-for="(item, index) in filteredData" :key="`order-${index}`">
      <v-card v-if="item?.items.length > 0" class="card-container">
        <v-card-title class="font-size-18-600 pt-0 pl-0">
          Part {{ index + 1 }} of {{ filteredData.length }}
        </v-card-title>

        <ShopCartOrderSummary
            :item="item"
            @make-payment="processOrder"
        />
      </v-card>
    </section>

    <v-card class="card-container pb-6">
      <section v-if="getShippingAddress">
        <v-card-title class="font-size-18-600 pl-0">
          Shipping
        </v-card-title>
        <div>
          <v-card-title class="font-size-14-600 pl-0">
            Shipping Address
          </v-card-title>
          <div class="d-flex align-center ga-3">
            <Icon icon="address-icon"/>

            <div class="font-size-12-400 d-flex justify-space-between w-100">
              <div>
                <p>
                  {{ getShippingAddress.address_1 }},
                  {{ getShippingAddress.address_2 }},
                  {{ getShippingAddress.address_3 }}
                </p>
                <p class="mt-1">
                  {{ getShippingAddress.post_code }},
                  {{ getShippingAddress.city }},
                </p>
                <p class="mt-1">
                  {{ getShippingAddress.country }}
                </p>
              </div>
              <div @click="router.push({ name: 'client-details'})">
                <v-btn
                    class="text-white b-round-20 d-flex justify-end pr-1"
                    elevation="0"
                    height="32px"
                    x-small
                >
                  <v-icon :color="settingsStore.settings.secondaryColor" icon="fa fa-pencil"></v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section v-if="hasProducts">
        <v-card-title class="font-size-14-600 pl-0">
          Shipping Method
        </v-card-title>

        <div v-if="isPaymentDisabled" class="alert-message font-size-12-400">
          Please ensure that a shipping method is added to proceed with the order.
        </div>

        <div v-for="(itemShipping, index) in data" :key="`shipping-${index}`" class="font-size-12-400">
          <ShopCartOrderShippingMethod
              v-if="itemShipping.items.length > 0"
              :disabled="state.loadingOnRemove"
              :item="itemShipping"
              :shipping-address="getShippingAddress"
              :user-store="userStore"
              class="py-4"
              @delete="removeShipping"
              @add-shipping="addShipping"
          />
        </div>
      </section>
    </v-card>
  </v-container>
  <v-container v-else>
    <p class="p-text-lg text-center">Cart not found.</p>
  </v-container>

  <section class="pt-12">
    <ButtonFixed
        :disabled="isPaymentDisabled || data.length === 0"
        custom-class="text-white primary-button"
        text="Make Payment"
        @click="processOrder"
    />
  </section>

  <ShopCartAddShippingMethodModal
      v-if="shippingState.show"
      v-model="shippingState.show"
      v-bind="shippingState"
      @addShipping="getCarts"
  />
</template>

<script lang="ts" setup>
import {reactive, onMounted, ref, computed, inject} from 'vue';
import {cartService} from '@/api/services/cart';
import {ordersService} from '@/api/services/order';
import {clientService} from '@/api/services/client';
import {useSettingsStore} from '@/api/store/settings';
import {Browser} from '@capacitor/browser';
import {getToken} from '@/api/services/auth';
import router from '@/router';
import ShopCartOrderSummary from "@/views/ShopComponents/ShopCartOrderSummary/ShopCartOrderSummary.vue";
import ButtonFixed from "@/components/custom/ButtonFixed.vue";
import Icon from "@/components/Icons/Icon.vue";
import ShopCartOrderShippingMethod from "@/views/ShopComponents/ShopCartOrderSummary/ShopCartOrderShippingMethod.vue";
import ShopCartAddShippingMethodModal
  from "@/views/ShopComponents/ShopCartOrderSummary/ShopCartAddShippingMethodModal.vue";
import {useUserStore} from "@/api/store/user";

const settingsStore = useSettingsStore();
const userStore = useUserStore();

const storedToken = getToken();

const snackBarPlugin = inject('snackbar');

const state = reactive({
  address: null as any,
  addresses: [] as any[],
  showProducts: false,
  editAddress: false,
  order: null as any | null,
  loadingOnRemove: false as boolean,
});

const shippingState = reactive({
  shippingId: null as any | null,
  cartId: null as any | null,
  show: false,
});

const data = ref([]);

const openPurchase = async (token: string, order: number) => {
  await Browser.open({url: process.env.VUE_APP_SWP_URL + '/payment/' + token + '/' + order});
  router.push({name: 'order-confirmation'});
};

const processOrder = () => {
  ordersService.finalize(data.value[0].order_id, state.address.id).then((result) => {
    openPurchase(storedToken as string, result.order.id);
    console.log('order processed', result);
  }).catch((error) => {
    console.log('order error', error);
  });
  /*
  cartService.processOrder(cartStore.items[0].order_id, state.address.id).then((result) => {
      openPurchase(storedToken as string, result.order.id);
      console.log('order processed', result);
  })
  .catch((error) => {
      console.log('order error', error);
  });*/
};

const getCarts = () => {
  settingsStore.loading = true;
  cartService.index().then((response: any) => {
    data.value = response.data;
  }).catch((error: any) => {
    console.log(error);
  }).finally(() => {
    settingsStore.loading = false;
  })
};

const getAddresses = async () => {
  const response = await clientService.addresses();
  state.addresses = response;
  state.addresses.map((address: any) => {
    address.title = address.one_line;
  });
  if (response.length > 0) {
    state.address = response.find((address: any) => address.type === 'shipping');
  }
};

const addShipping = (cartId: any) => {
  shippingState.cartId = cartId;
  shippingState.shippingId = getShippingAddress.value.id;
  shippingState.show = true;
};

const removeShipping = (cartId: any, itemId: any) => {
  state.loadingOnRemove = true;
  cartService.removeItem(cartId, itemId).then(() => {
    getCarts();
    state.loadingOnRemove = false;
    snackBarPlugin.warningSnackbar('Shipping method removed successfully');
  }).catch((error: any) => {
    snackBarPlugin.errorSnackbar('Failed to remove shipping method');
    console.log(error);
  });
};

const getShippingAddress = computed(() => {
  return state.address ?? null;
})

const filteredData = computed(() => {
  return data.value.filter((item) => item.items.length > 0);
});

const hasProducts = computed(() => {
  return filteredData.value.some(cart =>
      cart.items.some(item => item.clean_product_type === 'Product')
  );
});

const isPaymentDisabled = computed(() => {
  return filteredData.value.some(cart =>
      cart.items.some(item => item.clean_product_type === 'Product') &&
      !cart.items.some(item => item.product?.type === 'shipping_method')
  );
});

onMounted(() => {
  getCarts();
  getAddresses();
})
</script>

<style lang="scss" scoped>
.card-container {
  box-shadow: 0.5px 0.5px 15px 0px #0000000D !important;
  border: 1px solid #F0F0F0;
  margin: 20px;
  padding: 10px 20px;
}

.alert-message {
  background-color: #ffebee;
  color: #d32f2f;
  padding: 6px;
  border-radius: 4px;
  margin-bottom: 10px;
}
</style>