<template>
    <v-sheet :color="bgcolour ?? '#FFF'" height="50">
        <v-row justify="center" class="mt-1 py-1">
            <v-col cols="auto" v-for="tab in tabs" :key="tab.name">
                <v-btn class="b-round-20" :class="state.modelValue.method == 'in-person' ? 'text-white' : ''"
                    :color="state.modelValue.name == tab.name ? colour : ''"
                    :variant="state.modelValue.name == tab.name ? 'flat' : 'text'"
                    @click="state.modelValue = tab;emit('update:modelValue', state.modelValue)">
                    <span class="p-text-sm" :class="dark && state.modelValue.name == tab.name  ? 'text-white' : ''">{{ tab.name }}</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, reactive } from 'vue';
const props = defineProps(['modelValue', 'colour', 'bgcolour', 'tabs', 'dark'])
const emit = defineEmits(['update:modelValue']);

const state = reactive({
    modelValue: props.modelValue,
    tabs: props.tabs,
    dark: props.dark,
});
</script>

<style>
.v-picker__header {
    display: none !important;
}

.v-picker-title {
    display: none !important;
}
</style>