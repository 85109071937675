<template>
  <div class="form-input">
    <v-text-field :name="item.name" variant="outlined" :type="item.variant || 'text'" :modelValue="modelValue"
      @input="updateValue">
      <template #label>
        <span>{{ item.label }}<strong class="text-red" v-if="item.isRequired"> *</strong></span>
      </template>
    </v-text-field>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
const props = defineProps({
  item: Object,
  modelValue: [String, Number, Boolean, Array, Object]
});

const emit = defineEmits(['update:modelValue']);

const updateValue = (event) => {
  emit('update:modelValue', event.target.value);
};
</script>