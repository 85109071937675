<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1523_380)">
      <path d="M5.11394 13.9779V12.3112H10.9473V13.9779H5.11394ZM5.11394 10.6445V8.97786H14.2806V10.6445H5.11394ZM5.11394 7.3112V5.64453H14.2806V7.3112H5.11394Z" fill="currentColor"/>
      <path d="M15.2806 16.061H3.61393V4.39437H15.2806V8.64437L16.9473 7.06103V4.39437C16.9473 3.9777 16.7806 3.56103 16.4473 3.2277C16.1139 2.89437 15.6973 2.7277 15.2806 2.7277H11.7806C11.6139 2.2277 11.2806 1.81104 10.8639 1.56104C10.4473 1.2277 9.94727 1.06104 9.44727 1.06104C8.94727 1.06104 8.44727 1.14437 8.0306 1.4777C7.61393 1.81104 7.36393 2.2277 7.11393 2.7277H3.61393C3.19727 2.7277 2.7806 2.89437 2.44727 3.2277C2.11393 3.56103 1.94727 3.89437 1.94727 4.39437V16.061C1.94727 16.561 2.11393 16.8944 2.44727 17.2277C2.7806 17.561 3.19727 17.7277 3.61393 17.7277H15.2806C15.6973 17.7277 16.1139 17.561 16.4473 17.2277C16.7806 16.8944 16.9473 16.4777 16.9473 16.061H15.2806ZM9.0306 2.64437C9.11393 2.56104 9.2806 2.4777 9.44727 2.4777C9.61393 2.4777 9.7806 2.56104 9.86393 2.64437C9.94727 2.7277 10.0306 2.89437 10.0306 3.06104C10.0306 3.2277 9.94727 3.39437 9.86393 3.4777C9.7806 3.56104 9.61393 3.64437 9.44727 3.64437C9.2806 3.64437 9.11393 3.64437 9.0306 3.56104C8.94727 3.4777 8.86393 3.31104 8.86393 3.14437C8.86393 2.89437 8.94727 2.81104 9.0306 2.64437Z" fill="currentColor"/>
      <path d="M12.6973 14.8942V13.1442L17.1139 8.72754L18.8639 10.4775L14.4473 14.8942H12.6973Z" fill="currentColor"/>
      <path d="M19.4473 9.89437L17.6973 8.14437L18.2806 7.56104C18.4473 7.39437 18.6973 7.31104 18.8639 7.31104C19.0306 7.31104 19.2806 7.39437 19.4473 7.56104L20.1139 8.14437C20.2806 8.31104 20.3639 8.4777 20.3639 8.7277C20.3639 8.9777 20.2806 9.14437 20.1139 9.31103L19.4473 9.89437Z" fill="#1EBCD5"/>
    </g>
    <defs>
      <clipPath id="clip0_1523_380">
        <rect width="20" height="20" fill="white" transform="translate(0.697266 0.644531)"/>
      </clipPath>
    </defs>
  </svg>
</template>