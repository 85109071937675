<template>
  <v-alert type="info">
    You will now be logged out.
  </v-alert>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import { useSettingsStore } from '@/api/store/settings';
import { useUserStore } from '@/api/store/user';

const userStore = useUserStore();
const router = useRouter();

const logout = () => {
  userStore.logout();
  setTimeout(() => {
    window.location.href = "/";
  }, 1000);
}

logout();
</script>