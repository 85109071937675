<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="alert-content__icon"
    data-v-4aebb22b=""
    data-v-2145b20c=""
  >
    <circle cx="8" cy="8" r="8" fill="#D41F1F" data-v-4aebb22b=""></circle>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.81811 11.182C4.52522 10.8891 4.52522 10.4143 4.81811 10.1214L6.93934 8.00014L4.81792 5.87872C4.52503 5.58583 4.52503 5.11096 4.81792 4.81806C5.11082 4.52517 5.58569 4.52517 5.87858 4.81806L8 6.93948L10.1214 4.81806C10.4143 4.52517 10.8892 4.52517 11.1821 4.81806C11.475 5.11095 11.475 5.58583 11.1821 5.87872L9.06066 8.00014L11.1819 10.1214C11.4748 10.4143 11.4748 10.8891 11.1819 11.182C10.889 11.4749 10.4141 11.4749 10.1212 11.182L8 9.0608L5.87877 11.182C5.58588 11.4749 5.11101 11.4749 4.81811 11.182Z"
      fill="white"
      data-v-4aebb22b=""
    ></path>
  </svg>
</template>
