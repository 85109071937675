import {HttpService} from "./http";

class NotificationsService extends HttpService {
    public constructor() {
        super("/client-app/client/notifications");
    }

    public index(filters: string | object | null = null): Promise<any> {
        return this.http
            .get(`${this.route}/`, {
                params: filters
            })
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public mostRecent(): Promise<any> {
        return this.http
            .get(this.route + "/most-recent")
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public show(id: number): Promise<any> {
        return this.http
            .get(this.route + "/" + id)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public read(id: number): Promise<any> {
        return this.http
            .post(this.route + "/read/" + id)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public delete(id: number): Promise<any> {
        return this.http
            .delete(this.route + "/" + id)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }
}

export const notificationsService: NotificationsService = new NotificationsService();
