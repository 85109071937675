<template>
  <div class="form-input">
    <v-select 
    :name="item.name" 
    :items="item.content" 
    :item-title="item => item.text" 
    :multiple="item.isMultiSelect"
    :modelValue="modelValue" 
    @update:modelValue="updateValue" 
    variant="outlined">
      <template #label>
        <span>{{ item.label }}<strong class="text-red" v-if="item.isRequired"> *</strong></span>
      </template>
    </v-select>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
const props = defineProps({
  item: Object,
  modelValue: [String, Number, Boolean, Array, Object]
});

const emit = defineEmits(['update:modelValue']);

const updateValue = (value) => {
  emit('update:modelValue', value);
};
</script>