import {HttpService} from "./http";

class FilesService extends HttpService {
    public constructor() {
        super("/client-app/client/files");
    }

    public index(filters: string | object | null = null): Promise<any> {
        return this.http
            .get(`${this.route}/`, {
                params: filters
            })
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public getFile(id: number): Promise<any> {
        return this.http
            .get(this.route + "/get/" + id)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public viewedByClient(userId: number, fileResourceId: number): Promise<any> {
        return this.http
            .post(`${this.route}/${userId}/viewed/${fileResourceId}`)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public stats(): Promise<any> {
        return this.http
            .get(`${this.route}/stats`)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }
    
    public getRecentFiles(): Promise<any> {
        return this.http
            .get(`${this.route}/recent`)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }
}

export const filesService: FilesService = new FilesService();
