import {HttpService} from "./http";

class ClientService extends HttpService {
    public constructor() {
        super("/client-app/client");
    }


    public me(): any {
        return this.http
            .get(this.route + "/me")
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public profileDetails(): any {
        return this.http
            .get(`${this.route}/profile/details`)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public update(profileData: any): any {
        return this.http
            .post(this.route + "/", profileData)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public addresses(): any {
        return this.http
            .get(this.route + "/me/addresses")
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public createAddress(addressData: any): any {
        return this.http
            .post(this.route + "/addresses", addressData)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public updateEmergency(addressData: any, clientId: any): any {
        return this.http
            .put(`${this.route}/addresses/emergency/${clientId}`, addressData)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public updatePrimary(addressData: any, clientId: any): any {
        return this.http
            .put(`${this.route}/addresses/primary/${clientId}`, addressData)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public updateShipping(addressData: any, clientId: any): any {
        return this.http
            .put(`${this.route}/addresses/shipping/${clientId}`, addressData)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public updateGpDetails(gpDetails: any, clientId: any): any {
        return this.http
            .put(`${this.route}/medical/details/${clientId}`, gpDetails)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public updateInsuranceDetails(insuranceDetails: any, clientId: any): any {
        return this.http
            .put(`${this.route}/insurance/details/${clientId}`, insuranceDetails)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }
}

export const clientService: ClientService = new ClientService();
