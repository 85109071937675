<template>
  <svg fill="none" height="31" viewBox="0 0 32 31" width="32" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1717_254)">
      <path
          d="M5.88534 25.8327C5.17492 25.8327 4.56676 25.5797 4.06086 25.0738C3.55495 24.5679 3.302 23.9598 3.302 23.2493V7.74935C3.302 7.03893 3.55495 6.43077 4.06086 5.92487C4.56676 5.41897 5.17492 5.16602 5.88534 5.16602H13.6353L16.2187 7.74935H26.552C27.2624 7.74935 27.8706 8.0023 28.3765 8.5082C28.8824 9.01411 29.1353 9.62227 29.1353 10.3327H15.153L12.5697 7.74935H5.88534V23.2493L8.98534 12.916H31.0728L27.7468 23.9921C27.5746 24.5518 27.257 24.9985 26.7942 25.3322C26.3313 25.6658 25.8201 25.8327 25.2603 25.8327H5.88534ZM8.59784 23.2493H25.2603L27.5853 15.4993H10.9228L8.59784 23.2493Z"
          fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_1717_254">
        <rect fill="currentColor" height="31" transform="translate(0.71875)" width="31"/>
      </clipPath>
    </defs>
  </svg>
</template>
