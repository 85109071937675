<template>
  <v-dialog v-model="dialog" max-width="300" min-width="270" persistent>
    <v-card max-height="250" min-height="350">
      <v-card-title class="text-h6 d-flex justify-space-between align-center">
        Add Shipping Method
        <div @click="dialog = false">
          <Icon color="grey_light" icon="cross-icon"></Icon>
        </div>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text v-if="shippingMethods.length > 0">
        <v-radio-group
            v-for="(method, index) in shippingMethods" :key="`method-${index}`"
            v-model="selectedShippingMethod"
            class="w-100"
        >
          <v-radio :value="method.id" color="success">
            <template v-slot:label>
              <div class="d-flex align-center justify-space-between ga-6 flex-wrap">
                <p>{{ method.name }}</p>
                <p>{{ method.price_display }}</p>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-card-text>

      <v-card-text v-else class="d-flex align-center">
        <p class="text-center">No shipping methods available</p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="b-round-20 outline-btn cancel"
            elevation="0"
            height="32px"
            x-small
            @click="dialog = false"
        >
          Cancel
        </v-btn>

        <v-btn
            :disabled="selectedShippingMethod === null"
            class="text-white b-round-20 outline-btn white-btn"
            elevation="0"
            height="32px"
            x-small
            @click="onAddShippingMethod"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import {ref, defineProps, watch, defineEmits, onMounted, inject} from 'vue';
import {cartService} from "@/api/services/cart";
import Icon from "@/components/Icons/Icon.vue";

const emit = defineEmits(['update:modelValue', 'addShipping']);

const props = defineProps({
  modelValue: [String, Number],
  cartId: {
    type: [String, Number],
  },
  shippingId: {
    type: [String, Number],
  }
});

const snackBarPlugin = inject('snackbar');

const dialog = ref(props.modelValue);
const shippingMethods = ref([]);
const selectedShippingMethod = ref(null);

const onAddShippingMethod = () => {
  cartService.addItem(props.cartId, selectedShippingMethod.value, 1).then(() => {
    dialog.value = false;
    emit('addShipping');
    snackBarPlugin?.successSnackbar('Shipping method added successfully');
  }).catch((error: any) => {
    console.log(error);
  });
}

const getShippingMethod = () => {
  cartService.getShippingMethod(props.cartId, props.shippingId).then((resp) => {
    shippingMethods.value = resp.shipping_methods;
  }).catch((error: any) => {
    console.log(error);
  });
};

watch(dialog, (newValue) => {
  emit('update:modelValue', newValue);
});

onMounted(getShippingMethod);
</script>

<style lang="scss" scoped>
.cancel {
  color: #B0B3B7 !important;
  border: 1px solid #B0B3B7;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
  linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
}
</style>
