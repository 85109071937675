import {createApp, watch} from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import {loadFonts} from './plugins/webfontloader'
import {createPinia} from "pinia";
import {useSettingsStore} from '@/api/store/settings';
import {useUserStore} from '@/api/store/user';
import {ref} from 'vue'
import {snackBarPlugin} from "@/plugins/snakBarPlugin";

//Vuetify Extensions
import 'flag-icons/css/flag-icons.min.css';
import 'v-phone-input/dist/v-phone-input.css';
import {createVPhoneInput} from 'v-phone-input';

const vPhoneInput = createVPhoneInput({
    countryIconMode: 'svg'
});

loadFonts()

const pinia = createPinia();

createApp(App)
    .use(pinia)
    .use(router)
    .use(vuetify)
    .use(vPhoneInput)
    .use(snackBarPlugin)
    .mount('#app');

watch(
    pinia.state,
    (state) => {
        localStorage.setItem("lsUser", JSON.stringify(state.user));
    },
    {deep: true}
);

const userStore = useUserStore();
//settingsStore.loading = true;

if (router.currentRoute.value.name && router.currentRoute.value.name != 'preview') {
    if (!userStore.logged_in && router.currentRoute.value.name != 'login') {
        if (typeof window !== 'undefined' && window.location.pathname.startsWith('/login')) {
            router.push({
                name: 'process-login',
                params: {'token': window.location.pathname.replace('/login/', '')}
            });
        } else {
            router.push({name: 'login'});
        }
    }
}