<template>
  <v-sheet v-if="editting" class="px-2 py-1">
    <v-text-field v-model="value" :label="label" :type="type" variant="outlined"></v-text-field>
  </v-sheet>
  <v-sheet v-else :class="{'border-b': border}" class="d-flex justify-space-between px-5 py-3">
    <v-sheet>
      <p class="p-text-md" style="color: #AAA">{{ label }}</p>
    </v-sheet>
    <v-sheet class="text-right">
      <p class="p-text-md">{{ type === 'date' ? formattedDate(value, 'DD/MM/YYYY') : value }}</p>
    </v-sheet>
  </v-sheet>
</template>

<script lang="ts" setup>
import {computed} from 'vue'
import {defineProps, defineEmits} from 'vue'
import moment from "moment";

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  editting: {
    type: Boolean,
    required: false,
    default: false,
  },
  label: {
    type: String,
    required: true,
  },
  first: {
    type: Boolean,
    required: false,
    default: false,
  },
  last: {
    type: Boolean,
    required: false,
    default: false,
  },
  border: {
    type: Boolean,
    default: true,
  },
  type: {
    type: String,
    default: 'text',
  },
});

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

const formattedDate = (value: string | number, format = 'YYYY-MM-DD') => {
  return moment(value).format(format)
}
</script>