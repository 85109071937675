<template>
  <v-container class="px-0 pt-0 pb-12 position-relative" style="background-color:#F0F0F0; min-height:100vh">
    <v-row class="mt-0 mb-2 py-5 text-center" no-gutters style="background-color:#FFF">
      <v-col cols="12">
        <SearchFilter @update:modelValue="(val: string) => state.search = val"/>
      </v-col>
      <v-col class="d-flex px-3" cols="12">
        <v-col class="mt-1 position-relative left-filter-text" @click="()=>state.filterSelected = 'sort'">
          <ButtonFilterSelect
              v-model="sortTabSelectedValue"
              :is-tab-selected="isSortTabSelected"
              :items="sortValues"
              :multiple="false"
              :text="sortTabSelectedValue"
              icon="sort-icon"
              single-line
          />
        </v-col>
        <v-col class="mt-1 position-relative left-filter-text" @click="()=> state.filterSelected = 'filter'">
          <ButtonFilterSelect
              v-model="filterTabSelectedValue"
              :is-tab-selected="isFilterTabSelected"
              :items="filterValues"
              :multiple="false"
              :text="filterTabSelectedValue"
              single-line
          />
        </v-col>
      </v-col>
    </v-row>

    <section v-if="!settingsStore.loading">
      <OrderCard
          v-for="order in state.items"
          :key="order.id"
          :button-color="settingsStore.settings.buttonColor"
          :order="order"
          :orders-background-color="setAppColor"
      />
    </section>

    <v-card v-if="state.items.length == 0" class="mt-4 mx-4 mb-3" height="50vh">
      <v-row class="my-2 py-1" no-gutters>
        <v-col class="my-1 px-10 text-center" cols="12">
          <v-alert v-if="!settingsStore.loading" type="info" variant="tonal">No Orders Found</v-alert>
        </v-col>
      </v-row>
    </v-card>

    <div v-if="state.pagination.next_page_url" class="text-center">
      <v-btn
          :ripple="false"
          class="load-more text-capitalize font-size-12-600"
          variant="plain"
          @click="seeMore"
      >
        Load More
      </v-btn>
    </div>
  </v-container>
</template>

<script lang="ts" setup>
import {reactive, computed, shallowRef, watch} from 'vue';
import {ordersService} from '@/api/services/order';
import {useSettingsStore} from '@/api/store/settings';
import {filterValues, sortValues} from "@/views/OrdersComponents/Helpers";
import ButtonFilterSelect from "@/components/custom/ButtonFilterSelect/ButtonFilterSelect.vue";
import SearchFilter from "@/components/custom/SearchFilter.vue";
import debounce from 'lodash/debounce';
import OrderCard from "@/views/OrdersComponents/OrderDetails/OrderCard.vue";

const settingsStore = useSettingsStore();

const setAppColor = settingsStore.settings.buttonColor2

// Data
const state = reactive({
  items: [] as any[],
  pagination: {
    meta: {},
    links: [],
    next_page_url: null,
  },
  search: '' as string,
  filterSelected: 'filter' as string,
});

const sortTabSelectedValue = shallowRef({
  name: 'Sort',
  label: 'Sort'
})
const filterTabSelectedValue = shallowRef({
  name: 'Filter',
  label: 'Filter'
})
const page = shallowRef(1)

// Computed
const isFilterTabSelected = computed(() => {
  return state.filterSelected === 'filter'
});

const isSortTabSelected = computed(() => {
  return state.filterSelected === 'sort'
});

const filterQueryString = computed(() => {
  let filter = {
    sorting: '',
    search: state.search,
    page: page.value,
    payment_status: '',
  };

  let sortOrder = sortTabSelectedValue.value.name === 'Sort' ? 'desc' : sortTabSelectedValue.value.name;

  let filterValue = filterTabSelectedValue.value.name === 'Filter' ? 'title' : filterTabSelectedValue.value.name;

  if (filterTabSelectedValue.value.name === 'Filter' && sortTabSelectedValue.value.name === 'Sort') {
    filter.sorting = 'id,desc';
  } else {
    if (filterValue === 'paid' || filterValue === 'not_paid') {
      filter.payment_status = filterValue;
      filter.sorting = `id,${sortOrder}`;
    } else {
      filter.sorting = `${filterValue},${sortOrder}`;
    }
  }

  return filter;
});

const getOrders = () => {
  settingsStore.loading = true;
  ordersService.index(filterQueryString.value).then((response) => {
    state.items.push(...response.data)
    state.pagination.meta = response.meta;
    state.pagination.links = response.links;
    state.pagination.next_page_url = response.next_page_url;
    settingsStore.loading = false;
  });
}

// Debounced to fetch getMedicalAlerts
const debouncedGetOrders = debounce(getOrders, 300);

const resetState = () => {
  page.value = 1;
  state.items = [];
}

const seeMore = () => {
  if (state.pagination.next_page_url) {
    page.value++;
    getOrders();
  }
}

watch(() => [filterTabSelectedValue.value, sortTabSelectedValue.value, state.search], () => {
  resetState();
  debouncedGetOrders();
});

getOrders();
</script>

<style lang="scss" scoped>
:deep(.add-new-circle-icon) {
  margin-right: 3px;
}

.left-filter-text {
  :deep(.v-btn) {
    justify-content: left;
  }
}

.load-more {
  padding: 10px;
  text-align: center;
  margin: 10px auto 30px auto;
  width: 200px;
  color: v-bind(setAppColor);
  text-decoration: underline;
  text-underline-offset: 2px;
  opacity: 1;
}
</style>