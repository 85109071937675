import { getToken } from "./auth"
import axios from "axios";
import type { AxiosError, AxiosInstance, AxiosResponse } from "axios";

export function isAxiosError(value: any): value is AxiosError {
  return typeof value?.response === "object";
}

export abstract class HttpService {
  protected readonly http: AxiosInstance;
  protected route: string;

  protected constructor(protected readonly path: string) {

    this.route = process.env.VUE_APP_API_URL + path;

    this.http = axios.create();

    this.http.interceptors.request.use((request: any) => {
      const token = getToken();
      if (request.headers && token) {
        request.headers.Authorization = `Bearer ${token}`;
      } 
      else {
        //return (window.location.href = "/auth/login");
      }

      return request;
    });

  }

  protected createParams(record: Record<string, any>): URLSearchParams {
    const params: URLSearchParams = new URLSearchParams();

    for (const key in record) {
      if (Object.prototype.hasOwnProperty.call(record, key)) {
        const value: any = record[key];

        if (value !== null && value !== undefined) {
          params.append(key, value);
        } else {
          console.debug(
            `Param key '${key}' was null or undefined and will be ignored`
          );
        }
      }
    }

    return params;
  }

  protected handleResponse<T>(response: AxiosResponse<T>): T {
    console.log(response);

    return response.data;
  }

  protected handleError(error: unknown): never {
    console.log("handleError: ", error);

    if (isAxiosError(error)) {
      if (error.response) {
        if (error.response.status === 401) {
          console.log('Unauthorized, redirecting to login');
          window.location.href = '/#/login';
        }
      }
    }
    throw new Error(error as any);
  }
}
