<template>
    <div v-if="item.type === 'section'" class="pa-2 bg-grey-lighten-3">
      <div class="d-flex justify-space-between">
        <div><h2>{{ item.label }}</h2></div>
        <div>
            
        </div>
      </div>
    </div>
    <div v-else>
        <!-- Dynamically render the appropriate field component -->
        <component :is="fieldComponent" :item="item" 
        :modelValue="modelValue"
        @update:modelValue="updateValue"></component>
    </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';

import DateField from './fields/DateField.vue';
import AutoText from './fields/AutoText.vue';
import CheckboxField from './fields/CheckboxField.vue';
import DividerField from './fields/DividerField.vue';
import ProductsTable from './fields/ProductsTable.vue';
import SelectField from './fields/SelectField.vue';
import SwitchField from './fields/SwitchField.vue';
import TextArea from './fields/TextArea.vue';
import TextField from './fields/TextField.vue';
import RadioField from './fields/RadioField.vue';
import ScaleField from './fields/ScaleField.vue';
import ImageField from './fields/ImageField.vue';
import ListField from './fields/ListField.vue';

const props = defineProps({
    item: Object,
    modelValue: [String, Number, Boolean, Array, Object]
});

const emit = defineEmits(['update:modelValue']);

const fieldComponents = {
    date: DateField,
    text: AutoText,
    checkbox: CheckboxField,
    divider: DividerField,
    image: ImageField,
    list: ListField,
    products: ProductsTable,
    radio: RadioField,
    select: SelectField,
    scale: ScaleField,
    switch: SwitchField,
    textarea: TextArea,
    textbox: TextField
};

const fieldComponent = computed(() => fieldComponents[props.item.type] || 'div');

const updateValue = (value) => {
    emit('update:modelValue', value);
};
</script>