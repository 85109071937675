<template>
  <v-container class="px-0 pb-5 document-form" style="background-color:#F0F0F0;min-height:500px;">
    <v-row class="d-flex ga-3 pa-2" no-gutters>
      <v-row no-gutters>
        <v-btn
            :class="{ 'secondary-button': state.tab === 'incomplete'}"
            class="b-round-20 transparent"
            @click="toggleView('incomplete')"
        >
          Incomplete
        </v-btn>
      </v-row>
      <v-row no-gutters>
        <v-btn
            :class="{ 'secondary-button': state.tab === 'completed'}"
            class="b-round-20 transparent"
            @click="toggleView('completed')"
        >
          Completed
        </v-btn>
      </v-row>
    </v-row>

    <v-card v-for="document in state.items" :key="document.id" class="mt-2 mx-2 mb-2 pa-4 border-rounded">
      <v-row class="my-0" no-gutters>
        <v-col cols="12">
          <v-row>
            <v-col class="px-3">
              <p class="p-text mt-1 name">{{ document.file_resource.title }}</p>

              <p class="p-text mt-1">
                {{ moment(document.created_at).format('DD MMM YYYY') }}
                <span
                    v-if="moment(document.created_at).isBefore(currentDate) && state.tab === 'incomplete'"
                    class="small"
                >
                  (Overdue)
                </span>
              </p>
            </v-col>
            <v-col class="text-end px-3">
              <v-btn
                  :class="[{ 'view-button': state.tab === 'completed', 'complete-button': state.tab === 'incomplete' }, 'b-round-20']"
                  :color="settingsStore.settings.buttonColor"
                  @click="router.push({ name: 'document-viewer', params: { id: document.file_resource.file.id } })"
              >
                {{ state.tab === 'completed' ? 'View' : 'Complete Now' }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-if="state.items.length == 0" class="mt-2 mx-2 mb-2 pa-4">
      <v-row class="my-0" no-gutters>
        <v-col cols="12">
          <v-row class="py-2">
            <v-col class="pt-3 px-3">
              <p class="p-text mt-1">No forms available.</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <div v-if="state.pagination.next_page_url" class="text-center">
      <v-btn
          :ripple="false"
          class="load-more text-capitalize font-size-12-600"
          variant="plain"
          @click="seeMore"
      >
        Load More
      </v-btn>
    </div>
  </v-container>
</template>

<script lang="ts" setup>
import {reactive, shallowRef, watch} from 'vue';
import {useSettingsStore} from '@/api/store/settings';
import {useRouter} from 'vue-router';
import moment from 'moment';
import {filesService} from '@/api/services/file';

const settingsStore = useSettingsStore();
const router = useRouter();

const currentDate = moment();

const state = reactive({
  items: [] as any[],
  pagination: {
    meta: {},
    links: [],
    next_page_url: null,
  },
  filesLoaded: false,
  tab: 'incomplete'
});

const page = shallowRef(1)

const getFiles = (reset = false) => {
  settingsStore.loading = true;

  // Clear items if reset flag is true (for initial fetch or toggle)
  if (reset) {
    state.items = [];
    page.value = 1;
  }

  filesService.index({
    type: 'form',
    page: page.value,
    tab: state.tab
  }).then((response) => {
    state.items.push(...response.data);
    state.pagination.meta = response.meta;
    state.pagination.links = response.links;
    state.pagination.next_page_url = response.links.next;

    settingsStore.loading = false;
  }).catch((error) => {
    console.log(error);
  }).finally(() => {
    state.filesLoaded = true;
    settingsStore.loading = false;
  });
}

const toggleView = (tab) => {
  state.tab = tab;
  getFiles(true);
}

const seeMore = () => {
  if (state.pagination.next_page_url) {
    page.value++;
    getFiles();
  }
}

getFiles(true);
</script>