<template>
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.354 21.1471H6.454L17.529 9.61068L16.429 8.46484L5.354 20.0013V21.1471ZM20.704 8.49089L17.504 5.15755L18.554 4.0638C18.8373 3.76866 19.1873 3.62109 19.604 3.62109C20.0207 3.62109 20.3707 3.76866 20.654 4.0638L21.754 5.20964C22.0373 5.50477 22.179 5.86936 22.179 6.30339C22.179 6.73741 22.0373 7.102 21.754 7.39714L20.704 8.49089ZM19.654 9.58464L7.054 22.7096H3.854V19.3763L16.454 6.2513L19.654 9.58464ZM16.979 9.03776L16.429 8.46484L17.529 9.61068L16.979 9.03776Z"
      fill="#1EBCD5"
    />
  </svg>
</template>
