<template>
    <v-container style="background-color: #F5F5F5;">
        <v-row class="mt-5">
            <v-col class="text-center"><v-btn label="Log out" @click="logout" class="b-round-20 text-white"
                    :color="settingsStore.settings.primaryColor">Log Out</v-btn></v-col>
        </v-row>
        <v-divider class="mt-5 mb-5" />
        <v-row>
            <v-col cols="12" class="d-flex justify-center">
                <p class="p-text-lg">Associated Accounts</p>
            </v-col>
        </v-row>
        <v-row v-if="state.client">
            <v-col cols="12" class="d-flex justify-center">
                <p class="p-text-md">Select an account to switch to</p>
            </v-col>
            <v-row v-if="state.client.associated_clients">
                <v-col cols="12" class="mt-10">
                    <v-row>
                        <v-col v-for="(account, index) in state.client.associated_clients" :key="index" cols="12" class="d-flex justify-center">
                            <v-sheet :color="settingsStore.settings.buttonColor" class="pa-3 mx-4 b-round-20 text-center"
                                elevation="3" height="110" width="120" @click="switchAccount(account)">
                                <v-icon class="my-2 text-white" icon="fa fa-user" size="40"></v-icon>
                                <p class="p-text-md text-white">{{ account.name }}</p>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-row>
    </v-container>
</template>

<script lang="ts" setup>
import { setToken, setUser } from "@/api/services/auth"
import { useRouter } from "vue-router"
import { useSettingsStore } from "@/api/store/settings"
import { reactive } from "vue";
import { useUserStore } from "@/api/store/user";
import AddressManager from "./ClientComponents/AddressManager.vue";
import FormField from "@/components/Forms/FormField.vue";
import { clientService } from "@/api/services/client";

const settingsStore = useSettingsStore();
const userStore = useUserStore();

const router = useRouter();

const logout = () => {
    userStore.logout();
    router.push({ name: "login" });
}

const state = reactive({
    client: null as any,
});

const fetchClient = async () => {
    // Fetch client data
    clientService.me().then((response: any) => {
        state.client = response;
    })
};

fetchClient();

const switchAccount = (account: any) => {
    setToken(account.token);
    setUser(account);
    router.push({ name: "client-details" });
}
</script>