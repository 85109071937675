<template>
  <v-btn @click="emit('left')" icon color="transparent" elevation="0" class="chevron-size">
    <v-icon>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.46286 9.21484L11.7212 12.4732L10.7295 13.4648L6.47952 9.21484L10.7295 4.96484L11.7212 5.95651L8.46286 9.21484Z"
            fill="#7F898E"/>
      </svg>
    </v-icon>
  </v-btn>
  <v-btn @click="emit('right')" icon color="transparent" elevation="0" class="chevron-size">
    <v-icon>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.3129 9.21484L6.05457 5.95651L7.04623 4.96484L11.2962 9.21484L7.04623 13.4648L6.05457 12.4732L9.3129 9.21484Z"
            fill="#7F898E"/>
      </svg>
    </v-icon>
  </v-btn>
</template>

<script setup lang="ts">
import {defineEmits} from 'vue';

const emit = defineEmits(['left', 'right']);
</script>

<style scoped lang="scss">
.chevron-size {
  width: 26px;
  height: 26px;
}
</style>