<template>
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.42407 13.0234H10.2991V11.1484H12.1741V9.27344H10.2991V7.39844H8.42407V9.27344H6.54907V11.1484H8.42407V13.0234ZM3.36157 16.2109V7.21094L9.36157 2.71094L15.3616 7.21094V16.2109H3.36157ZM4.48657 15.0859H14.2366V7.69844L9.36157 4.15469L4.48657 7.69844V15.0859Z"
      fill="currentColor"
    />
  </svg>
</template>
