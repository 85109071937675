import moment from "moment/moment";

/**
 * Convert date to calendar format
 * @param date
 */
const convertDateCalendar = (date: any) => {
    return moment(date).calendar(null, {
        sameDay: '[Today], hh:mm A',
        nextDay: '[Tomorrow], hh:mm A',
        nextWeek: 'dddd, hh:mm A',
        sameElse: 'dddd, Do MMM YYYY, hh:mm A'
    })
}

export {convertDateCalendar}