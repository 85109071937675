<template>
  <div class="form-input">
    <v-switch :name="item.name" :inline="true" color="primary" 
      :model-value="booleanValue"
      @update:model-value="updateValue">
      <template #label>
        <span>{{ item.label }}<strong class="text-red" v-if="item.isRequired"> *</strong></span>
      </template>
    </v-switch>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue';

const props = defineProps({
  item: Object,
  disabled: Boolean,
  modelValue: [String, Number, Boolean, Array, Object]
});

const emit = defineEmits(['update:modelValue']);

const booleanValue = ref(props.modelValue === true || props.modelValue === 'true');

watch(() => props.modelValue, (newValue) => {
  booleanValue.value = newValue === true || newValue === 'true';
});

const updateValue = (value) => {
  emit('update:modelValue', value);
};
</script>