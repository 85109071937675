import {PropType} from 'vue';

const buttonProps = {
    customClass: {
        type: [String, Array] as PropType<string | []>,
        default: ''
    },
    customIconClass: {
        type: [String, Array] as PropType<string | []>,
        default: ''
    },
    variant: {
        type: String as PropType<string>,
        default: 'outlined'
    },
    width: {
        type: String as PropType<string>,
        default: '90%'
    },
    colorIcon: {
        type: String as PropType<string>,
    },
    icon: {
        type: String as PropType<string>,
    },
    text: {
        type: String as PropType<string>,
        default: 'Tab'
    },
    buttonColor: {
        type: String as PropType<string>,
    },
    count: {
        type: Number as PropType<number>,
    },
    activeFilter: {
        type: Boolean as PropType<boolean>,
        default: false
    },
    disabled: {
        type: Boolean as PropType<boolean>,
        default: false
    },
    singleLine: {
        type: Boolean as PropType<boolean>,
        default: false
    }
}

export default buttonProps;