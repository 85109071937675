<template>
  <v-sheet class="px-5 py-3" style="background-color: #F5F5F5;">
    <v-row class="px-3 mb-3">
      <v-col>
        <p class="p-text-lg mt-2">Shipping Address</p>
      </v-col>
      <v-col class="text-right">
        <v-btn v-if="!edit" color="#F5F5F5" variant="flat" @click="emit('edit')">
          <v-icon :color="color" icon="fa fa-pencil"></v-icon>
        </v-btn>
        <v-btn v-else color="#F5F5F5" variant="flat" @click="emit('save', state)">
          <v-icon :color="buttonColor" icon="fas fa-save"></v-icon>
        </v-btn>
        <!--Close-->
        <v-btn v-if="edit" color="#F5F5F5" variant="flat" @click="emit('close')">
          <v-icon :color="color" icon="fas fa-close"></v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-sheet class="px-2 pt-3 pb-2 b-round-20">
      <form-field v-model="state.address_1" :editting="edit" label="Address 1" variant="plain"/>
      <form-field v-model="state.address_2" :editting="edit" label="Address 2" variant="outlined"/>
      <form-field v-model="state.address_3" :editting="edit" label="Address 3" variant="outlined"/>
      <form-field v-model="state.city" :editting="edit" label="City" variant="outlined"/>
      <form-field v-model="state.county" :editting="edit" label="County" variant="outlined"/>
      <form-field v-model="state.country" :editting="edit" label="Country" variant="outlined"/>
      <form-field v-model="state.post_code" :border="false" :editting="edit" label="Post Code" variant="outlined"/>
    </v-sheet>
  </v-sheet>
</template>

<script lang="ts" setup>
import FormField from "@/components/Forms/FormField.vue";
import {defineProps, watch, reactive, defineEmits} from 'vue'

const defaultData = {
  address_1: '-',
  address_2: '-',
  address_3: '-',
  city: '-',
  county: '-',
  country: '-',
  post_code: '-',
};

const props = defineProps({
  data: {
    type: Object,
  },
  color: {
    type: String,
  },
  buttonColor: {
    type: String,
  },
  edit: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['save', 'edit', 'close'])

const state = reactive({
  ...props.data,
});

// Watch for changes in props.data to update state
watch(() => props.data, (newData) => {
  Object.keys(defaultData).forEach(key => {
    state[key] = newData[key] !== undefined && newData[key] !== null ? newData[key] : defaultData[key];
  });
}, {immediate: true});
</script>