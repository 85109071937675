<template>
    <div>
        <v-container>
            <div v-for="item in items" :key="item.id">
                <appointment-item :item="item" class="my-2"/>
                <hr/>
            </div>
        </v-container>
    </div>
</template>

<script lang="ts" setup>
import PageHeader from '@/components/Layout/PageHeader.vue';
import AppointmentItem from './AppointmentItem.vue';

const items = [
    {
        id: 1,
        time: '2023-02-23 10:00:00',
        name: 'Initial Consultation',
        status: 'confirmed',
        payment_status: 'paid'
    },
    {
        id: 2,
        time: '2023-03-02 10:00:00',
        name: 'Follow-Up Consultation',
        status: 'confirmed',
        payment_status: 'overdue'
    },
    {
        id: 3,
        time: '2023-03-09 10:00:00',
        name: 'Follow-Up Consultation',
        status: 'confirmed',
        payment_status: 'unpaid'
    }
]
</script>