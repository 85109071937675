<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color="#627177"
    class=""
    data-v-116486ce=""
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.81802 11.1822C4.62276 10.9869 4.62276 10.6703 4.81802 10.4751L7.29289 8.00019L4.81802 5.52532C4.62276 5.33006 4.62276 5.01347 4.81802 4.81821C5.01328 4.62295 5.32986 4.62295 5.52513 4.81821L8 7.29308L10.4749 4.81821C10.6701 4.62295 10.9867 4.62295 11.182 4.81821C11.3772 5.01347 11.3772 5.33006 11.182 5.52532L8.70711 8.00019L11.182 10.4751C11.3772 10.6703 11.3772 10.9869 11.182 11.1822C10.9867 11.3774 10.6701 11.3774 10.4749 11.1822L8 8.7073L5.52513 11.1822C5.32986 11.3774 5.01328 11.3774 4.81802 11.1822Z"
      fill="currentColor"
    ></path>
    <mask
      id="mask0_15349_10681"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="4"
      y="4"
      width="8"
      height="8"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.81802 11.1822C4.62276 10.9869 4.62276 10.6703 4.81802 10.4751L7.29289 8.00019L4.81802 5.52532C4.62276 5.33006 4.62276 5.01347 4.81802 4.81821C5.01328 4.62295 5.32986 4.62295 5.52513 4.81821L8 7.29308L10.4749 4.81821C10.6701 4.62295 10.9867 4.62295 11.182 4.81821C11.3772 5.01347 11.3772 5.33006 11.182 5.52532L8.70711 8.00019L11.182 10.4751C11.3772 10.6703 11.3772 10.9869 11.182 11.1822C10.9867 11.3774 10.6701 11.3774 10.4749 11.1822L8 8.7073L5.52513 11.1822C5.32986 11.3774 5.01328 11.3774 4.81802 11.1822Z"
        fill="currentColor"
      ></path>
    </mask>
    <g mask="url(#mask0_15349_10681)"></g>
    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#D2DADD"></rect>
  </svg>
</template>
