<template>
  <v-app-bar
      app
      color="white"
      density="compact" fixed
      style="box-shadow: 0 2px 8px -8px gray;"
  >
    <template v-slot:prepend>
      <v-btn icon>
        <v-icon class="primary-color" icon="fa-bars" size="20"/>
      </v-btn>
      <v-btn icon>
        <v-icon class="primary-color" icon="fa-search" size="20"/>
      </v-btn>
    </template>

    <v-app-bar-title class="text-center">
      <v-btn icon @click="router.push({ name: 'home' })">
        <Icon color="primaryColor" icon="home-icon"/>
      </v-btn>
    </v-app-bar-title>

    <template v-slot:append>
      <v-btn icon @click="router.push({ name: 'notifications' })">
        <Icon color="primaryColor" icon="bell-icon"/>
      </v-btn>

      <v-btn icon @click="router.push({ name: 'client' })">
        <Icon color="primaryColor" icon="person-icon"/>
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script lang="ts" setup>
import {useRouter} from 'vue-router';
import Icon from "@/components/Icons/Icon.vue";

const router = useRouter();
</script>