<template>
  <v-container class="px-1">
    <v-row class="my-0 py-1" no-gutters>
      <v-col class="my-1" cols="12">
        <SearchFilter :model-value="state.search" @update:modelValue="(val: string) => state.search = val"/>
      </v-col>
      <v-col class="my-0 px-3" cols="6">
        <!--prepend-inner-icon="fa:fas fa-search"-->
        <ButtonFilterSelect
            v-model="sortTabSelectedValue"
            :is-tab-selected="filterSelected === 'sort'"
            :items="sortValues"
            :multiple="false"
            :text="sortTabSelectedValue"
            icon="sort-icon"
            single-line
            @click="()=> filterSelected = 'sort'"
        />
      </v-col>
      <v-col class="my-0 px-3" cols="6">
        <ButtonBase
            :custom-class="['transparent text-black', {'secondary-button text-white': filterSelected === 'clear'}]"
            text="Clear Filters"
            width="100%"
            @click="resetFilters"
        />
      </v-col>
    </v-row>

    <v-row class="px-2 mt-1" no-gutters>
      <v-col v-for="item in state.items" :key="item.id" class="pa-1" cols="6">
        <v-card v-if="item.item_type == 'product'" class="mx-auto pt-2 px-2" height="250"
                @click="router.push({ name: 'shop-product', params: { 
                  id: item.id, 
                  type: item.type === 'clinic_product'? 'clinic': 'natural-dispensary' 
                }
               })"
        >
          <v-img :src="getFeaturedImage(item)" class="text-white text-right align-end" cover height="120">
            <v-btn size="40" variant="flat" @click.stop="addProductToCart(item)">
              <v-icon :color="settingsStore.settings.buttonColor" icon="fa fa-cart-plus" size="20"></v-icon>
            </v-btn>
          </v-img>

          <v-card-text>
            <div class="p-text font-weight-bold ms-1 mb-2">
              {{ item.name }}
            </div>
            <div class="p-text font-weight-light ms-1 mb-2">
              {{ item.slot3 }}
            </div>
            <div class="p-text font-weight-bold ms-1 mb-2">
              £{{ item.price.toFixed(2) }}
            </div>
          </v-card-text>
        </v-card>
        <v-card v-else-if="item.item_type == 'service'" class="mx-auto pt-2 px-2" height="250"
                @click="router.push({ name: 'shop-service', params: { id: item.id } })"
        >
          <v-sheet v-if="item.image?.file?.file_path" class="image-container m-0">
            <div :style="{backgroundImage: `url(${item.image?.file?.file_path})`}" class="image-container__image"></div>
          </v-sheet>

          <v-sheet v-else :class="{'secondary-background-button-color': !item?.supplier }" class="icon-container">
            <Icon icon="service-icon"/>
          </v-sheet>

          <v-card-text>
            <div class="p-text font-weight-bold ms-1 mb-2">
              {{ item.name }}
            </div>
            <div class="p-text font-weight-light ms-1 mb-2">
              {{ item.booking_options ? item.booking_options.duration : '0' }} mins
            </div>
            <div class="p-text font-weight-bold ms-1 mb-2">
              {{ item.price_display }}
            </div>
          </v-card-text>
        </v-card>

        <v-card v-else-if="item.type == 'clinic_package'" class="mx-auto pt-2 px-2" height="250"
                @click="router.push({ name: 'shop-package', params: { id: item.id } })"
        >
          <v-sheet v-if="item.image?.file?.file_path" class="image-container m-0">
            <div :style="{backgroundImage: `url(${item.image?.file?.file_path})`}" class="image-container__image"></div>
          </v-sheet>

          <v-sheet v-else :class="{'secondary-background-button-color': !item?.supplier }" class="icon-container">
            <Icon icon="package-icon"/>
          </v-sheet>

          <v-card-text>
            <div class="p-text font-weight-bold ms-1 mb-2">
              {{ item.name }}
            </div>
            <div class="p-text font-weight-light ms-1 mb-2">
              {{ totalServicesFromPackage(item) }}
            </div>
            <div class="p-text font-weight-bold ms-1 mb-2">
              {{ item.price_display }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts" setup>
import {computed, reactive, shallowRef, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useCartStore} from '@/api/store/cart';
import {productService} from '@/api/services/product';
import {servicesService} from '@/api/services/service';
import {packagesService} from '@/api/services/package';
import {useSettingsStore} from '@/api/store/settings';
import ButtonBase from "@/components/custom/ButtonBase.vue";
import ButtonFilterSelect from "@/components/custom/ButtonFilterSelect/ButtonFilterSelect.vue";
import debounce from 'lodash/debounce';
import SearchFilter from "@/components/custom/SearchFilter.vue";
import Icon from "@/components/Icons/Icon.vue";

const settingsStore = useSettingsStore();
const cartStore = useCartStore();

const router = useRouter();
const route = useRoute();

const category = route.params.category;

const state = reactive({
  items: [] as any[],
  search: "" as string,
  sort_asc: true as boolean,
  page: 1 as number,
});

const sortValues = [
  {
    name: 'asc',
    label: 'Asc'
  },
  {
    name: 'desc',
    label: 'Desc'
  }
]

const filterSelected = shallowRef('clear');

const sortTabSelectedValue = shallowRef({
  name: 'Sort',
  label: 'Sort'
})

const getFeaturedImage = (item: any) => {
  if (item.images.length > 0) {
    return item.images[0].full;
  }
  return '/img/placeholder.png';
}

const resetFilters = () => {
  state.search = "";
  state.sort_asc = true;
  filterSelected.value = 'clear';
  debouncedGetOrders();
}

// Computed
const filterQueryString = computed(() => {
  let filter = {
    sorting: '',
    search: state.search,
    searching: 'name'
  };

  let sortOrder = sortTabSelectedValue.value.name === 'Sort' ? 'desc' : sortTabSelectedValue.value.name;

  filter.sorting = `id,${sortOrder}`;

  return filter;
});

const loadItems = async () => {
  state.items = [];

  if (!category || category === 'products') {
    settingsStore.loading = true;
    await productService.index(null, filterQueryString.value).then((response) => {
      const data = response.data;
      data.forEach(function (element: any) {
        if (!element.visible_in_app) return;
        element.item_type = 'product';
        state.items.push(element);
      });
    });
    settingsStore.loading = false;
    return;
  }

  if (!category || category === 'services') {
    settingsStore.loading = true;
    await servicesService.index(filterQueryString.value).then((response) => {
      const data = response.data;
      data.forEach(function (element: any) {

        if (!element.client_visibility) return;
        element.item_type = 'service';
        state.items.push(element);
      });
    });
    settingsStore.loading = false;
    return;
  }

  if (!category || category === 'packages') {
    settingsStore.loading = true;
    await packagesService.index(filterQueryString.value).then((response) => {
      state.items = response.data.filter((element: any) => element.client_visibility);
    });
    settingsStore.loading = false;
    return;
  }
}

const addProductToCart = (item: any) => {
  settingsStore.loading = true;
  cartStore.addItem(0, item.id, 1).then((res: any) => {
    router.push({name: 'shopping-cart'});
  }).catch((error: any) => {
    settingsStore.error = true;
    settingsStore.error_message = "This item may be out of stock or no longer available from the supplier."
  }).finally(() => {
    settingsStore.loading = false;
  });
}

// Get total services for each package
const totalServicesFromPackage = (item: any) => {
  const servicesCount = item?.services.length ?? 0;

  return servicesCount > 1 ? `${servicesCount} services` : servicesCount === 0 ? `No services` : `${servicesCount} service`;
}

// Debounce the loadItems function
const debouncedGetOrders = debounce(loadItems, 300);

watch(() => [sortTabSelectedValue.value, state.search, filterSelected.value], () => {
  debouncedGetOrders();
});

loadItems();
</script>

<style lang="scss" scoped>
:deep(.v-input) {
  margin: 0 !important;
}

.image-container {
  height: 122.57px;

  &__image {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.icon-container {
  border-radius: 5px;
  height: 122.57px;
  background: #3A5485;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>