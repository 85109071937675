import { HttpService } from "./http";

class SettingsService extends HttpService {
  public constructor() {
    super("/client-app/client/settings");
  }

  public fetch(key: string): Promise<any> {
    return this.http
      .get(this.route + "/" + key)
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }
}

export const settingsService: SettingsService = new SettingsService();
