import {HttpService} from "./http";

class ActivityService extends HttpService {
    public constructor() {
        super("/client-app/client/activities");
    }

    public index(filters: string | object | null = null): Promise<any> {
        return this.http
            .get(`${this.route}/`, {
                params: filters
            })
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }
}

export const activityService: ActivityService = new ActivityService();
