export const loadPanelData = (item: any) => {
    return [
        {
            title: 'Description',
            content: decodeHtml(item.description),
        },
        {
            title: 'Ingredients',
            content: '',
        },
        {
            title: 'Nutrient Information',
            content: '',
        },
        {
            title: 'Dietary Information',
            content: '',
        },
        {
            title: 'Allergens',
            content: '',
        },
        {
            title: 'Format',
            content: '',
        },
        {
            title: 'Directions & Storage',
            content: '',
        },
        {
            title: 'Warnings',
            content: '',
        },
        {
            title: 'Certification',
            content: '',
        },

    ];
}

const decodeHtml = (html: string) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}