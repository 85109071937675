<template>
    <v-row>
        <v-col class="text-center" cols="6">
            <v-row>
                <v-col cols="2" class="mr-0 pr-0">
                    <span class="p-date">{{  appointmentTime.date() }}</span>
                </v-col>
                <v-col cols="8" class="mr-0 pr-0">
                    <span class="p-text">{{  appointmentTime.format('dddd') }}</span><br/>
                    <span class="p-text">{{  appointmentTime.format('MMM') }} {{  appointmentTime.format('YYYY') }}</span>
                </v-col>
                <v-col cols="2" class="mx-0 pl-0">
                    <span class="p-time">10:00 10:30</span>
                </v-col>
            </v-row>
        </v-col>
        <v-col class="text-center" cols="4">
            <span class="p-text">{{ props.item.name }}</span>
        </v-col>
        <v-col class="text-center pr-5" cols="2">
            <v-icon icon="fa-check-circle fa-2x pt-1" color="green" v-if="props.item.payment_status =='paid'"></v-icon>
            <v-icon icon="fa-question-circle fa-2x pt-1" color="yellow" v-if="props.item.payment_status =='unpaid'"></v-icon>
            <v-icon icon="fa-warning fa-2x pt-1" color="red" v-if="props.item.payment_status =='overdue'"></v-icon>
        </v-col>
    </v-row>
</template>

<script lang="ts" setup>
import { defineProps } from 'vue';
import moment from 'moment';
const props = defineProps(['item']);
const appointmentTime = moment(props.item.time);
</script>

<style scoped>
.p-date {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 54px;
}

.p-time {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;
}
</style>