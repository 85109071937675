import {HttpService} from "./http";

class SurveysService extends HttpService {
    public constructor() {
        super("/client-app/client/surveys");
    }

    public index(): Promise<any> {
        return this.http
            .get(this.route + "/")
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public show(id: number): Promise<any> {
        return this.http
            .get(this.route + "/" + id)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public getQuestionsResponse(clientSurveyId: number, filters: string | object | null = ''): Promise<any> {
        return this.http
            .get(`${this.route}/questions/${clientSurveyId}`, {
                params: filters
            })
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }


    public store(survey: any, clientSurveyId: number): Promise<any> {
        return this.http
            .post(this.route + "/", {
                responses: [...survey.questions],
                client_survey_id: clientSurveyId,
                client_id: survey.client_id
            })
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }
}

export const surveysService: SurveysService = new SurveysService();
