<template>
  <div class="form-input w-100" :class="item.position ? 'text-' + props.item.position : ''">
    <img :src="item.content" alt="Image" :height="item.height"/>
  </div>
</template>

<script setup>
import { reactive, watch, defineProps } from 'vue';

const props = defineProps({
  item: Object,
});
</script>