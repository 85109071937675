<template>
  <div class="position-relative">
    <ButtonBase
        :icon="icon"
        :color-icon="isTabSelected ? 'white' : null"
        :custom-class="[{'text-white': isTabSelected}]"
        :variant="isTabSelected ? 'elevated':'outlined'"
        custom-icon-class="mr-2 text"
        :text="text[itemTitle]"
        width="100%"
        :count="setSelect.length"
        :active-filter="isTabSelected"
        :single-line="singleLine"
    />

    <v-select
        class="select-dropdown"
        variant="underlined"
        :item-title="itemTitle"
        :item-value="itemValue"
        v-model="setSelect"
        :items="items"
        :multiple="multiple"
        :single-line="singleLine"
        return-object
        menu-icon="''"
    >
      <template v-slot:selection>
        <slot name="selection"></slot>
      </template>
    </v-select>
  </div>
</template>

<script setup lang="ts">
import {defineProps, defineEmits, ref, watch} from "vue";
import ButtonBase from "@/components/custom/ButtonBase.vue";

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {
    type: [Object, String],
  },
  text: {
    type: String,
    default: 'Filter'
  },
  items: {
    type: Array,
    required: true,
  },
  isTabSelected: {
    type: Boolean,
    required: true,
  },
  itemTitle: {
    type: String,
    default: 'label',
  },
  itemValue: {
    type: String,
    default: 'name',
  },
  multiple: {
    type: Boolean,
    default: true
  },
  singleLine: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    default: 'filter-icon'
  },
})

const setSelect = ref(props.modelValue);

watch(() => props.modelValue, (value) => {
  setSelect.value = value;
});

watch(setSelect, (value) => {
  return emit('update:modelValue', value);
});
</script>

<style scoped lang="scss">
.select-dropdown {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

:deep(.v-field__outline) {
  display: none;
}

:deep(.v-field.v-field--variant-underlined .v-field__append-inner) {
  margin: auto;
  padding: 0;
}

:deep(.v-input__details) {
  display: none;
}

:deep(.v-field__input) {
  padding: 0;
  justify-content: end;
}

:deep(.v-field__field) {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #000000;
}

:deep(.v-field__append-inner > .v-icon) {
  opacity: 1;
}

:deep(.v-input__control) {
  height: 88%;
}
</style>