<template>
  <v-phone-input
      :disabled="disabled"
      :label="label"
      :loading="loading"
      :modelValue="modelValue"
      country-variant="outlined"
      defaultCountry="GB"
      variant="outlined"
      @error="error"
      @update:modelValue="updateValue"
  />
</template>

<script setup>
import {defineProps, defineEmits} from 'vue';

const props = defineProps({
  modelValue: String,
  loading: Boolean,
  label: String,
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

function updateValue($event) {
  emit('update:modelValue', $event);
}

const error = (error) => {
  console.log(error);
};
</script>