<template>
  <v-card class="font-size-12-400 card-container">
    <div class="d-flex justify-space-between align-center">
      <div class="d-flex align-center ga-2 py-2">
        <p>
          <Icon color="primaryColor" icon="bell-icon"/>
        </p>
        <p>{{ notification.subject }}</p>
      </div>
      <div class="d-flex align-center ga-2">
        <p>{{ getDate }}</p>
        <p class="pa-2" @click="emit('close', notification)">
          <Icon color='grey_light' icon="cross-icon"/>
        </p>
      </div>
    </div>
    <div class="font-size-12-600 pb-2">{{ notification.content }}</div>
  </v-card>
</template>

<script lang="ts" setup>
import {defineProps, computed, defineEmits} from 'vue';
import Icon from "@/components/Icons/Icon.vue";
import moment from 'moment';

const emit = defineEmits(['close'])

const props = defineProps({
  notification: {
    type: Object,
    default: () => ({})
  }
})

const getDate = computed(() => {
  return moment(props.notification.created_at).format('h:mm A');
})
</script>

<style lang="scss" scoped>
.card-container {
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  background: #F5F5F5;
  padding: 8px 14px;
  box-shadow: 2px 2px 4px 0px #00000014;
}
</style>