<template>
  <v-container class="px-0 pt-0 pb-5">
    <div class="text-right px-4 pt-3" @click="router.push({ name: 'medical-alerts' })">
      <Icon color="primaryColor" icon="cross-icon"/>
    </div>

    <section class="px-10">
      <CreatedBy/>

      <MedicalAlertForm
          v-model="formState"
          :errors="errors"
      />
    </section>
  </v-container>

  <section>
    <ButtonFixed
        :custom-class="['text-white', {
          'primary-button': !disableSave,
          'grey-disable': disableSave || loading
        }]"
        custom-icon-class="add-new-circle-icon"
        text="Save"
        @click="onSave"
    />
  </section>
</template>

<script lang="ts" setup>
import Icon from "@/components/Icons/Icon.vue";
import MedicalAlertForm from "@/views/ClientComponents/MedicalAlerts/Partials/MedicalAlertForm.vue";
import {computed, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import CreatedBy from "@/components/custom/CreatedBy.vue";
import ButtonFixed from "@/components/custom/ButtonFixed.vue";
import moment from "moment/moment";
import {medicalAlertsService} from "@/api/services/medicalAlerts";
import {useSettingsStore} from "@/api/store/settings";

const settingsStore = useSettingsStore();
const router = useRouter();
const route = useRoute();

const errors = ref(null);
const loading = ref(false);

const formState = ref({
  name: '',
  description: '',
  type: 'Intolerance',
  date_from: moment().format('YYYY-MM-DD'),
  status: 'Ongoing',
  method: 'date',
  date_to: moment().format('YYYY-MM-DD'),
  client_visibility: true,
});

const disableSave = computed(() => {
  return !formState.value.name || !formState.value.description
})

const onSave = () => {
  loading.value = true;

  if (route.params.action === 'edit') {
    medicalAlertsService.update(route.params.id, formState.value).then(() => {
      loading.value = false;
      router.push({name: 'medical-alerts'})
    }).catch((error) => {
      errors.value = error.response.data.errors
      loading.value = false;
    });
  } else {
    medicalAlertsService.create(formState.value).then(() => {
      loading.value = false;
      router.push({name: 'medical-alerts'})
    }).catch((error) => {
      errors.value = error.response.data.errors
      loading.value = false;
    });
  }
}

const fetchMedicalAlert = () => {
  if (route.params.action === 'edit') {
    settingsStore.loading = true;

    medicalAlertsService.show(route.params.id).then(({data}) => {
      formState.value = {
        name: data.name,
        description: data.description,
        type: data.type,
        date_from: data.date_from,
        status: data.status,
        method: data.method,
        date_to: data.date_to,
      }

      settingsStore.loading = false;
    })
  }
}

onMounted(fetchMedicalAlert)
</script>

<style lang="scss" scoped>
.medical-alert-form-name {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}
</style>