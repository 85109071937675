<template>
    <v-container class="text-center">
        <v-row class="mt-10">
            <v-col class="pa-15 text-center">
                <v-sheet class="b-round-20 pa-10 mx-auto" :color="settingsStore.settings.primaryColor"
                    style="height:200px;width:200px">
                    <v-icon size="100" icon="fa-shopping-bag" class="text-white"></v-icon>
                    <v-sheet class="tick-icon-outer pa-2" color="green">
                        <v-icon size="30" icon="fa-check" class="text-white ma-auto" color="white"></v-icon>
                    </v-sheet>
                </v-sheet>
                <p class="p-text-lg mt-5" style="line-height: 30px;;">Thank you very much,<br />your order is confirmed!</p>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col cols="12">
                <v-btn class="b-round-20 text-white" :color="settingsStore.settings.buttonColor" width="80%">View
                    Order</v-btn>
            </v-col>
            <v-col cols="12">
                <v-btn class="b-round-20" :color="settingsStore.settings.buttonColor" width="80%"
                    variant="outlined">Continue Shopping</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { useSettingsStore } from '@/api/store/settings';
const settingsStore = useSettingsStore();

</script>

<style>
.tick-icon-outer {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    margin-top: -20px;
    margin-left: 70px;
}</style>