<template>
  <v-text-field
      placeholder="Name"
      density="compact"
      class="pt-3 pb-5"
      width="100%"
      variant="solo"
      elevation="0"
      hide-details
      v-model="setForm.name"
  />

  <ErrorMessages :errors="errors" name="name"/>

  <div class="pb-6">
    <TextArea v-model="setForm.description"/>
  </div>

  <ErrorMessages :errors="errors" name="description"/>

  <FlexContainer title="Type">
    <div class="v-col-6 pr-0">
      <SelectDropdown
          v-model="setForm.type"
          :items="medicalAlertTypes"
          :variant="variant"
          :disabled="disabledSelect"
          :readonly="readonlySelect"
      />
    </div>
  </FlexContainer>

  <ErrorMessages :errors="errors" name="type"/>

  <v-divider></v-divider>

  <FlexContainer title="Date From">
    <div class="d-flex align-center">
        <span class="font-size-12-600 font-black pr-3">
          {{ formattedDate(setForm.date_from, 'DD MMM YYYY') }}
        </span>

      <RotatingArrow :rotate-arrow="rotateArrowDateFrom"/>

      <DatePicker
          class="date-calendar-input"
          v-model="setForm.date_from"
          :min="minDateFrom"
          :max="moment().format('YYYY-MM-DD')"
          @blur="rotateArrowDateFrom = false"
          @focus="rotateArrowDateFrom = true"
      />
    </div>
  </FlexContainer>

  <ErrorMessages :errors="errors" name="date_from"/>

  <v-divider></v-divider>

  <FlexContainer title="Status">
    <div class="v-col-6 pr-0">
      <SelectDropdown
          v-model="setForm.status"
          :items="statusTypes"
          :variant="variant"
          :disabled="disabledSelect"
          :readonly="readonlySelect"
      />
    </div>
  </FlexContainer>

  <v-divider></v-divider>

  <FlexContainer title="Date To">
    <div class="d-flex align-center">
        <span
            class="font-size-12-600 font-black pr-3"
            :class="{'font-grey': setForm.status === 'Ongoing' }"
        >
          {{ setForm.status === 'Ongoing' ? 'Present' : formattedDate(setForm.date_to, 'DD MMM YYYY') }}
        </span>

      <RotatingArrow
          v-if="setForm.status === 'Ended'"
          :rotate-arrow="rotateArrowDateTo"
      />

      <DatePicker
          class="date-calendar-input"
          v-model="setForm.date_to"
          :min="minDateTo"
          :max="moment().format('YYYY-MM-DD')"
          :disabled="setForm.status === 'Ongoing'"
          @blur="rotateArrowDateTo = false"
          @focus="rotateArrowDateTo = true"
      />
    </div>
  </FlexContainer>

  <ErrorMessages :errors="errors" name="date_to"/>

  <v-divider></v-divider>
</template>

<script setup lang="ts">
import {defineProps, ref, defineEmits, reactive, watch, computed} from 'vue';
import TextArea from "@/components/custom/TextArea.vue";
import SelectDropdown from "@/components/custom/SelectDropdown.vue";
import FlexContainer from "@/views/ClientComponents/MedicalAlerts/Partials/FlexContainer.vue";
import DatePicker from "@/components/custom/DatePicker.vue";
import moment from "moment";
import RotatingArrow from "@/components/custom/RotatingArrow.vue";
import ErrorMessages from "@/components/custom/ErrorMessages.vue";
import {statusTypes, medicalAlertTypes} from "@/views/ClientComponents/MedicalAlerts/Helpers";
import {useRoute} from "vue-router";

const route = useRoute();

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {
    type: Object,
  },

  variant: {
    type: String,
  },

  disabledSelect: {
    type: Boolean,
    default: false,
  },

  readonlySelect: {
    type: Boolean,
    default: false,
  },

  errors: {
    type: Object,
  }
})

const setForm = ref({...props.modelValue});
const rotateArrowDateFrom = ref(false);
const rotateArrowDateTo = ref(false);

watch(() => props.modelValue, (val) => {
  setForm.value = val
})

watch(setForm, (newValue) => {
  rotateArrowDateFrom.value = false;
  rotateArrowDateTo.value = false;

  if (newValue.status === 'Ongoing') {
    newValue.date_to = null;
  }

  if (newValue.status === 'Ended' && !newValue.date_to) {
    newValue.date_to = moment().format('YYYY-MM-DD');
  }

  emit('update:modelValue', newValue);
}, {deep: true});

const formattedDate = (value: string | number, format = 'YYYY-MM-DD') => {
  return moment(value).format(format)
}

const minDateFrom = computed(() => {
  return route.query.dob ? route.query.dob : null
})

const minDateTo = computed(() => {
  return setForm.value.date_to ? formattedDate(setForm.value.date_from) : null
})
</script>

<style scoped lang="scss">
:deep(.v-field--variant-solo, .v-field--variant-solo-filled) {
  box-shadow: none;
  border: 1px solid #000000;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.date-calendar-input {
  opacity: 0;
  position: absolute;
  right: 0;
  z-index: 1;
  top: 0;
  bottom: 0;
  width: 66px;
  font-size: 45px;
}
</style>