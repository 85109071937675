import { defineStore } from 'pinia';
import { computed } from 'vue';
import { cartService } from '../services/cart';

export const useCartStore = defineStore('cart', {
    state: () => {
        return {
            items: [] as any[],
            item_count: 0 as number,
            cart_loading: false as boolean,
        }
    },
    actions: {
        async updateCarts(): Promise<any> {
            this.cart_loading = true;
            return cartService.index().then((response: any) => {
                this.items = response.data.items;
                this.item_count = response.data.item_count;
            }).catch((error: any) => {
                this.items = [];
                this.item_count = 0;
                console.log(error);
            }).finally(() => {
                this.cart_loading = false;
            });
        },
        async getActiveCart(supplier_id: number | null): Promise<any> {
            this.cart_loading = true;
            this.items = this.items || []; // Ensure this.items is initialized as an array
            const supplier = supplier_id !== null ? supplier_id : 0;
        
            // Ensure the array is initialized up to the required index
            if (supplier >= this.items.length) {
                this.items.length = supplier + 1; // Extend the array length
            }
        
            await cartService.getCart(supplier).then((response: any) => {
                console.log("response", response);
                this.items[supplier] = response.cart;
                console.log("items", this.items);
            }).catch((error: any) => {
                console.log(error);
            }).finally(() => {
                this.cart_loading = false;
            });
        },
        async addItem(supplier_id: number | null, product_id: number, quantity: number, product_type?: string): Promise<any> {
            const supplier = supplier_id !== null ? supplier_id : 0;
            await this.getActiveCart(supplier_id);
            console.log("cart " + supplier, this.items[supplier]);
            await cartService.addItem(this.items[supplier].id, product_id, quantity, product_type).then((response: any) => {
                console.log('Item added to cart');
            })
            .catch((error: any) => {
                console.log(error);
            });

        }
    },
})