<template>
  <svg fill="none" height="48" viewBox="0 0 24 19" width="48" xmlns="http://www.w3.org/2000/svg">
    <line stroke="currentColor" stroke-width="1.3" x1="8.49228" x2="18.4923" y1="5.57488" y2="5.57488"/>
    <line stroke="currentColor" stroke-width="1.3" x1="8.49228" x2="18.4923" y1="9.57488" y2="9.57488"/>
    <line stroke="currentColor" stroke-width="1.3" x1="8.49228" x2="18.4923" y1="13.5749" y2="13.5749"/>
    <path
        d="M7.5 5.6C7.5 6.15228 7.05229 6.6 6.5 6.6C5.94772 6.6 5.5 6.15228 5.5 5.6C5.5 5.04771 5.94772 4.6 6.5 4.6C7.05229 4.6 7.5 5.04771 7.5 5.6Z"
        fill="currentColor"/>
    <path
        d="M7.50138 9.58939C7.50138 10.1417 7.05367 10.5894 6.50138 10.5894C5.9491 10.5894 5.50138 10.1417 5.50138 9.58939C5.50138 9.0371 5.9491 8.58939 6.50138 8.58939C7.05367 8.58939 7.50138 9.0371 7.50138 9.58939Z"
        fill="currentColor"/>
    <path
        d="M7.5 13.6C7.5 14.1523 7.05228 14.6 6.5 14.6C5.94772 14.6 5.5 14.1523 5.5 13.6C5.5 13.0477 5.94772 12.6 6.5 12.6C7.05228 12.6 7.5 13.0477 7.5 13.6Z"
        fill="currentColor"/>
  </svg>
</template>

