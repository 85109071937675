import {HttpService, isAxiosError} from "./http";

class MedicalAlertsService extends HttpService {
    public constructor() {
        super("/client-app/client/medical-alerts");
    }

    public index(filters: string | object | null = ''): Promise<any> {
        return this.http
            .get(`${this.route}/`, {
                params: filters
            })
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public show(alertId: number) {
        return this.http
            .get(`${this.route}/${alertId}`)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public create(formData = {}) {
        return new Promise((resolve, reject) => {
            return this.http
                .post(`${this.route}/new `, formData)
                .then((response) => {
                    this.handleResponse.bind(this)
                    resolve(response)
                })
                .catch((error) => {
                    if (isAxiosError(error) && error.response?.status !== 422) {
                        this.handleError.bind(this);
                    }
                    reject(error);
                });
        });
    }

    public update(alert: number, formData = {}) {
        return this.http.put(`${this.route}/${alert}`, formData)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }
}

export const medicalAlertsService: MedicalAlertsService = new MedicalAlertsService();
