import {HttpService} from "./http";

class OrdersService extends HttpService {
    public constructor() {
        super("/client-app/client/orders");
    }

    public index(filters: string | object | null = null): Promise<any> {
        return this.http
            .get(`${this.route}/`, {
                params: filters
            })
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public show(id: number): Promise<any> {
        return this.http
            .get(this.route + "/" + id)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public finalize(id: number, address_id: number): Promise<any> {
        return this.http
            .post(this.route + "/" + id + "/finalize", {shipping_address_id: address_id})
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }
}

export const ordersService: OrdersService = new OrdersService();
