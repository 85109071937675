<template>
  <div v-if="error">
    <div class="text-center">
      <h3>Unable to load preview</h3>
    </div>
  </div>
  <div v-else-if="!loaded">
    <v-progress-linear indeterminate color="primary"></v-progress-linear>
    <div class="text-center">
      <h3>Loading Preview...</h3>
    </div>
  </div>
  <v-container v-else class="py-4 px-6">
    <v-row no-gutters class="px-3">
      <v-col class="text-center">
        <v-btn icon :color="settingsStore.settings.primaryColor" variant="flat" size="x-large"
          class="sw-button-roundness">
          <v-icon icon="fa-heartbeat" color="white" size="30"></v-icon>
        </v-btn>
        <br />
        <span class="smText">Menu 1</span>
      </v-col>
      <v-col class="text-center">
        <v-btn icon :color="settingsStore.settings.primaryColor" variant="flat" size="x-large"
          class="sw-button-roundness">
          <v-icon icon="fa-shopping-cart" color="white" size="30"></v-icon>
        </v-btn>
        <br />
        <span class="smText">Menu 2</span>
      </v-col>

      <v-col class="text-center">
        <v-btn icon :color="settingsStore.settings.primaryColor" variant="flat" size="x-large"
          class="sw-button-roundness">
          <v-icon icon="fa-folder-open" color="white" size="30"></v-icon>
        </v-btn>
        <br />
        <span class="smText">Menu 3</span>
      </v-col>

      <v-col class="text-center">
        <v-btn icon :color="settingsStore.settings.primaryColor" variant="flat" size="x-large"
          class="sw-button-roundness">
          <v-icon icon="fa-list" color="white" size="30"></v-icon>
        </v-btn>
        <br />
        <span class="smText">Menu 4</span>
      </v-col>
    </v-row>
    <v-row class="p-4">
      <v-card>
        <v-card-title>
          <h3>App Preview </h3>
        </v-card-title>
        <v-card-text>
          <p>This is an app preview, for a full view please login as your demo client to view the full portal</p>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row class="text-center py-4">
      <v-col>
        <v-btn :color="settingsStore.settings.buttonColor">
          Example Button 1
        </v-btn>
      </v-col>
      <v-col>
        <v-btn :color="settingsStore.settings.buttonColor">
          Example Button 2
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts" setup>
import { useSettingsStore } from '@/api/store/settings';
import { appService } from '@/api/services/app';
import router from '@/router';

const settingsStore = useSettingsStore();

let loaded = false;
let error = false;

const uuid = router.currentRoute.value.params.uuid as string;

if(!uuid) {
  error = true;
}

appService.fetchDisplayById(uuid).then((response: any) => {
  settingsStore.settings = JSON.parse(response.settings);
  loaded = true;
}).catch((err: any) => {
  console.log(err);
});


</script>