<template>
  <v-container v-if="practitioner" class="px-0 pb-5 text-center"
               style="background-color:white;min-height:500px; padding-top: 20px">
    <v-sheet class="avatar-container">
      <div v-if="getAvatar?.image"
           :style="{ backgroundImage: `url(${getAvatar.image})` }"
           class="avatar-container__image"></div>
      <div v-else class="avatar-container__initials primary-background-button-color">
        {{ getAvatar?.initials }}
      </div>
      <v-overlay activator="parent" location-strategy="connected" scroll-strategy="close">
        <v-card class="pa-2">
          {{ getAvatar.name }}
        </v-card>
      </v-overlay>
    </v-sheet>

    <p class="font-size-16-600 mt-4">
      {{ practitioner?.creator.first_name }} {{ practitioner?.creator.last_name }}
    </p>
    <p class="font-size-12-400 mt-1">
      {{ firstLetterUppercase(practitioner?.creator?.role) }}
    </p>

    <div class="d-flex align-center justify-center ga-1 mt-3">
      <Icon v-if="practitioner?.booking_options?.allow_standard" color="#1EBCD5" icon="location-icon"/>
      <Icon v-if="practitioner?.booking_options?.allow_phone" color="#1EBCD5" icon="phone-icon"/>
      <Icon v-if="practitioner?.booking_options?.allow_video" color="#1EBCD5" icon="video-icon"/>
    </div>

    <p v-if="practitioner?.creator?.bio" class="font-size-12-400 mt-4 ma-auto description-container">
      {{ practitioner?.creator?.bio }}
    </p>

    <div v-if="!route.params.view" class="mt-8">
      <v-btn
          :class="route.params.practitionerId ? 'secondary-button' : 'transparent secondary-button-color'"
          class="b-round-20 outline-btn"
          elevation="0"
          height="32px"
          width="234.14px"
          x-small
          @click="selectPractitioner"
      >
        {{ route.params.practitionerId ? 'Unselect' : 'Select' }}
      </v-btn>
    </div>
  </v-container>
</template>

<script lang="ts" setup>
import {computed, ref, onMounted} from "vue";
import {useRoute, useRouter} from "vue-router";
import {servicesService} from "@/api/services/service";
import {firstLetterUppercase} from "@/components/Helpers/Utils.ts";
import Icon from "@/components/Icons/Icon.vue";

const practitioner = ref(null);
const route = useRoute();
const router = useRouter();

const loadService = async () => {
  const serviceId = route.params.serviceId;
  if (serviceId) {
    await servicesService.getService(serviceId).then(response => {
      console.log("response", response);
      practitioner.value = response;
    });
  }
};

onMounted(() => {
  loadService();
});

const getAvatar = computed(() => {
  const creator = practitioner.value?.creator;
  if (creator) {
    const firstName = creator.first_name ?? '';
    const lastName = creator.last_name ?? '';

    return creator.image
        ? {image: creator.image.file?.file_path ?? '', initials: null, name: `${firstName} ${lastName}`}
        : {image: null, initials: (firstName[0] + (lastName[0] || '')).toUpperCase(), name: `${firstName} ${lastName}`};
  }
  return null;
});

const selectPractitioner = () => {
  let practitionerId = practitioner.value?.creator?.id;

  if (practitionerId && route.params.practitionerId) {
    practitionerId = null;
  }

  router.push({
    name: 'book-service',
    params: {id: route.params.serviceId, practitionerId}
  });
};
</script>

<style lang="scss" scoped>
.avatar-container {
  border-radius: 100%;
  width: 170px;
  height: 170px;
  margin: auto;

  &__image {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__initials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: 12px;
    border-radius: 50%;
  }
}

.description-container {
  width: 305px;
  line-height: 18px;
}
</style>
