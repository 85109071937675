<template>
  <v-row class="mt-6 mx-0">
    <NotificationsComponent
        v-if="state.notifications.length > 0"
        :notifications="state.notifications"
        @onClose="markRead"
    />

    <EventsAndActivityPanelCard v-if="isEmptyObject(state.event)"/>
    <EventsActivity v-else :event="state.event"/>

    <EventsAndActivityPanelCard
        v-if="state.recentActivities.length === 0"
        empty-content-custom-class="empty-message"
        firstRow="You have no recent"
        icon="basket-icon"
        secondRow="activity"
        sub-title="Activity"
        title="Recent Activity"
    />
    <ActivityPanelType v-else :recent-activity="state.recentActivities[0]"/>
  </v-row>
</template>

<script lang="ts" setup>
import {notificationsService} from '@/api/services/notification';
import {consultationService} from '@/api/services/consultation';
import {reactive} from 'vue';
import EventsAndActivityPanelCard
  from "@/components/Layout/LayoutV2/EventsAndActivityPanel/EventsAndActivityPanelCard.vue";
import NotificationsComponent from "@/components/Layout/LayoutV2/Notifications/NotificationsComponent.vue";
import {activityService} from "@/api/services/activity";
import ActivityPanelType from "@/components/Layout/LayoutV2/EventsAndActivityPanel/ActivityPanelType.vue";
import EventsActivity from "@/components/Layout/LayoutV2/EventsAndActivityPanel/Cards/EventsActivity.vue";
import {isEmptyObject} from "@/components/Helpers/Utils";

const state = reactive({
  notifications: [] as any,
  event: null as any,
  recentActivities: [] as any,
});

const getNotifications = () => {
  notificationsService.index().then((response) => {
    state.notifications = response.data.filter((notification: any) => !notification.read);
  });
}

const markRead = (notification: object) => {
  notificationsService.read(notification.id).then(() => {
    state.notifications = state.notifications.filter((item: any) => item.id !== notification.id);
    getNotifications();
  });
}

const getEvent = () => {
  consultationService.mostRecent().then((response) => {
    state.event = response;
  });
}

const getRecentActivities = () => {
  activityService.index({
    per_page: 1
  }).then((response) => {
    state.recentActivities = response.data;
  });
}

getNotifications();
getEvent();
getRecentActivities();
</script>

<style lang="scss" scoped>
:deep(.card-container) {
  width: 100%;
}

:deep(.card-container .card-container-sheet) {
  display: flex !important;
  align-items: end !important;
}
</style>