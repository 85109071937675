<template>
  <GpDetails
      v-if="!state.insuranceDetails.edit"
      :button-color="settingsStore.settings.buttonColor"
      :color="settingsStore.settings.primaryColor"
      :data="state.data?.gp_details"
      :edit="state.gpDetails.edit"
      @close="onClose('gpDetails')"
      @edit="onEdit('gpDetails')"
      @save="saveGpDetails"
  />

  <InsuranceDetails
      v-if="!state.gpDetails.edit"
      :button-color="settingsStore.settings.buttonColor"
      :color="settingsStore.settings.primaryColor"
      :data="state.data?.insurance_details"
      :edit="state.insuranceDetails.edit"
      @close="onClose('insuranceDetails')"
      @edit="onEdit('insuranceDetails')"
      @save="saveInsuranceDetails"
  />
</template>

<script lang="ts" setup>
import {useSettingsStore} from "@/api/store/settings";
import {useUserStore} from "@/api/store/user";
import {inject, onMounted, reactive} from "vue";
import GpDetails from "@/views/ClientComponents/MedicalDetails/GpDetails.vue";
import InsuranceDetails from "@/views/ClientComponents/MedicalDetails/InsuranceDetails.vue";
import {clientService} from "@/api/services/client";

const settingsStore = useSettingsStore();
const userStore = useUserStore();

const snackBarPlugin = inject('snackbar');

const state = reactive({
  data: null,
  gpDetails: {
    edit: false,
  },
  insuranceDetails: {
    edit: false,
  },
})

let stateBackup = JSON.parse(JSON.stringify(state.data))

const onEdit = (key) => {
  stateBackup = JSON.parse(JSON.stringify(state.data))
  state[key].edit = true
}

const onClose = (key) => {
  state.data = JSON.parse(JSON.stringify(stateBackup))
  state[key].edit = false
}

const saveGpDetails = (data) => {
  state.gpDetails.edit = false;
  userStore.updateGpDetails(data, state.data.id).then(() => {
    snackBarPlugin.successSnackbar('GP details updated successfully');
  }).catch(() => {
    snackBarPlugin.errorSnackbar('Failed to update GP details');
  })
}

const saveInsuranceDetails = (data) => {
  state.insuranceDetails.edit = false;
  userStore.updateInsuranceDetails(data, state.data.id).then(() => {
    snackBarPlugin.successSnackbar('Insurance details updated successfully');
  }).catch(() => {
    snackBarPlugin.errorSnackbar('Failed to update insurance details');
  })
}

const fetchData = () => {
  settingsStore.loading = true;
  clientService.profileDetails().then(({data}) => {
    state.data = data;
    settingsStore.loading = false;
  })
}

onMounted(() => {
  fetchData();
})
</script>