<template>
  <v-container class="px-0 pb-0 surveys" style="background-color:#F0F0F0; position: relative;">
    <v-card-title class="date-label mx-3">
      Entry: <span class="date-color">{{ moment().format('DD MMM YYYY') }}</span>
    </v-card-title>

    <v-container
        v-for="(question, index) in state.survey.questions" :key="`survey-${index}`"
        class="px-6 py-0"
    >
      <v-sheet class="border-rounded pa-3 mb-3">
        <div class="pa-2">
          <p class="mb-3">{{ question.title }}</p>
          <QuestionSlider v-model="question.answer" :color="settingsStore.settings.primaryColor" :question="question"/>
        </div>
      </v-sheet>

      <v-textarea
          v-if="question.enable_comments == 1"
          v-model="question.comment"
          density="compact"
          no-resize
          placeholder="Add a Comment..."
          rows="1"
          variant="solo"
      />
    </v-container>

    <section
        style="position: sticky; bottom: 0; background-color: white; padding: 20px; box-shadow: 0px 4px 10px 0px #0000000D;"
    >
      <v-btn
          :color="!isSubmissionAllowed || disableSubmission ? '#ABAAAA' : settingsStore.settings.buttonColor2"
          :disabled="!isSubmissionAllowed || disableSubmission"
          class="b-round-20 primary-button"
          @click="submitAnswers"
      >
        Submit
      </v-btn>
    </section>

  </v-container>
</template>

<script lang="ts" setup>
import {computed, onMounted, reactive, ref} from "vue";
import {surveysService} from "@/api/services/surveys";
import {useSettingsStore} from "@/api/store/settings";
import {useRouter} from "vue-router";
import moment from "moment";
import QuestionSlider from "@/views/DocumentComponents/DocumentSurveys/Partials/QuestionSlider.vue";

const settingsStore = useSettingsStore();
const router = useRouter();

const state = reactive({
  survey: {},
});

const disableSubmission = ref(false);

const getSurveysQuestions = () => {
  settingsStore.loading = true;

  surveysService.show(router.currentRoute.value.params.surveyId as number).then((response) => {

    state.survey = {
      ...response,
      questions: response.questions.map((question: any) => {
        return {
          ...question,
          answer: 0,
          comment: ""
        }
      })
    };

    router.currentRoute.value.meta.showTitleBar = true;
    router.currentRoute.value.meta.title = state.survey.name;

    settingsStore.loading = false;
  });
}

const isSubmissionAllowed = computed(() => {
  if (!state.survey.questions || state.survey.questions.length === 0) {
    return false;
  }
  return state.survey.questions.every(question => question.answer >= 1);
});

const submitAnswers = () => {
  if (!isSubmissionAllowed.value) return;

  disableSubmission.value = true;

  surveysService.store(state.survey, router.currentRoute.value.params.clientSurveyId).then(() => {
    disableSubmission.value = false;
    router.push({name: 'document-surveys'});
  });
}

onMounted(() => {
  getSurveysQuestions()
});
</script>


<style lang="scss" scoped>
.date-color {
  color: #B5B8BC;
}

:deep(.v-field--variant-solo) {
  border-radius: 10px;
  box-shadow: none;
  border: 1px solid #0000001A;
  color: #838383;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
}

:deep(.v-field__input) {
  padding: 20px;
}
</style>