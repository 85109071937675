<template>
  <EventsAndActivityPanelCard>
    <template #customContent>
      <div class="d-flex align-center justify-space-between w-100">
        <div
            class="ml-3"
            @click="router.push({ name: 'order-view', params: { id: getOrderId } })"
        >
          <p class="font-16">{{ event.name }}</p>
          <div v-if="getClinicServiceNameFromPackage.packageName" class="d-flex ga-1">
            <p class="font-14"> {{ getClinicServiceNameFromPackage.serviceName }}</p>
            <p class="font-14 font-grey-dark">({{ getClinicServiceNameFromPackage.packageName }})</p>
          </div>
          <p class="font-14"> {{ convertDateCalendar(event.start_date) }}</p>
          <div class="d-flex ga-1">
            <p class="font-14">({{ event.duration ?? '30 minutes' }})</p>
            <p class="font-14">{{ event.model?.method }}</p>
          </div>
        </div>

        <div class="text-right mt-3">
          <v-btn
              class="b-round-20 outline-btn white-btn mb-2"
              elevation="0"
              height="32px"
              variant="outlined"
              width="82px"
              x-small
              @click="onShowEventModal"
          >
            <span class="mr-1">Info</span>
          </v-btn>
        </div>
      </div>

      <EventDetailsModal
          v-if="showEventDetailsModal"
          v-model="showEventDetailsModal"
          :event="event"
      />
    </template>
  </EventsAndActivityPanelCard>
</template>

<script lang="ts" setup>
import {computed, defineProps, ref} from "vue";
import {useRouter} from "vue-router";
import {convertDateCalendar} from "@/components/Helpers/DateHelper";
import EventsAndActivityPanelCard
  from "@/components/Layout/LayoutV2/EventsAndActivityPanel/EventsAndActivityPanelCard.vue";
import Icon from "@/components/Icons/Icon.vue";
import EventDetailsModal from "@/components/Layout/LayoutV2/EventsAndActivityPanel/Cards/EventDetailsModal.vue";

const router = useRouter();

const props = defineProps({
  event: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const showEventDetailsModal = ref(false);

const getOrderId = computed(() => {
  return props.event.model.consultation.order_id ?? null
})

const getClinicServiceNameFromPackage = computed(() => {

  if (props.event.model.consultation.package_id !== null) {
    return {
      serviceName: props.event.model.clinic_service.name,
      packageName: props.event.model.consultation.name
    } ?? {}
  }

  return {}
})

const onShowEventModal = () => {
  showEventDetailsModal.value = !showEventDetailsModal.value;
};
</script>

<style lang="scss" scoped>
.font-16 {
  font-size: 16px;
  font-weight: 600;
}

.font-14 {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
</style>