<template>
  <v-container style="background-color: #F5F5F5;">
    <ProfileDetails
        v-if="!state.emergencyContact.edit && !state.primaryAddress.edit && !state.shippingAddress.edit"
        :button-color="settingsStore.settings.buttonColor"
        :color="settingsStore.settings.primaryColor"
        :data="userStore.user"
        :edit="state.profileForm.edit"
        @close="onClose('profileForm')"
        @edit="onEdit('profileForm')"
        @save="save"
    />
    <EmergencyContact
        v-if="!state.profileForm.edit && !state.primaryAddress.edit && !state.shippingAddress.edit"
        :button-color="settingsStore.settings.buttonColor"
        :color="settingsStore.settings.primaryColor"
        :data="userStore.user.emergency_contact"
        :edit="state.emergencyContact.edit"
        @close="onClose('emergencyContact')"
        @edit="onEdit('emergencyContact')"
        @save="saveEmergency"
    />
    <PrimaryAddress
        v-if="!state.profileForm.edit && !state.emergencyContact.edit && !state.shippingAddress.edit"
        :button-color="settingsStore.settings.buttonColor"
        :color="settingsStore.settings.primaryColor"
        :data="getAddress('primary')"
        :edit="state.primaryAddress.edit"
        @close="onClose('primaryAddress')"
        @edit="onEdit('primaryAddress')"
        @save="savePrimaryAddress"
    />

    <v-sheet
        v-if="!state.profileForm.edit && !state.emergencyContact.edit && !state.primaryAddress.edit && !state.shippingAddress.edit"
        class="px-1 pt-1 pb-1 b-round-20 switch-right"
        @click="toggleAddressMatch"
    >
      <v-switch
          v-model="addressMatch"
          :color="settingsStore.settings.primaryColor"
          :false-value="0"
          :true-value="1"
          class="switch-right font-size-10-400"
          label="Same as Shipping Address"
      />
    </v-sheet>
    <ShippingAddress
        v-if="!state.profileForm.edit && !state.emergencyContact.edit && !state.primaryAddress.edit && !addressMatch"
        :button-color="settingsStore.settings.buttonColor"
        :color="settingsStore.settings.primaryColor"
        :data="getAddress('shipping')"
        :edit="state.shippingAddress.edit"
        @close="onClose('shippingAddress')"
        @edit="onEdit('shippingAddress')"
        @save="saveShippingAddress"
    />
  </v-container>
</template>

<script lang="ts" setup>
import {useSettingsStore} from "@/api/store/settings"
import {inject, onMounted, reactive, ref, watch} from "vue";
import {useUserStore} from "@/api/store/user";
import EmergencyContact from "@/views/ClientComponents/PersonalDetails/EmergencyContact.vue";
import ProfileDetails from "@/views/ClientComponents/PersonalDetails/ProfileDetails.vue";
import PrimaryAddress from "@/views/ClientComponents/PersonalDetails/PrimaryAddress.vue";
import ShippingAddress from "@/views/ClientComponents/PersonalDetails/ShippingAddress.vue";
import {appService} from "@/api/services/app";
import {setUser} from "@/api/services/auth";

const snackBarPlugin = inject('snackbar');

const settingsStore = useSettingsStore();
const userStore = useUserStore();

const state = reactive({
  profileForm: {
    edit: false,
  },
  emergencyContact: {
    edit: false,
  },
  primaryAddress: {
    edit: false,
  },
  shippingAddress: {
    edit: false,
  },
});

const addressMatch = ref(userStore.user.address_match)
let stateBackup = JSON.parse(JSON.stringify(userStore.user))

const onClose = (key) => {
  userStore.user = JSON.parse(JSON.stringify(stateBackup))
  state[key].edit = false
}

const onEdit = (key) => {
  stateBackup = JSON.parse(JSON.stringify(userStore.user))
  state[key].edit = true
}

const save = (data: any) => {
  state.profileForm.edit = false;

  userStore.update(data).then(() => {
    snackBarPlugin.successSnackbar('Profile updated successfully');
  }).catch(error => {
    console.error('Failed to update profile:', error);
    snackBarPlugin.errorSnackbar('Failed to update profile');
  });
}
const saveEmergency = (data) => {
  state.emergencyContact.edit = false
  userStore.updateEmergency(data, data.client_id).then(() => {
    snackBarPlugin.successSnackbar('Emergency contact updated successfully');
  }).catch(error => {
    console.error('Failed to update emergency contact:', error);
    snackBarPlugin.errorSnackbar('Failed to update emergency contact');
  });
}
const savePrimaryAddress = (data) => {
  state.primaryAddress.edit = false

  userStore.updatePrimary(data, data.client_id).then((response) => {
    snackBarPlugin.successSnackbar('Primary address updated successfully');

    if (addressMatch.value == true) {
      const shippingAddress = {
        ...response.data,
        id: getAddress('shipping').id,
        type: 'shipping',
        address_match: true
      };
      userStore.updateShipping(shippingAddress, shippingAddress.client_id);
    }
  }).catch(error => {
    console.error('Failed to update primary address:', error);
    snackBarPlugin.errorSnackbar('Failed to update primary address');
  });
}
const saveShippingAddress = (data) => {
  state.shippingAddress.edit = false
  const shippingAddress = {...data, address_match: false};

  userStore.updateShipping(shippingAddress, data.client_id).then(() => {
    snackBarPlugin.successSnackbar('Shipping address updated successfully');
  }).catch(error => {
    console.error('Failed to update shipping address:', error);
    snackBarPlugin.errorSnackbar('Failed to update shipping address');
  });
}

// Get the address of the shipping or primary address type 
const getAddress = (type = 'primary') => {
  return userStore.user?.addresses.find(address => address.type === type)
}

const fetchData = () => {
  appService.fetchDisplay().then((response: any) => {
    userStore.setUser(response.client);
    setUser(response.client);
  })
}

const toggleAddressMatch = () => {
  if (addressMatch.value == false) {
    const primaryAddress = {
      ...getAddress('primary'),
      id: getAddress('shipping').id,
      type: 'shipping',
      address_match: true
    };
    userStore.updateShipping(primaryAddress, primaryAddress.client_id);
  }
}

onMounted(() => {
  fetchData();
})
</script>

<style lang="scss" scoped>
.switch-right {
  display: flex;
  margin: 20px;
}
</style>