import { HttpService } from "./http";

class DocumentService extends HttpService {
  public constructor() {
    super("/client-app/client/documents");
  }

  public index(filters: string | null = null): Promise<any> {

    let route = this.route + "/";

    if(filters ) {
        route += "?";
        route += filters;
    }

    return this.http
      .get(route)
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }

  public show(id: string): Promise<any> {
    return this.http
      .get(this.route + "/" + id)
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }

  public update(id: string, data: any): Promise<any> {
    return this.http
      .put(this.route + "/" + id, data)
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }
}

export const documentService: DocumentService = new DocumentService();
