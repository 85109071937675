<template>
  <v-card class="mx-5 my-3 card-container">
    <v-sheet class="card-container-sheet pa-4">
      <slot></slot>
    </v-sheet>
  </v-card>
</template>

<style scoped lang="scss">
.card-container {
  border-radius: 10px;
  box-shadow: 0.5px 0.5px 15px 0px #0000000D !important;
  border: 1px solid #F0F0F0;

  .card-container-sheet {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    align-items: center;
    border-radius: 30px;
  }
}
</style>
