<template>
  <v-container class="px-0 pb-5 surveys" style="background-color:#F0F0F0;min-height:500px;">
    <v-sheet class="mx-6 border-rounded">
      <v-container class="d-flex justify-space-between align-center pa-2 pb-0">
        <v-card-title v-if="currentSurvey" class="text-h6 font-weight">
          Overview <span v-if="currentSurvey.name" class="primary-color">({{ currentSurvey?.name }})</span>
        </v-card-title>

        <div class="pr-2">
          <LeftAndRightArrows
              @left="previousSurvey"
              @right="nextSurvey"
          />
        </div>
      </v-container>

      <v-container>
        <v-select
            v-model="selectedQuestionTitle"
            :items="currentSurvey?.questions"
            density="compact"
            item-id="id"
            item-title="title"
            return-object
            variant="solo"
        />

        <div class="d-flex justify-center">

          <div
              v-for="(question, index) in state.clientSurveysWithAnswer" :key="`survey-${index}`"
              class="display-grid question-slider"
          >
            <QuestionSlider
                v-model="question.responses"
                :color="settingsStore.settings.primaryColor"
                :min="0"
                :question="question"
                :show-ticks="false"
                direction="vertical"
                readonly
                track-size="25"
            />

            <span class="slider-value">{{ question.responses }}</span>

            <div class="text-center date-label date-color mt-2">
              {{ getQuestionCompletedDate(question).month }} <br>
              <p class="mt-1">{{ getQuestionCompletedDate(question).year }}</p>
            </div>

          </div>
        </div>
      </v-container>

      <v-container class="d-flex justify-end pt-0">
        <LeftAndRightArrows
            @left="previousPage"
            @right="nextPage"
        />
      </v-container>
    </v-sheet>

    <v-card-title class="mx-3 text-h6 font-weight mt-3">Upcoming Surveys</v-card-title>
    <v-sheet v-if="Object.keys(state.upcomingSurvey).length > 0"
             class="mx-6 pa-3 border-rounded d-flex align-center justify-space-between">
      <div>
        <p class="p-text date-label">
          {{ moment(state.upcomingSurvey?.start).format('DD MMM YYYY') }}
          <span class="small">{{ getDueOrOverdue }} </span>
        </p>
        <p class="p-text mt-1">{{ state.upcomingSurvey?.survey?.name }}</p>
      </div>

      <v-btn
          :color="getDueOrOverdue.length === 0 || disableSurveyIfASurveyWasCompletedToday ? '#ABAAAA' : settingsStore.settings.buttonColor2"
          :disabled="getDueOrOverdue.length === 0 || disableSurveyIfASurveyWasCompletedToday"
          class="text-white b-round-20 complete-button"
          @click="onComplete"
      >
        Complete now
      </v-btn>
    </v-sheet>
    <v-sheet v-else class="mx-6 pa-3 border-rounded d-flex align-center justify-space-between">
      <p class="p-text">You have no active surveys running at this time.</p>
    </v-sheet>

    <v-card-title class="mx-3 text-h6 font-weight mt-3">Latest Entries</v-card-title>

    <v-sheet
        v-for="(entry, index) in state.latestEntries"
        :key="`entry-${index}`"
        class="mx-6 pa-3 border-rounded mb-2"
    >
      <div
          class="d-flex align-center justify-space-between"
      >
        <div>
          <p class="p-text date-label">
            {{ moment(entry.completed).format('DD MMM YYYY') }}
          </p>
          <p class="p-text mt-1">{{ entry.survey.name }}</p>
        </div>

        <v-btn
            class="b-round-20 view-button"
            @click="router.push({ name: 'document-survey-view', params: { id: entry.id, surveyName: entry.survey.name } })"
        >
          View
        </v-btn>
      </div>
    </v-sheet>

    <v-sheet
        v-if="state.latestEntries.length === 0"
        class="mx-6 pa-3 border-rounded d-flex align-center justify-space-between"
    >
      <p class="p-text">There is no information to show</p>
    </v-sheet>

  </v-container>
</template>

<script lang="ts" setup>
import {useSettingsStore} from "@/api/store/settings";
import {computed, reactive, ref, watch} from "vue";
import {surveysService} from "@/api/services/surveys";
import {useRouter} from "vue-router";
import moment from "moment/moment";
import LeftAndRightArrows from "@/views/DocumentComponents/DocumentSurveys/Partials/LeftAndRightArrows.vue";
import QuestionSlider from "@/views/DocumentComponents/DocumentSurveys/Partials/QuestionSlider.vue";

const router = useRouter();
const settingsStore = useSettingsStore();

const buttonColor = settingsStore.settings.buttonColor

const state = reactive({
  surveys: [] as any[],
  latestEntries: [] as any[],
  clientSurveysWithAnswer: [] as any[],
  upcomingSurvey: {},
  currentIndex: 0,

});

const questionsState = reactive({
  page: 1,
  hasMorePages: false,
});

const selectedQuestionTitle = ref({
  id: null,
  title: '',
});

const getSurveys = () => {
  settingsStore.loading = true;
  surveysService.index().then((response) => {
    state.surveys = [...response.active];
    state.latestEntries = [...response.latest_entries];
    state.upcomingSurvey = {...response.upcoming}
    settingsStore.loading = false;
  });
}

// Function to handle moving to the next survey
const nextSurvey = () => {
  if (state.currentIndex < state.surveys.length - 1) {
    state.currentIndex++;
  }
}

// Function to handle moving to the previous survey
const previousSurvey = () => {
  if (state.currentIndex > 0) {
    state.currentIndex--;
  }
}

// Function to handle the questions pagination
const nextPage = () => {
  if (questionsState.hasMorePages) {
    questionsState.page++;
    fetchClientSurveysWithAnswer();
  }
}

// Function to handle moving to the previous page on questions pagination
const previousPage = () => {
  if (questionsState.page > 1) {
    questionsState.page--;
    fetchClientSurveysWithAnswer()
  }
}

// Computed property to get the current survey based on currentIndex
const currentSurvey = computed(() => {
  const surveys = [...state.surveys]
  return surveys[state.currentIndex] || {};
});

const fetchClientSurveysWithAnswer = () => {
  settingsStore.loading = true;
  const questionId = selectedQuestionTitle.value.id;

  if (currentSurvey.value.id && questionId) {
    surveysService.getQuestionsResponse(currentSurvey.value.id, {
      question_id: questionId,
      page: questionsState.page
    }).then((response) => {
      state.clientSurveysWithAnswer = response.data.map((question) => {
        return {
          ...question,
          responses: question.responses[0]?.answer ?? 0,
        }
      });

      // Check if there are more pages available
      questionsState.hasMorePages = response.current_page < response.last_page;

      settingsStore.loading = false;

    });
  }
}

watch(currentSurvey, (newValue) => {
  if (newValue && newValue.questions && newValue.questions.length > 0) {
    selectedQuestionTitle.value = {
      id: newValue.questions[0].id,
      title: newValue.questions[0].title,
    };
  }
});

watch(selectedQuestionTitle, fetchClientSurveysWithAnswer);


const getQuestionCompletedDate = (question) => {
  if (question === undefined) return;

  return {
    month: moment(question.completed ?? question.start).format('DD MMM'),
    year: moment(question.completed ?? question.start).format('YYYY'),
  }
}


const getDueOrOverdue = computed(() => {
  const today = moment();
  const surveyStart = moment(state.upcomingSurvey?.start);

  if (today.isSame(surveyStart, 'day')) {
    return '(Due)';
  }

  if (today.isAfter(surveyStart)) {
    return '(Overdue)';
  }

  return '';
});

const disableSurveyIfASurveyWasCompletedToday = computed(() => {
  const today = moment().format('YYYY-MM-DD');
  const latestEntryToday = state.latestEntries.find((entry) => moment(entry.completed).format('YYYY-MM-DD') === today);

  return !!latestEntryToday;
});

const onComplete = () => {
  router.push({
    name: 'document-survey-form',
    params: {
      surveyId: state.upcomingSurvey.survey_id,
      clientSurveyId: state.upcomingSurvey.id
    },
  });
}

getSurveys()
</script>

<style lang="scss" scoped>
:deep(.v-field--variant-solo) {
  border-radius: 10px;
  box-shadow: none;
  border: 1px solid #0000001A;
}

:deep(.v-slider-track) {
  border-radius: 12px;
}

:deep(.v-slider.v-input--vertical) {
  margin: 0;
}

:deep(.v-slider-thumb__surface) {
  display: none;
}

:deep(.large-slider .v-slider) {
  height: 100px;
}

:deep(.v-slider.v-input--vertical > .v-input__control) {
  min-height: 150px;
}

.display-grid {
  display: grid;
  flex: 1 1 auto;
}

.question-slider {
  position: relative;
}

.slider-value {
  position: absolute;
  bottom: 50px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  color: #FFFFFF;
}

.name {
  color: v-bind(buttonColor)
}
</style>