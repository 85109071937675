<template>
    <v-container>
        <v-card v-if="state.notification">
            <v-card-title>
                <p class="p-text-lg">{{ state.notification.subject }}</p>
                <p class="p-text-sm mt-3">{{ moment(state.notification.created_at).format("LLL") }}</p>
            </v-card-title>
            <v-card-text>
                <div class="text-left mt-3">
                    <p class="p-text-sm">{{ state.notification.content }}</p>
                </div>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script lang="ts" setup>
import { reactive } from "vue";
import { useRoute } from "vue-router"
import { useSettingsStore } from "@/api/store/settings"
import { notificationsService } from '@/api/services/notification';
import moment from 'moment';

const settingsStore = useSettingsStore();
const route = useRoute();

const state = reactive({
    notification: null as any,
});

const getNotifications = () => {
    settingsStore.loading = true;
    notificationsService.show(route.params.id).then((response) => {
        state.notification = response;
        settingsStore.loading = false;
    });
}

getNotifications();
</script>