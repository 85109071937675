<template>
  <v-dialog
      v-model="dialog"
      max-width="400"
      min-width="30%"
      persistent
  >
    <v-card class="font-grey-dark">
      <v-card-title class="text-h6 d-flex justify-space-between align-center">
        Product Details
        <div @click="dialog = false">
          <Icon color="grey_light" icon="cross-icon"></Icon>
        </div>
      </v-card-title>

      <v-divider></v-divider>

      <div class="overflow-auto" style="min-height: 295px;">
        <v-expansion-panels v-if="isSupplier" variant="accordion">
          <v-expansion-panel v-for="(itemInfo, index) in attributes" :key="`itemInfo-${index}`">
            <v-expansion-panel-title>
              <p class="panel-title">{{ itemInfo.title ?? itemInfo.name }}</p>
              <template v-slot:actions>
                <v-icon class="panel-arrow" icon="fa-chevron-down">
                </v-icon>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div v-if="itemInfo.content" class="my-2 text-subtitle-1" v-html="itemInfo.content"></div>
              <div v-if="itemInfo.value" class="my-2 text-subtitle-1" v-html="itemInfo.value"></div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-card v-else class="font-grey-dark" variant="text">
          <template v-slot:text>
            <div v-for="(attribute,index) in attributes" :key="`description-${index}`" :class="{'pt-4': index > 0}">
              <p class="font-size-12-600 text-uppercase">{{ attribute.name }}</p>
              <p class="font-black">{{ attribute.value }}</p>
            </div>
          </template>
        </v-card>
      </div>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="b-round-20 outline-btn cancel"
            elevation="0"
            height="32px"
            x-small
            @click="dialog = false"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import {defineEmits, defineProps, ref, watch} from 'vue';
import Icon from "@/components/Icons/Icon.vue";

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: [String, Number],

  attributes: {
    type: Array,
    default: () => []
  },

  isSupplier: {
    type: Boolean,
    default: false
  }
})

const dialog = ref(props.modelValue);

watch(() => props.modelValue, (newValue) => {
  dialog.value = newValue;
});

watch(dialog, (newValue) => {
  emit('update:modelValue', newValue);
});
</script>

<style lang="scss" scoped>
.cancel {
  color: #B0B3B7 !important;
  border: 1px solid #B0B3B7;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
  linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
}

.panel-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #838383;
}

.panel-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
</style>
