<template>
  <svg fill="none" height="17" viewBox="0 0 17 17" width="17" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2918_1067)">
      <path
          d="M0.968872 15.4512L2.26887 10.9845C2.05776 10.5289 1.89943 10.0623 1.79387 9.58451C1.68832 9.10673 1.63554 8.61784 1.63554 8.11784C1.63554 7.19562 1.81054 6.32895 2.16054 5.51784C2.51054 4.70673 2.98554 4.00117 3.58554 3.40117C4.18554 2.80117 4.89109 2.32617 5.70221 1.97617C6.51332 1.62617 7.37998 1.45117 8.30221 1.45117C9.22443 1.45117 10.0911 1.62617 10.9022 1.97617C11.7133 2.32617 12.4189 2.80117 13.0189 3.40117C13.6189 4.00117 14.0939 4.70673 14.4439 5.51784C14.7939 6.32895 14.9689 7.19562 14.9689 8.11784C14.9689 9.04006 14.7939 9.90673 14.4439 10.7178C14.0939 11.529 13.6189 12.2345 13.0189 12.8345C12.4189 13.4345 11.7133 13.9095 10.9022 14.2595C10.0911 14.6095 9.22443 14.7845 8.30221 14.7845C7.80221 14.7845 7.31332 14.7317 6.83554 14.6262C6.35776 14.5206 5.89109 14.3623 5.43554 14.1512L0.968872 15.4512ZM2.93554 13.4845L5.06887 12.8512C5.22443 12.8067 5.38276 12.7901 5.54387 12.8012C5.70498 12.8123 5.85776 12.8512 6.00221 12.9178C6.35776 13.0956 6.72998 13.229 7.11887 13.3178C7.50776 13.4067 7.90221 13.4512 8.30221 13.4512C9.79109 13.4512 11.0522 12.9345 12.0855 11.9012C13.1189 10.8678 13.6355 9.60673 13.6355 8.11784C13.6355 6.62895 13.1189 5.36784 12.0855 4.33451C11.0522 3.30117 9.79109 2.78451 8.30221 2.78451C6.81332 2.78451 5.55221 3.30117 4.51887 4.33451C3.48554 5.36784 2.96887 6.62895 2.96887 8.11784C2.96887 8.51784 3.01332 8.91228 3.10221 9.30117C3.19109 9.69006 3.32443 10.0623 3.50221 10.4178C3.57998 10.5623 3.62165 10.7151 3.62721 10.8762C3.63276 11.0373 3.61332 11.1956 3.56887 11.3512L2.93554 13.4845Z"
          fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_2918_1067">
        <rect fill="white" height="16" transform="translate(0.302246 0.118164)" width="16"/>
      </clipPath>
    </defs>
  </svg>
</template>