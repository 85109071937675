<template>
  <v-container v-if="state.item" class="px-0 pt-0 position-relative"
               style="background-color:#F0F0F0; margin-bottom: 70px">
    <v-card class="card-container">
      <v-sheet v-if="!state.item.images || state.item.images.length == 0" color="#EEE" height="300"
               width="100%"></v-sheet>
      <v-img v-else :src="state.item.images[0].full" height="300" width="100%"></v-img>

      <v-card-title class="font-size-16-600 pb-3"><b>{{ state.item.name }}</b></v-card-title>

      <v-card-text class="font-size-12-400">
        <v-row>
          <v-col class="pt-2 pb-0" cols="4">
            <p class="font-grey-dark">Brand:</p>
          </v-col>
          <v-col class="pt-2 pb-0" cols="6">
            <p v-if="getBrandOrSupplierName"
               :class="{'secondary-background-button-color text-white brand': !state.item?.brand }"
               class="font-black"
            >
              {{ getBrandOrSupplierName }}
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pt-2 pb-0" cols="4">
            <p class="font-grey-dark">Product ID:</p>
          </v-col>
          <v-col class="pt-2 pb-0" cols="6">
            <p>{{ state.item.sku }}</p>
          </v-col>
        </v-row>

        <v-row class="align-center">
          <v-col class="pt-2 pb-0" cols="4">
            <p class="font-grey-dark">Price:</p>
          </v-col>
          <v-col class="pt-2 pb-0" cols="6">
            <p class="font-size-16-600">{{ state.item.price_display }}</p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="card-container px-0 mt-4 font-size-12-400">
      <div ref="descriptionContainer" :class="{'three-line-ellipsis': !showMore}">
        <div v-if="getDescription" style="padding: 0 20px">
          <p class="font-grey-dark">Description</p>

          <div class="line-height-15 mt-4" v-html="getDescription"></div>
        </div>

        <v-expansion-panels class="mt-4" variant="accordion">
          <v-expansion-panel v-for="itemInfo in state.PanelData" :key="itemInfo.title">
            <v-expansion-panel-title v-if="itemInfo.title !== 'Description'">
              <p class="font-grey-dark font-size-12-400">{{ itemInfo.title }}</p>
              <template v-slot:actions>
                <v-icon class="panel-arrow" icon="fa-chevron-down">
                </v-icon>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text v-if="itemInfo.title !== 'Description'">
              <div class="my-2 font-size-12-400 line-height-15" v-html="itemInfo.content"></div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <div v-if="isTextOverflowing" class="text-center pr-2">
        <v-btn
            :ripple="false"
            class="primary-underline-button text-capitalize font-size-12-600 pa-0"
            variant="plain"
            @click="toggleText"
        >
          {{ showMore ? 'Show Less' : 'Show More' }}
        </v-btn>
      </div>
    </v-card>

    <v-card class="button-fix d-flex ga-3 align-center justify-space-between">
      <ButtonBase
          custom-class="text-white primary-button"
          text="Add to cart"
          @click="addProduct"
      />

      <quantity-editor v-model="state.qty"/>
    </v-card>
  </v-container>
</template>

<script lang="ts" setup>
import {computed, reactive, ref, onMounted, nextTick, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {productService} from '@/api/services/product';
import {useSettingsStore} from '@/api/store/settings';
import {useCartStore} from '@/api/store/cart';
import QuantityEditor from '@/components/QuantityEditor.vue';
import ButtonBase from "@/components/custom/ButtonBase.vue";
import {useUserStore} from "@/api/store/user";
import {loadPanelData} from "@/views/ShopComponents/ItemComponents/PanelDataForSupplier";

const userStore = useUserStore();
const settingsStore = useSettingsStore();
const cartStore = useCartStore();

const route = useRoute();
const router = useRouter();

const state = reactive({
  item: null as any,
  qty: 1 as number,
  fav: false as boolean,
  PanelData: [] as any[],
});

const showMore = ref(false);
const isTextOverflowing = ref(false);
const descriptionContainer = ref<HTMLElement | null>(null);

const toggleText = () => {
  showMore.value = !showMore.value;
};

settingsStore.loading = true;
productService.getProduct(+route.params.id).then((response) => {
  state.item = response.data;
  router.currentRoute.value.meta.title = state.item.name;
  state.PanelData = loadPanelData(state.item)
}).catch((err) => {
  //router.push({ name: 'shop' });
}).finally(() => {
  settingsStore.loading = false;
});

const addProduct = () => {
  settingsStore.loading = true;
  cartStore.addItem(null, state.item.id, state.qty).then((res) => {
    console.log(res);
    router.push({name: 'shopping-cart'});
  }).catch((error) => {
    settingsStore.error = true;
    settingsStore.error_message = "This item may be out of stock or no longer available from the supplier."
  }).finally(() => {
    settingsStore.loading = false;
  });
};

const getBrandOrSupplierName = computed(() => {
  if (state.item.brand) {
    return state.item.brand.name;
  }

  return userStore.user?.clinic.name;
})

const getDescription = computed(() => {
  return state.item.description ?? '';
});

const checkTextOverflow = () => {
  if (descriptionContainer.value) {
    const element = descriptionContainer.value;
    isTextOverflowing.value = element.scrollHeight > element.clientHeight + 1;
  }
};

watch(() => state.item?.description, () => {
  nextTick(() => {
    checkTextOverflow();
  });
});

onMounted(() => {
  nextTick(() => {
    checkTextOverflow();
  })
});
</script>

<style lang="scss" scoped>
.card-container {
  box-shadow: 0.5px 0.5px 15px 0px #0000000D !important;
  border: 1px solid #F0F0F0;
  padding: 20px;
}

.button-fix {
  z-index: 1;
  position: fixed;
  bottom: 0;
  background: #FFFFFF;
  padding: 18px 12px 18px 23px;
  width: 100%;
  max-width: 480px;
  box-shadow: 0px -2px 15px 0px #0000000D;
}

:deep(.v-btn--variant-outlined) {
  width: 70% !important;

  @media only screen and (max-width: 375px) {
    width: 50% !important;
  }
}

:deep(.number-control-container) {
  width: 104px;
  height: 36px;
  border-radius: 20px;
}

:deep(.number-control) {
  line-height: 35px;
}

.brand {
  padding: 2px 6px;
  border-radius: 30px;
  text-align: center;
  width: fit-content;
}

.three-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-height-15 {
  line-height: 15px;
}

.panel-arrow {
  color: #1EBCD5;
}

:deep(.v-expansion-panel__shadow) {
  box-shadow: none;
}
</style>