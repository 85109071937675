<template>
  <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.16667 18.6014C3.75 18.6014 3.33333 18.4255 3 18.0736C2.66667 17.7217 2.5 17.2819 2.5 16.8421V4.52731C2.5 4.08749 2.66667 3.64768 3 3.29583C3.33333 2.94397 3.75 2.76805 4.16667 2.76805H7.66667C7.83333 2.24027 8.16667 1.80046 8.58333 1.4486C9 1.18471 9.5 1.00879 10 1.00879C10.5 1.00879 11 1.18471 11.4167 1.53657C11.8333 1.88842 12.1667 2.24027 12.3333 2.85601H15.8333C16.3333 2.85601 16.6667 3.03194 17 3.38379C17.3333 3.73564 17.5 3.99953 17.5 4.52731V16.8421C17.5 17.2819 17.3333 17.7217 17 18.0736C16.6667 18.4255 16.3333 18.6014 15.8333 18.6014H4.16667ZM4.16667 16.8421H15.8333V4.52731H4.16667V16.8421ZM10 3.8236C10.1667 3.8236 10.3333 3.73564 10.4167 3.64768C10.5 3.55971 10.5833 3.38379 10.5833 3.20786C10.5833 3.03194 10.5833 2.85601 10.4167 2.76805C10.25 2.68009 10.1667 2.50416 10 2.50416C9.83333 2.50416 9.66667 2.59212 9.58333 2.76805C9.5 2.94397 9.41667 3.03194 9.41667 3.20786C9.41667 3.38379 9.5 3.55971 9.58333 3.64768C9.66667 3.8236 9.83333 3.8236 10 3.8236Z" fill="currentColor"/>
    <path d="M14.1667 6.6377H5.83333V7.60529H14.1667V6.6377Z" fill="#1EBCD5"/>
    <path d="M7.83334 8.39753C8.47767 8.39753 9.00001 7.84618 9.00001 7.16605C9.00001 6.48592 8.47767 5.93457 7.83334 5.93457C7.18901 5.93457 6.66667 6.48592 6.66667 7.16605C6.66667 7.84618 7.18901 8.39753 7.83334 8.39753Z" fill="#1EBCD5"/>
    <path d="M14.1667 10.2451H5.83333V11.2127H14.1667V10.2451Z" fill="#1EBCD5"/>
    <path d="M11.75 11.9161C12.3943 11.9161 12.9167 11.3647 12.9167 10.6846C12.9167 10.0045 12.3943 9.45312 11.75 9.45312C11.1057 9.45312 10.5833 10.0045 10.5833 10.6846C10.5833 11.3647 11.1057 11.9161 11.75 11.9161Z" fill="#1EBCD5"/>
    <path d="M14.1667 13.8516H5.83333V14.8192H14.1667V13.8516Z" fill="#1EBCD5"/>
    <path d="M8.91667 15.5225C9.561 15.5225 10.0833 14.9712 10.0833 14.2911C10.0833 13.6109 9.561 13.0596 8.91667 13.0596C8.27233 13.0596 7.75 13.6109 7.75 14.2911C7.75 14.9712 8.27233 15.5225 8.91667 15.5225Z" fill="#1EBCD5"/>
  </svg>
</template>