<template>
  <v-sheet class="px-5 py-3" style="background-color: #F5F5F5;">
    <v-row class="px-3 mb-3">
      <v-col class="d-flex">
        <p class="p-text-lg mt-2">Insurance Details</p>
      </v-col>
      <v-col class="text-right">
        <v-btn v-if="!edit" color="#F5F5F5" variant="flat" @click="emit('edit')">
          <v-icon :color="color" icon="fa fa-pencil"></v-icon>
        </v-btn>
        <v-btn v-else color="#F5F5F5" variant="flat" @click="handleSave">
          <v-icon :color="buttonColor" icon="fas fa-save"></v-icon>
        </v-btn>
        <!--Close-->
        <v-btn v-if="edit" color="#F5F5F5" variant="flat" @click="handleClose">
          <v-icon :color="color" icon="fas fa-close"></v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-sheet class="px-2 pt-3 pb-2 b-round-20">
      <form-field v-model="state.provider" :editting="edit" label="Insurance Provider" variant="plain"/>
      <form-field v-model="state.policy_number" :editting="edit" label="Insurance Policy Number" variant="outlined"/>
      <form-field v-model="state.email" :editting="edit" label="Email" variant="outlined"/>
      <form-field v-model="state.address" :editting="edit" label="GP Address" variant="outlined"/>
      <form-field v-model="state.name" :editting="edit" label="Contact Name" variant="outlined"/>
      <v-sheet class="pt-6">
        <PhoneNumber
            v-model="state.phone"
            :disabled="!edit"
            label="GP Telephone"
        />
      </v-sheet>
      <p class="px-3 pb-3 font-size-12-400" style="color: red">{{ emailValidationMessage }}</p>
    </v-sheet>

    <v-sheet class="mt-3 pa-3 b-round-20 switch">
      <v-switch
          v-model="state.contact_permission"
          :class="{'no-pointer': !edit}"
          :color="color"
          :false-value="0"
          :true-value="1"
          class="switch font-size-10-400"
          label="Permission to Contact"
      />
    </v-sheet>
  </v-sheet>
</template>

<script lang="ts" setup>
import FormField from "@/components/Forms/FormField.vue";
import {defineProps, watch, reactive, defineEmits, shallowRef} from 'vue'
import PhoneNumber from "@/components/locations-selector/PhoneNumber.vue";

const defaultData = {
  provider: '-',
  policy_number: '-',
  email: '-',
  address: '-',
  name: '-',
  phone: '-',
  contact_permission: 0
};

const props = defineProps({
  data: {
    type: Object,
  },
  color: {
    type: String,
  },
  buttonColor: {
    type: String,
  },
  edit: {
    type: Boolean,
    default: false
  },
})

const emit = defineEmits(['save', 'edit', 'close'])

const state = reactive({
  ...props.data,
});

const emailValidationMessage = shallowRef('');

const handleSave = () => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (emailRegex.test(state.email)) {
    emit('save', state);
  } else {
    emailValidationMessage.value = 'Please enter a valid email address.';
  }
};

const handleClose = () => {
  emailValidationMessage.value = ''
  emit('close')
}

// Watch for changes in props.data to update state
watch(() => props.data, (newData) => {
  Object.keys(defaultData).forEach(key => {
    state[key] = newData[key] !== undefined && newData[key] !== null ? newData[key] : defaultData[key];
  });
});
</script>

<style lang="scss" scoped>
.switch {
  display: flex;
  margin: 4px;
}

.no-pointer {
  pointer-events: none;
}
</style>