const isEmptyObject = (obj: any) => {
    return obj === null || (Object.keys(obj).length === 0 && obj.constructor === Object);
};

const getInitials = (firstName: string, lastName: string) => {
    return {
        initials: (firstName[0] + (lastName[0] || '')).toUpperCase(),
        name: `${firstName} ${lastName}`
    };
};

const firstLetterUppercase = (str: string) => {
    if(!str) return '';
    
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export {isEmptyObject, getInitials, firstLetterUppercase}