<template>
    <v-card>
        <v-tabs v-model="state.tab">
            <v-tab value="service">Service</v-tab>
            <v-tab value="package">Package</v-tab>
        </v-tabs>
        <v-card-text>
      <v-window v-model="state.tab">
        <v-window-item value="service">
          <service-selector />
        </v-window-item>

        <v-window-item value="package">
          <package-selector />
        </v-window-item>
      </v-window>
    </v-card-text>
    </v-card>
</template>

<script lang="ts" setup>
import { reactive } from 'vue';
import PackageSelector from './BookingComponents/PackageSelector.vue';
import ServiceSelector from './BookingComponents/ServiceSelector.vue';

const state = reactive({
    tab: 'service'
});
</script>