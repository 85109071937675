<template>
  <svg fill="none" height="27" viewBox="0 0 30 27" width="30" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M29.1213 6.51512C28.8595 6.09158 28.4847 5.73863 28.0334 5.49069C27.582 5.24275 27.0696 5.10829 26.546 5.10041H7.15546L6.27683 1.90318C6.18805 1.59455 5.99 1.32307 5.71492 1.13297C5.43984 0.942878 5.10394 0.845358 4.76194 0.856298H1.73217C1.3304 0.856298 0.945081 1.00535 0.660985 1.27066C0.376889 1.53596 0.217285 1.8958 0.217285 2.271C0.217285 2.64621 0.376889 3.00604 0.660985 3.27135C0.945081 3.53666 1.3304 3.68571 1.73217 3.68571H3.61063L7.79171 18.2006C7.88048 18.5092 8.07854 18.7807 8.35362 18.9708C8.6287 19.1609 8.96459 19.2584 9.3066 19.2475H22.9406C23.2203 19.2467 23.4944 19.1736 23.7323 19.0362C23.9703 18.8989 24.1629 18.7027 24.2888 18.4694L29.2576 9.18891C29.473 8.76735 29.5733 8.30255 29.5495 7.836C29.5257 7.36945 29.3786 6.91566 29.1213 6.51512Z"
        fill="currentColor"/>
    <path
        d="M8.54918 26.3203C9.80415 26.3203 10.8215 25.3702 10.8215 24.1982C10.8215 23.0262 9.80415 22.0762 8.54918 22.0762C7.29421 22.0762 6.27686 23.0262 6.27686 24.1982C6.27686 25.3702 7.29421 26.3203 8.54918 26.3203Z"
        fill="currentColor"/>
    <path
        d="M23.6979 26.3203C24.9528 26.3203 25.9702 25.3702 25.9702 24.1982C25.9702 23.0262 24.9528 22.0762 23.6979 22.0762C22.4429 22.0762 21.4255 23.0262 21.4255 24.1982C21.4255 25.3702 22.4429 26.3203 23.6979 26.3203Z"
        fill="currentColor"/>
  </svg>
</template>
