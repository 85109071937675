import {HttpService} from "./http";

class ProductService extends HttpService {
    public constructor() {
        super("/client-app/shop/products");
    }

    public index(supplier: number | null = null, filters: object | null = null): Promise<any> {
        let filter = filters;
        
        if (supplier) {
            filter = {
                supplier_id: supplier,
                ...filters,
            };
        }

        return this.http
            .get(`${this.route}/`, {
                params: filter
            })
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public getProduct(id: number): Promise<any> {
        return this.http
            .get(this.route + "/" + id)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public getFeaturedProducts(): Promise<any> {
        return this.http
            .get(this.route + "/featured")
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }
}

export const productService: ProductService = new ProductService();
