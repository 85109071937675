<template>
  <v-container>
    <v-sheet class="text-center" color="transparent">
      <v-sheet class="text-center" color="transparent">
        <v-img height="100" src="/img/account_circle.png"/>
        <p class="p-text-lg mt-2">{{ userStore.user.first_name + ' ' + userStore.user.last_name }}</p>
      </v-sheet>
      <v-sheet class="d-flex justify-center pa-4 mt-5" color="transparent">
        <v-sheet :color="settingsStore.settings.buttonColor2" class="pa-3 mx-4 b-round-20"
                 elevation="3" height="100" width="120" @click="router.push({ name: 'client-details' })">
          <v-icon class="my-2 text-white" icon="fa fa-heartbeat" size="40"></v-icon>
          <p class="p-text-md text-white">Health Plan</p>
        </v-sheet>
        <v-sheet :color="settingsStore.settings.buttonColor2" class="pa-3 mx-4 b-round-20"
                 elevation="3" height="100" width="120" @click="router.push({ name: 'client-details' })">
          <v-icon class="my-2 text-white" icon="fa fa-comment" size="40"></v-icon>
          <p class="p-text-md text-white">Messages</p>
        </v-sheet>
      </v-sheet>
      <v-sheet class="d-flex justify-center pa-4" color="transparent">
        <v-sheet :style="buttonStyle" class="pa-3 mx-4  b-round-20"
                 elevation="3" height="100" width="120" @click="router.push({ name: 'client-details' })">
          <v-icon class="my-2" icon="fa fa-address-card" size="40"></v-icon>
          <p class="p-text-md">Contact Details</p>
        </v-sheet>
        <v-sheet :style="buttonStyle" class="pa-3 mx-4  b-round-20"
                 elevation="3" height="100" width="120" @click="router.push({ name: 'medical-details' })">
          <v-icon class="my-2" icon="fa fa-id-card" size="40"></v-icon>
          <p class="p-text-md">Medical Details</p>
        </v-sheet>
      </v-sheet>
      <v-sheet class="d-flex justify-center pa-4" color="transparent">
        <v-sheet :style="buttonStyle" class="pa-3 mx-4 b-round-20"
                 elevation="3" height="100" width="120" @click="router.push({ name: 'medical-alerts' })">
          <v-icon class="my-2" icon="fa fa-warning" size="40"></v-icon>
          <p class="p-text-md">Medical Alerts</p>
        </v-sheet>
        <v-sheet :style="buttonStyle" class="pa-3 mx-4  b-round-20"
                 elevation="3" height="100" width="120" @click="router.push({ name: 'client-details' })">
          <v-icon class="my-2" icon="fa fa-heart" size="40"></v-icon>
          <p class="p-text-md">Health Goals</p>
        </v-sheet>
      </v-sheet>
      <v-sheet class="d-flex justify-center pa-4" color="transparent">
        <v-sheet :style="buttonStyle" class="pa-3 mx-4 b-round-20"
                 elevation="3" height="100" width="120" @click="router.push('associated-accounts')">
          <v-icon class="my-2" icon="fa fa-sign-out" size="40"></v-icon>
          <p class="p-text-md">Switch Account</p>
        </v-sheet>
        <v-sheet :style="buttonStyle" class="pa-3 mx-4 b-round-20"
                 elevation="3" height="100" width="120" @click="router.push('logout')">
          <v-icon class="my-2" icon="fa fa-sign-out" size="40"></v-icon>
          <p class="p-text-md">Logout</p>
        </v-sheet>
      </v-sheet>

    </v-sheet>

    <!--v-row class="mt-6 text-center">
        <v-col cols="3"></v-col>
        <v-col class="text-center" cols="6"><v-btn block label="Log out" @click="logout" class="b-round-20 text-white"
                :color="settingsStore.settings.primaryColor">Log Out</v-btn></v-col>
    </v-row-->
  </v-container>
</template>

<script lang="ts" setup>
import {useRouter} from "vue-router"
import {useSettingsStore} from "@/api/store/settings"
import {getCurrentAppVersion, getAvailableAppVersion, performImmediateUpdate} from "@/plugins/updater";
import {reactive, computed} from "vue";
import {useUserStore} from "@/api/store/user";
import AddressManager from "./ClientComponents/AddressManager.vue";
import FormField from "@/components/Forms/FormField.vue";

const settingsStore = useSettingsStore();
const userStore = useUserStore();

const router = useRouter();

const logout = () => {
  userStore.logout();
  router.push({name: "login"});
}

const state = reactive({
  appVersion: "",
  appAvailableVersion: "",
  profileForm: {
    edit: false,
  }
});

getCurrentAppVersion().then((version) => {
  state.appVersion = version;
}).catch((err) => {
  state.appVersion = "Unavailable";
  console.log(err);
});

getAvailableAppVersion().then((version) => {
  state.appAvailableVersion = version;
}).catch((err) => {
  state.appAvailableVersion = "Unavailable";
  console.log(err);
});

const openUpdate = () => {
  performImmediateUpdate();
}

const buttonStyle = computed(() => {
  return "background: linear-gradient(180deg, rgba(250, 250, 250, 0.5) 5%, rgba(255, 255, 255, 1) 100%); color: " + settingsStore.settings.primaryColor + ";";
});
</script>