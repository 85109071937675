<template>
  <v-container class="px-0" style="min-height:100%">
    <section>
      <v-card-title class="font-size-18-600">
        My Clinic Categories
      </v-card-title>

      <div class="d-flex flex-wrap font-size-12-600">
        <RoundButton
            icon="service-icon"
            label="Services"
            @click="router.push({ name: 'clinic-shop', params: { category: 'services'} })"
        />
        <RoundButton
            icon="package-icon"
            label="Packages"
            @click="router.push({ name: 'clinic-shop', params: { category: 'packages'} })"
        />
        <RoundButton
            icon="program-icon"
            label="Programmes"
            @click="router.push({ name: 'clinic-shop', params: { category: 'programmes'} })"
        />
        <RoundButton
            icon="product-icon"
            label="Products"
            @click="router.push({ name: 'clinic-shop', params: { category: 'products'} })"
        />
      </div>
    </section>

    <section class="mt-10">
      <v-card-title class="font-size-18-600">
        Shop By Supplier
      </v-card-title>

      <div class="d-flex mt-4 justify-center ga-6">
        <v-sheet class="logo-container-content nd-logo-border" @click="router.push({name: 'natural-dispensary'})">
          <div :style="{backgroundImage: `url('/img/tnd_hands_logo.png'`}" class="logo-container-image"></div>
        </v-sheet>

        <v-sheet class="logo-container-content clinic-logo-border secondary-background-button-color"
                 @click="router.push({ name: 'clinic-shop' })">
          <div :style="{backgroundImage: `url(${getClinicAvatar}`}" class="logo-container-image"></div>
        </v-sheet>
      </div>
    </section>

    <section class="mt-10">
      <v-card-title class="d-flex align-center justify-space-between flex-wrap font-size-18-600">
        <div class="d-flex align-center ga-1 font-size-18-600">
          <p class="d-flex">
            <Icon icon="history-icon"/>
          </p>
          Most Recent Orders
        </div>

        <v-btn
            :ripple="false"
            class="primary-underline-button text-capitalize font-size-12-600 pa-0"
            variant="plain"
            @click="router.push({ name: 'orders' })"
        >
          Show All
        </v-btn>
      </v-card-title>

      <CardComponent v-if="state.items.length === 0" class="font line-height-18 pa-2">
        <div class="round-primary-button primary-background-button-color">
          <Icon icon="basket-icon"></Icon>
        </div>
        <div class="font-size-14-400 line-height-18 empty-message-container">
          <p class="empty-message-title">Orders</p>
          <p>You have no confirmed</p>
          <p>orders</p>
        </div>
      </CardComponent>

      <OrderCard
          v-for="order in state.items"
          v-else
          :key="order.id"
          :button-color="settingsStore.settings.buttonColor"
          :order="order"
          :orders-background-color="setAppColor"
      />
    </section>
  </v-container>
</template>

<script lang="ts" setup>
import router from '@/router';
import {reactive, computed, onMounted} from 'vue';
import {useSettingsStore} from '@/api/store/settings';
import {useUserStore} from '@/api/store/user';
import RoundButton from "@/components/custom/RoundButton.vue";
import Icon from "@/components/Icons/Icon.vue";
import {ordersService} from "@/api/services/order";
import OrderCard from "@/views/OrdersComponents/OrderDetails/OrderCard.vue";
import CardComponent from "@/components/custom/CardComponent.vue";

const settingsStore = useSettingsStore();
const userStore = useUserStore();

const setAppColor = settingsStore.settings.buttonColor2

const state = reactive({
  items: [] as any[],
});

const getOrders = () => {
  ordersService.index({
    sorting: "created_at,desc",
    per_page: 2,
  }).then((response) => {
    state.items = [...response.data]
  });
}

const getClinicAvatar = computed(() => {
  return userStore?.user?.file.file_path ?? ''
})

onMounted(getOrders);
</script>

<style lang="scss" scoped>
.logo-container-content {
  border-radius: 20%;
  padding: 20px;
  width: 150px;
  height: 135px;

  .logo-container-image {
    border-radius: 60%;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.nd-logo-border {
  border: 1px solid #304978;
  background: #3A5485;
}

.empty-message-container {
  margin-left: 14px;

  .empty-message-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .font {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
}

.line-height-18 {
  line-height: 18px;
}

.round-primary-button {
  width: 68px;
  height: 62px;
}
</style>