<template>
  <v-dialog
      v-model="dialog"
      max-width="400"
      min-width="30%"
      persistent
  >
    <v-card class="font-grey-dark">
      <v-card-title class="d-flex justify-space-between align-center">
        Details
        <div @click="dialog = false">
          <Icon color="grey_light" icon="cross-icon"></Icon>
        </div>
      </v-card-title>

      <v-divider></v-divider>

      <div class="overflow-auto" style="min-height: 295px;">

        <v-card class="font-grey-dark">
          <template v-slot:text>
            <div>
              <div class="comment-section">
                <h3 class="comment-title">Consultation Comment:</h3>
                <p class="comment-text">{{ event.model.consultation.comment }}</p>
              </div>

              <v-divider v-if="isVideo" class="my-4"></v-divider>

              <div v-if="isVideo" class="video-section">
                <h3 class="video-title">Video Link</h3>
                <a
                    :href="event.model.link"
                    class="video-link"
                    target="_blank"
                >
                  {{ event.model.link }}
                </a>
              </div>
            </div>
          </template>
        </v-card>
      </div>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="b-round-20 outline-btn cancel"
            elevation="0"
            height="32px"
            x-small
            @click="dialog = false"
        >
          Cancel
        </v-btn>

        <div v-if="isVideo">
          <v-btn
              class="b-round-20 outline-btn primary-button ml-3"
              elevation="0"
              height="32px"
              variant="outlined"
              width="82px"
              x-small
              @click="onJoin(event?.model)"
          >
            <span class="mr-1">Join</span>
            <Icon icon="video-icon-lg"></Icon>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import {defineEmits, defineProps, ref, watch, computed} from 'vue';
import Icon from "@/components/Icons/Icon.vue";

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: [String, Number],

  event: {
    type: Object,
    default: () => {
      return {};
    }
  }
})

const dialog = ref(props.modelValue);

watch(() => props.modelValue, (newValue) => {
  dialog.value = newValue;
});

watch(dialog, (newValue) => {
  emit('update:modelValue', newValue);
});

const onJoin = (event: any) => {
  if (!event.link) return null;

  window.location.href = event.link;
}

const isVideo = computed(() => {
  return props.event?.model?.assignee && props.event?.model?.method === 'Video'
})
</script>

<style lang="scss" scoped>
.cancel {
  color: #B0B3B7 !important;
  border: 1px solid #B0B3B7;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
  linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
}

.panel-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #838383;
}

.panel-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

:deep(.v-card--variant-elevated) {
  box-shadow: none;
}

.comment-section {
  margin-bottom: 16px;
}

.comment-title {
  font-size: 15px;
  margin-bottom: 8px;
  color: #333;
}

.video-section {
  margin-top: 16px;
}

.video-title {
  font-size: 15px;
  margin-bottom: 8px;
  color: #333;
}

.video-link {
  font-size: 16px;
  color: #1e88e5;
  text-decoration: none;
  word-break: break-all;
}
</style>
