<template>
  <div class="add-new-button-container">
    <div class="position-relative">
      <ButtonBase
          v-bind="props"
          width="100%"
          @click="emit('click')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import ButtonBase from "@/components/custom/ButtonBase.vue";
import {defineEmits, defineProps} from 'vue'
import buttonProps from "@/components/custom/ButtonFilterSelect/ButtonProps";

const emit = defineEmits(['click'])

const props = defineProps({...buttonProps})
</script>


<style scoped lang="scss">
.add-new-button-container {
  position: fixed;
  bottom: 0;
  background: #FFFFFF;
  padding: 18px 23px;
  width: 100%;
  max-width: 480px;
  box-shadow: 0px -2px 15px 0px #0000000D;
}
</style>