<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.49022 18.9486L9.84377 19.3022L10.1973 18.9486L21.3171 7.82881L21.6707 7.47526L21.3171 7.12171L20.1973 6.00192L19.8438 5.64836L19.4902 6.00192L9.84377 15.6484L5.48378 11.2884L5.13023 10.9348L4.77668 11.2884L3.65688 12.4082L3.30333 12.7617L3.65688 13.1153L9.49022 18.9486Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
</template>
