import { HttpService } from "./http";

class AppService extends HttpService {
  public constructor() {
    super("/client-app/app");
  }


  public fetchDisplay(): any {
    return this.http
      .get(this.route + "/display")
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }

  public fetchDisplayById(id: string): any {
    const url = this.route.replace('/client-app/app', '/web-components/app-preview');
    return this.http
      .get(url + "/display/" + id)
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this));
  }
}

export const appService: AppService = new AppService();
