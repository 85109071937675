<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9972 17.5664L11.518 12.0872C11.1014 12.4483 10.6153 12.7296 10.0597 12.931C9.50416 13.1324 8.91388 13.2331 8.28888 13.2331C6.78888 13.2331 5.51805 12.7122 4.47638 11.6706C3.43471 10.6289 2.91388 9.37196 2.91388 7.89974C2.91388 6.42752 3.43471 5.17057 4.47638 4.12891C5.51805 3.08724 6.78193 2.56641 8.26805 2.56641C9.74027 2.56641 10.9937 3.08724 12.0285 4.12891C13.0632 5.17057 13.5805 6.42752 13.5805 7.89974C13.5805 8.49696 13.4833 9.07335 13.2889 9.62891C13.0944 10.1845 12.8028 10.7053 12.4139 11.1914L17.9139 16.6497L16.9972 17.5664ZM8.26805 11.9831C9.39305 11.9831 10.3514 11.5838 11.143 10.7852C11.9347 9.98654 12.3305 9.02474 12.3305 7.89974C12.3305 6.77474 11.9347 5.81293 11.143 5.01432C10.3514 4.21571 9.39305 3.81641 8.26805 3.81641C7.12916 3.81641 6.16041 4.21571 5.3618 5.01432C4.56318 5.81293 4.16388 6.77474 4.16388 7.89974C4.16388 9.02474 4.56318 9.98654 5.3618 10.7852C6.16041 11.5838 7.12916 11.9831 8.26805 11.9831Z"
      fill="currentColor"
    />
  </svg>
</template>
