export const TOKEN_KEY = "TOKEN__";
export const USER_INFO_KEY = "USER__INFO__";
export const LS_USER_KEY = "lsUser";

interface BasicStore {
    [TOKEN_KEY]: string | number | null | undefined;
    [USER_INFO_KEY]: string | number | null | undefined;
    [LS_USER_KEY]: string | null | undefined;
}

export type BasicKeys = keyof BasicStore;

export function getToken(): string | null {
    const value = getAuthCache(TOKEN_KEY);

    if (value == null || value == "") return "";

    return value;
}

export function setToken(value: string | null) {

    setAuthCache(TOKEN_KEY, value);
}

export function getUser() {
    const value = getAuthCache(USER_INFO_KEY);
    if (value == null || value == "") return "";

    const user = JSON.parse(value);

    return user;
}

export function setUser(value: any) {
    const data = JSON.stringify(value);
    const authData = JSON.parse(<string>getAuthCache(LS_USER_KEY))

    if (value && 'updated_at' in value &&
        authData.user && 'updated_at' in authData.user
        && value.updated_at !== authData.user.updated_at
    ) {
        authData.user = value
        setAuthCache(LS_USER_KEY, JSON.stringify(authData));
    }

    setAuthCache(USER_INFO_KEY, data);
}

export function getAuthCache<T>(key: BasicKeys) {
    const value = localStorage.getItem(key);
    return value;
}

export function setAuthCache(key: BasicKeys, value: any) {
    localStorage.setItem(key, value);
}

export function clearAuthCache() {
    localStorage.clear();
}
