<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7169 1.99609L9.13873 7.57422L14.7169 13.1523L13.3106 14.5586L7.73248 8.98047L2.15436 14.5586L0.748105 13.1523L6.32623 7.57422L0.748105 1.99609L2.15436 0.589844L7.73248 6.16797L13.3106 0.589844L14.7169 1.99609Z"
      fill="currentColor"
    />
  </svg>
</template>
