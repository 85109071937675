import {HttpService} from "./http";

class FilesFeedService extends HttpService {
    public constructor() {
        super("/client-app/client/files/feed");
    }

    public index(): Promise<any> {
        return this.http
            .get(this.route)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

}

export const filesFeedService: FilesFeedService = new FilesFeedService();
