<template>
  <v-container v-show="formState && !settingsStore.loading" class="px-0 pt-0 pb-5">
    <div class="text-right px-4 pt-3" @click="router.push({ name: 'medical-alerts' })">
      <Icon icon="cross-icon" color="primaryColor"/>
    </div>

    <section class="px-10">
      <CreatedBy :icon="setIcon"/>

      <div>
        <v-card-title class="px-0 py-0 pt-3 pb-4 font-size-18-600 font-black">
          <span>{{ formState.name }}</span>
        </v-card-title>

        <section class="pb-10">
          <p>{{ formState.description }}</p>
        </section>

        <FlexContainer title="Type">
          <div class="font-size-12-600 font-black">
            {{ formState.type }}
          </div>
        </FlexContainer>

        <v-divider></v-divider>

        <FlexContainer title="Status">
          <div class="font-size-12-600 font-black">
            {{ formState.status }}
          </div>
        </FlexContainer>

        <v-divider v-if="isMethodDate"></v-divider>

        <FlexContainer title="Date From" v-if="isMethodDate">
          <div class="font-size-12-600 font-black">
            {{ formattedDate(formState.date_from) }}
          </div>
        </FlexContainer>

        <v-divider v-if="isMethodDate"></v-divider>

        <FlexContainer title="Date To" v-if="isMethodDate">
          <div class="font-size-12-600 font-black">
            {{
              formState.date_to && formState.status !== 'Ongoing' ? formattedDate(formState.date_to) : formattedDate(moment())
            }}
          </div>
        </FlexContainer>

        <v-divider></v-divider>

        <FlexContainer title="Age">
          <div class="font-size-12-600 font-black">
            {{ getAgeAndDuration(getDob, formState).age.value }} old
          </div>
        </FlexContainer>

        <v-divider></v-divider>

        <FlexContainer title="Duration">
          <div class="font-size-12-600 font-black">
            {{ getAgeAndDuration(getDob, formState).duration.value }}
          </div>
        </FlexContainer>

        <v-divider></v-divider>
      </div>

    </section>
  </v-container>
</template>

<script setup lang="ts">
import Icon from "@/components/Icons/Icon.vue";
import CreatedBy from "@/components/custom/CreatedBy.vue";
import {computed, onMounted, ref} from 'vue'
import FlexContainer from "@/views/ClientComponents/MedicalAlerts/Partials/FlexContainer.vue";
import {medicalAlertsService} from "@/api/services/medicalAlerts";
import {useRoute, useRouter} from "vue-router";
import {useSettingsStore} from "@/api/store/settings";
import getAgeAndDuration from "@/views/ClientComponents/MedicalAlerts/GetAgeAndDuration";
import moment from "moment/moment";

const settingsStore = useSettingsStore();
const router = useRouter();
const route = useRoute();

const formState = ref({
  name: null,
  description: null,
  type: null,
  created_by: null,
  date_from: null,
  date_to: null,
  method: null,
  status: null
})

const setIcon = computed(() => {
  return formState.value.created_by === 'client' ? 'client-icon' : 'blue-practitioner-icon'
})

const getDob = computed(() => {
  return route.query.dob ? route.query.dob : null
})

const isMethodDate = computed(() => {
  return formState.value.method === 'date'
})

const formattedDate = (value: string | number, format = 'DD MMM YYYY') => {
  return moment(value).format(format)
}

const fetchMedicalAlert = () => {
  settingsStore.loading = true;
  medicalAlertsService.show(route.params.id).then(({data}) => {
    formState.value = data
    settingsStore.loading = false;
  })
}

onMounted(fetchMedicalAlert)
</script>