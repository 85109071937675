<template>
  <svg
    width="9"
    height="15"
    viewBox="0 0 9 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="1.73227" cy="2.27734" rx="1.47104" ry="1.5" fill="currentColor" />
    <ellipse cx="1.73227" cy="7.55469" rx="1.47104" ry="1.5" fill="currentColor" />
    <ellipse cx="1.73227" cy="12.8359" rx="1.47104" ry="1.5" fill="currentColor" />
    <ellipse cx="7.20469" cy="2.27734" rx="1.47104" ry="1.5" fill="currentColor" />
    <ellipse cx="7.20469" cy="7.55469" rx="1.47104" ry="1.5" fill="currentColor" />
    <ellipse cx="7.20469" cy="12.8359" rx="1.47104" ry="1.5" fill="currentColor" />
  </svg>
</template>
