<template>
  <div class="page-header align-items-start min-vh-100">
    <span class="mask bg-custom-secondary opacity-6"></span>
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="
                  bg-custom-primary
                  shadow-success
                  border-radius-lg
                  py-3
                  pe-1
                "
              >
                <div class="row"></div>
                <h4 class="text-white text-center mt-2 mb-0">
                  Practitioner Portal Login
                </h4>
              </div>
            </div>
            <div class="card-body">
              <div class="p-1">
                <div class="text-center">
                  Processing login, please wait.
                </div>
                <div class="text-center my-2">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { loginService } from "@/api/services/login";
import { computed, ComputedRef} from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { setToken, setUser } from "@/api/services/auth"

import { useSettingsStore } from '@/api/store/settings';
import { useUserStore } from '@/api/store/user';
import { appService } from '@/api/services/app'

const settingsStore = useSettingsStore();
const userStore = useUserStore();

const router = useRouter();
const route = useRoute();

console.log(route.params);
const token: ComputedRef<string> = computed((): string => route.params.token as string);

const getSettings = async () => {
appService.fetchDisplay().then((result: any) => {
  const data = JSON.parse(result.settings);
  settingsStore.settings = data;
  settingsStore.set = true;
  settingsStore.loading = false;
})
  .catch((error: any) => {
    settingsStore.loading = false;
    settingsStore.set = true;
  });
}

function processLoginToken() {
  return loginService.processLoginToken(token.value).then((result) => {
      if (result && result.success == true) {
      setToken(result.token.access_token);
      setUser(result.user);

      userStore.logged_in = true;
      userStore.user = result.user;

      getSettings().then(() => {
        router.replace({ path: '/' });
      });
    }
  })
}

processLoginToken();
</script>
