<template>
  <v-container v-if="state.service" class="ma-0 pa-0">
    <v-sheet class="pt-2 px-2" height="100" width="100%">
      <v-row class="mt-4 px-2">
        <v-col class="text-center pa-1">
          <p class="p-text-md pa-1 mt-2"><strong>{{ state.service.name }}</strong></p>
          <p class="p-text-sm pa-1">{{ state.service.booking_options.duration }} mins - {{
              state.service.price_display
            }}</p>
        </v-col>
        <v-col v-if="state.booking.practitioner" class="text-center pa-1">
          <v-avatar color="#f5f5f5" size="x-large">
            <v-icon icon="fa fa-user-circle"></v-icon>
          </v-avatar>
        </v-col>
        <v-col v-if="state.booking.method && state.booking.date" class="text-center pa-1">
          <p class="p-text-md pa-1 mt-2"><strong>{{ state.booking.method }}</strong></p>
          <p class="p-text-sm pa-1">{{ moment(state.booking.date).format('DD/MM/YY') }} {{ state.booking.time }}</p>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet class="pa-3" style="background-color: #f5f5f5;" width="100%">
      <button-tab v-model="state.tab" :colour="settingsStore.settings.primaryColor"
                  :dark="true" :tabs="[{ name: 'Practitioner' }, { name: 'Date/Method' }, { name: 'Book' }]"></button-tab>
      <v-window v-model="state.tab.name">
        <v-window-item value="Practitioner">
          <v-sheet v-if="state.service.creator" class="my-3" height="100" width="100%">
            <v-row class="pa-4 text-center">
              <v-col cols="3" @click="() => router.push({name: 'practitioner-bio', params:{ 
                              serviceId: route.params.id,
                              practitionerId: state.booking.practitioner?.id
                            }})">
                <v-avatar color="#f5f5f5" size="x-large">
                  <v-icon icon="fa fa-user-circle"></v-icon>
                </v-avatar>
              </v-col>
              <v-col cols="5">
                <p class="pa-3 p-text-sm mt-2"><strong>{{ state.service.creator.name }}</strong></p>
              </v-col>
              <v-col cols="4">
                <v-btn :color="settingsStore.settings.buttonColor2" class="mt-2" variant="outlined"
                       @click="state.booking.practitioner = state.service.creator">
                  Select
                </v-btn>
              </v-col>
            </v-row>
          </v-sheet>
        </v-window-item>
        <v-window-item value="Date/Method">
          <booking-method v-model="state.booking" :color="settingsStore.settings.secondaryColor"></booking-method>
          <v-spacer class="mt-2"></v-spacer>
          <v-date-picker v-model="state.booking.date" :color="settingsStore.settings.secondaryColor" :hide-actions="true"
                         :min="state.currentDate" style="width:100%"></v-date-picker>
          <time-picker
              v-if="state.availableTimes && state.availableTimes.length > 0"
              v-model="state.booking.time"
              :availableTimeslots="state.availableTimes"
              :highlightColor="settingsStore.settings.secondaryColor"
              class="mt-2"/>
        </v-window-item>
        <v-window-item value="Book">
          <!--v-sheet class="pa-10">
              <v-row>
                  <v-col>
                      <v-text-field label="First Name" variant="outlined" density="compact" class="b-round-20" />
                  </v-col>
                  <v-col>
                      <v-text-field label="Last Name" variant="outlined" density="compact" />
                  </v-col>
              </v-row>
              <v-row>
                  <v-col>
                      <v-text-field label="Email" variant="outlined" density="compact" />
                  </v-col>
                  <v-col>
                      <v-text-field label="Phone" variant="outlined" density="compact" />
                  </v-col>
              </v-row>
          </v-sheet-->
          <v-sheet class="pa-10 text-center">
            <p class="p-text-md">Terms and Conditions</p>
            <p class="p-text-sm">None Available</p>
          </v-sheet>
          <v-sheet class="text-center" height="200">
            <v-btn :disabled="!isBookingReady || state.creatingBooking" :loading="state.creatingBooking" class="text-white add-to-cart-btn mx-auto d-inline b-round-20"
                   variant="outlined" width="200"
                   @click="createBooking">
              <span>Complete Booking</span>
            </v-btn>
          </v-sheet>
        </v-window-item>
      </v-window>
    </v-sheet>
  </v-container>
</template>

<script lang="ts" setup>
import {reactive, computed, watch, inject} from 'vue';
import {useRouter, useRoute, ErrorTypes} from 'vue-router';
import {servicesService} from '@/api/services/service';
import {consultationService} from '@/api/services/consultation';
import {useSettingsStore} from '@/api/store/settings';
import {useUserStore} from '@/api/store/user';
import {useDate} from 'vuetify'
import BookingMethod from '@/components/custom/BookingMethod.vue';
import ButtonTab from '@/components/custom/ButtonTab.vue';
import TimePicker from '@/components/custom/TimePicker.vue';

import moment from 'moment';
import 'moment-timezone';

const router = useRouter();
const route = useRoute();

const settingsStore = useSettingsStore();
const userStore = useUserStore();

const snackbar: any = inject('snackbar');

const date = useDate()

const state = reactive({
  service: null as any | null,
  tab: {name: "Practitioner"} as any,
  booking: {
    method: "in-person" as string,
    date: null as any | null,
    time: null as any | null,
    practitioner: null as any | null,
    termsAgreed: true as boolean,
  },
  currentDate: new Date(),
  availableTimes: [] as any[],
  creatingBooking: false as boolean,
});

const loadService = async () => {
  state.service = await servicesService.getService(route.params.id);
  console.log("state.service", state.service);
  if (route.params.practitionerId) {
    state.booking.practitioner = state.service.creator;
  }
};

loadService();

const loadAvailableTimes = async () => {

  const date = state.booking.date;
  const serviceId = state.service.id;
  const method = state.booking.method;

  if (!date || !serviceId || !method) {
    snackbar.errorSnackbar("Please select a date, service and method");
    return;
  }

  servicesService.getServiceAvailability(serviceId, {
    date: moment(date).format('DD/MM/YY'),
    method: state.booking.method,
  }).then((response) => {
    state.availableTimes = response;
  }).catch((err) => {
    console.log(err);
  });
};

watch(() => state.booking.date, () => {
  loadAvailableTimes();
});

watch(() => state.booking.method, () => {
  if (state.booking.date) {
    loadAvailableTimes();
  }
});

// computed is booking ready
const isBookingReady = computed(() => {
  if (state.booking.method && state.booking.date && state.booking.time && state.booking.practitioner && state.booking.termsAgreed) {
    return true;
  }
  return false;
});

const createBooking = () => {

  if (state.creatingBooking) {
    return;
  }

  state.creatingBooking = true;

  const date = state.booking.date;
  const time = state.booking.time;

  const formattedDate = moment(date).format('DD/MM/YY');

  const datetimeString = `${formattedDate} ${time}`;

  const timezone = userStore.user.clinic.timezone || 'Europe/London';

  console.log("timezone", timezone)

  // Parse the combined date and time string in the user's local timezone
  const localDatetime = moment.tz(datetimeString, 'DD/MM/YY HH:mm', timezone);

  console.log("localDatetime", localDatetime);

  // Convert the local datetime to UTC
  const utcDatetime = localDatetime.utc();

  console.log("utcDatetime", utcDatetime);

  // Format the datetime to 'YYYY-MM-DD HH:mm:ss' format in UTC
  const formattedDatetime = utcDatetime.format('YYYY-MM-DD HH:mm:ss');

  console.log("formattedDatetime", formattedDatetime);

  consultationService.create({
    service_id: state.service.id,
    practitioner_id: state.booking.practitioner.id,
    method: state.booking.method,
    start_date: formattedDatetime,
  }).then((response) => {
    router.push({name: 'order-confirmation', params: {id: response.order.id}})
  }).catch((err) => {
    console.log(err);
  }).finally(() => {
    state.creatingBooking = false;
  });
}
</script>