<template>
  <v-select
      :items="items"
      v-model="setSelect"
      :variant="variant"
      :item-title="itemTitle"
      :item-value="itemValue"
      :disabled="disabled"
      :readonly="readonly"
      :return-object="returnObject"
      single-line
      :menuIcon="ArrowRightIcon"
  />
</template>

<script setup lang="ts">
import {defineEmits, defineProps, PropType, ref, watch} from 'vue';
import ArrowRightIcon from "@/components/Icons/components/ArrowRightIcon.vue";

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

type Variant = 'solo' | 'underlined' | 'outlined' | 'solo-filled' | 'solo-inverted' | undefined;

const props = defineProps({
  modelValue: {
    type: [Object, String] as PropType<object | string>,
  },

  items: {
    type: Array as PropType<any[]>,
  },

  variant: {
    type: String as PropType<Variant>,
    default: 'underlined',
  },

  itemTitle: {
    type: String as PropType<string>,
    default: 'label',
  },

  itemValue: {
    type: String as PropType<string>,
    default: 'name',
  },

  disabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  readonly: {
    type: Boolean as PropType<boolean>,
    default: false,
  },

  returnObject: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const setSelect = ref(props.modelValue);

watch(() => props.modelValue, (value) => {
  setSelect.value = value;
});

watch(setSelect, (value) => {
  return emit('update:modelValue', value);
});
</script>


<style scoped lang="scss">
:deep(.v-field__outline) {
  display: none;
}

:deep(.v-field.v-field--variant-underlined .v-field__append-inner) {
  margin: auto;
  padding: 0;
}

:deep(.v-input__details) {
  display: none;
}

:deep(.v-field__input) {
  padding: 0;
  justify-content: end;
}

:deep(.v-field__field) {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #000000;
}

:deep(.v-field__append-inner > .v-icon) {
  opacity: 1;
}
</style>