<template>
    <v-container class="px-1">
        <v-row class="my-0" no-gutters>
            <!--v-col class="my-1 px-1" cols="6">
                <v-select v-model="state.search_type" :items="state.search_types" item-text="name" item-value="id" label="Search Type"
                variant="outlined" density="compact"
                 class="b-round-20" hide-details @change="FilterItems">
                    <template v-slot:prepend-inner>
                        <v-icon size="12" color="grey" icon="fa-search" class="mt-1" />
                    </template>
                </v-select>
            </v-col-->
            <v-col class="mb-1 px-1" cols="12">
                <v-text-field v-model="state.search" variant="solo" placeholder="Search Products" density="compact" rounded
                    hide-details @input="FilterItems">
                    <template v-slot:prepend-inner>
                        <v-icon size="15" color="grey" icon="fa-search" class="mb-1" />
                    </template>
                </v-text-field>
            </v-col>
            <v-col cols="6" class="my-0 px-1">
                <!--prepend-inner-icon="fa:fas fa-search"-->
                <v-btn :color="settingsStore.settings.buttonColor" class="text-left text-white b-round-20" prepend-icon="fa-sort" width="100%" rounded
                     @click="state.sort_asc = !state.sort_asc">
                    Sort
                </v-btn>
            </v-col>
            <v-col cols="6" class="my-0 px-1">
                <v-btn class="b-round-20 text-white" prepend-icon="fa-filter" :color="settingsStore.settings.buttonColor"
                    width="100%" @click="resetFilters">
                    <small>Clear Filters</small>
                </v-btn>
            </v-col>
        </v-row>
        <infinite-scroll @infinite-scroll="loadNextData">
            <v-row class="px-2 mt-1" no-gutters>

                <v-col class="pa-1" v-for="item in state.items" :key="item.id" cols="6">
                    <v-card class="mx-auto pt-2 px-2 " max-width="300" height="250"
                        @click="router.push({ name: 'supplier-product', params: { id: item.id } })">
                        <v-img width="100" height="100" :src="item.images[0]?.full" cover class="mt-1 mx-auto text-white">
                            <v-btn variant="flat" size="40" @click.stop="addProductToCart(item)">
                                <v-icon icon="fa fa-cart-plus" :color="settingsStore.settings.buttonColor" size="20"></v-icon>
                            </v-btn>
                        </v-img>
                        <v-card-text>
                            <div class="p-text font-weight-bold ms-1 mb-2">
                                {{ item.name }}
                            </div>
                            <div class="p-text font-weight-light ms-1 mb-2">
                                {{ item.brand.name }}
                            </div>
                            <div class="p-text font-weight-light ms-1 mb-2">
                                {{ item.sku }}
                            </div>
                            <div class="p-text font-weight-bold ms-1 mb-2">
                                £{{ item.price.toFixed(2) }}
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>

            </v-row>
        </infinite-scroll>
    </v-container>
</template>

<script lang="ts" setup>
import { reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
import { productService } from '@/api/services/product';
import { useSettingsStore } from '@/api/store/settings';
import { useCartStore } from '@/api/store/cart';
import InfiniteScroll from "infinite-loading-vue3";
import debounce from 'lodash.debounce'

const settingsStore = useSettingsStore();
const cartStore = useCartStore();

const router = useRouter();

settingsStore.loading = true;

const SUPPLIER_INDEX = 1;

const state = reactive({
    items: [] as any[],
    search_type: null as string | null,
    search_types: ["name", "brand"] as any[],
    search: "" as string,
    sort_asc: true as boolean,
    page: 1 as number,
});

const resetFilters = () => {
    state.search = "";
    state.search_type = null;
    state.sort_asc = true;
}

const sortItems = () => {
    if (state.sort_asc) {
        state.items.sort((a, b) => {
            return a.id - b.id;
        });
    } else {
        state.items.sort((a, b) => {
            return b.id - a.id;
        });
    }
}

const loadNextData = async () => {
    state.page += 1;
    settingsStore.loading = true;
    let filters = "page=" + state.page;
    productService.index(2, filters).then((response) => {
        state.items.push(...response.data);
        settingsStore.loading = false;
    });
};

const addProductToCart = (item: any) => {
    settingsStore.loading = true;
    cartStore.addItem(1, item.id, 1).then((res: any) => {
        router.push({ name: 'shopping-cart' });
    }).catch((error: any) => {
        settingsStore.error = true;
        settingsStore.error_message = "This item may be out of stock or no longer available from the supplier."
    }).finally(() => {
        settingsStore.loading = false;
    });
}

const loadItems = () => {
    productService.index(SUPPLIER_INDEX).then((response) => {
        state.items = response.data;
        settingsStore.loading = false;
    });
}

const loadItemsWithFilter = debounce( (filter: string) => {
    
    productService.index(SUPPLIER_INDEX, filter).then((response) => {
        state.items = response.data;
        settingsStore.loading = false;
    });
}, 1000)

const FilterItems = () => {

    let filters = "";
    const search_type = state.search_type == null || state.search_type == "name" ? "search" : state.search_type;

    if (state.search != "") {
        filters = search_type + "=" + state.search;
    }

    if (filters != "") {
        loadItemsWithFilter(filters);
    } else {
        settingsStore.loading = true;
        loadItems();
    }
}

loadItems();
</script>