const filterValues = [
    {
        name: 'Filter',
        label: 'All'
    },
    {
        name: 'created_at',
        label: 'Date'
    },
    {
        name: 'id',
        label: 'Id'
    },
]

const sortValues = [
    {
        name: 'asc',
        label: 'Asc'
    },
    {
        name: 'desc',
        label: 'Desc'
    }
]

export {filterValues, sortValues}