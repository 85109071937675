<template>
  <div>
    <v-alert type="info" class="mt-4 text-white" :color="settingsStore.settings.primaryColor">
      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>
              <h5>Health Suite (Coming Soon)</h5>
            </v-card-title>
            <v-card-text>
              <p>
                The Health Suite is a collection of tools to help you manage your health and wellness.
              </p>
            </v-card-text>
            </v-card>
        </v-col>
        </v-row>
    </v-alert>
  </div>
</template>

<script lang="ts" setup>
import { useSettingsStore } from '@/api/store/settings';
const settingsStore = useSettingsStore();
</script>
