<template>
  <textarea
      :rows="rows"
      class="text-area-container"
      v-model="description"
  />
</template>

<script setup lang="ts">
import {defineProps, defineEmits, ref, watch} from 'vue';

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {
    type: String
  },
  rows: {
    type: Number,
    default: 7
  }
})

const description = ref(props.modelValue);

watch(() => props.modelValue, (value) => {
  description.value = value;
});

watch(description, (newValue) => {
  emit('update:modelValue', newValue);
});
</script>

<style scoped lang="scss">
.text-area-container {
  padding: 12px 12px;
  border: 1px solid #000000;
  border-radius: 10px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

textarea:focus {
  outline: 0;
}

textarea {
  resize: none;
}
</style>